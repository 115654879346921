.defauleLayoutSection.integrations_main_wrapper .Polaris-Layout__Section {
    margin: 0 !important;
    padding: 10px !important;
    display: flex;
    flex-wrap: wrap;
    gap: 33px;
}

.defauleLayoutSection.integrations_main_wrapper .Polaris-Layout__Section>.integration_card {
    width: 100%;
    max-width: calc(100%/4 - 25px);
    flex: calc(100%/4 - 25px);
    border-radius: 2.83px;
    background: #FFF;
    box-shadow: 0px 0px 5px 0px rgba(23, 24, 24, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    padding: 20px 20px 68.33px;
    position: relative;
}

.defauleLayoutSection.integrations_main_wrapper .Polaris-Layout__Section>.integration_card .integ_img {
    padding: 10px 0 35px;
}

.defauleLayoutSection.integrations_main_wrapper .Polaris-Layout__Section>.integration_card .integ_img img {
    height: 43px;
    object-fit: contain;
}

.defauleLayoutSection.integrations_main_wrapper .Polaris-Layout__Section>.integration_card h6 {
    color: #202223;
    font-family: 'Avenir';
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 158.678% */
    margin: 0 0 16px;
}

.defauleLayoutSection.integrations_main_wrapper .Polaris-Layout__Section>.integration_card span.integ_type {
    border-radius: 4px;
    background: #E4E5E7;
    height: auto;
    color: #202223;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    /* 142.857% */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    text-align: left;
}

.defauleLayoutSection.integrations_main_wrapper .Polaris-Layout__Section>.integration_card button.integ_connected {
    display: block;
    border-radius: 4px;
    background: #008060;
    width: 100%;
    height: 36px;
    padding: 11px 0px 11px 0px;
    border: 0;
    color: #FFF;
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    margin: 0;
    position: absolute;
    left: 50%;
    bottom: 20px;
    max-width: calc(100% - 40px);
    transform: translateX(-50%);
}

button.integ_connected.coming_soon_button {
    background: #f44 !important;
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header .Polaris-Stack__Item.Polaris-Stack__Item--fill {
    display: none;
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header .Polaris-Stack__Item {
    width: 100% !important;
    max-width: 100% !important;
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header .Polaris-Stack__Item .integration_logo {
    display: flex;
    align-items: center;
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header .Polaris-Stack__Item .integration_logo .header_integ_logo img {
    height: 50px;
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header .Polaris-Stack__Item .integration_logo .card_header_buttons.primary_button_group {
    margin-left: 52px;
    margin-right: auto;
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header .Polaris-Stack__Item .integration_logo .card_header_buttons.primary_button_group button.integration_button {
    background: transparent;
    border: 0;
    color: #001219;
    text-align: center;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 350;
    line-height: 16px;
    /* 106.667% */
    padding: 0 20px;
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header .Polaris-Stack__Item .integration_logo span.Polaris-Badge {
    border-radius: 20px;
    padding: 2px 7.61px 2px 8px;
    font-family: 'avenir';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header {
    padding: 15.5px 20px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid rgba(218, 218, 218, 0.50);
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Section {
    border-radius: 7.92px;
    background: #FFF;
    overflow: hidden;
    padding: 40px 26px 39px;
}

.inte_details_content_container p.integ_desc {
    color: #202223;
    font-family: 'avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    margin: 0 0 16px;
}

.inte_details_content_container .integ_key_features h6 {
    color: #202223;
    font-family: 'avenir';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    margin: 0 0 13px;
}

.inte_details_content_container .integ_key_features ul.key_features_list {
    padding: 0;
    margin: 0 0 29px;
    list-style: none;
}

.inte_details_content_container .integ_key_features ul.key_features_list>li:not(:last-child) {
    margin: 0 0 8px;
}

.inte_details_content_container .integ_key_features ul.key_features_list>li {
    color: #202223;
    font-family: 'avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    position: relative;
    padding: 0 0 0 36px;
}

.inte_details_content_container .integ_key_features ul.key_features_list>li:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'%3E%3Cpath d='M10.0918 18.6631C5.6808 18.6631 2.0918 15.0741 2.0918 10.6631C2.0918 6.25209 5.6808 2.66309 10.0918 2.66309C14.5028 2.66309 18.0918 6.25209 18.0918 10.6631C18.0918 15.0741 14.5028 18.6631 10.0918 18.6631ZM10.0918 4.66309C6.7828 4.66309 4.0918 7.35409 4.0918 10.6631C4.0918 13.9721 6.7828 16.6631 10.0918 16.6631C13.4008 16.6631 16.0918 13.9721 16.0918 10.6631C16.0918 7.35409 13.4008 4.66309 10.0918 4.66309ZM9.0918 13.6631C8.96046 13.6632 8.83039 13.6374 8.70906 13.5871C8.58773 13.5368 8.47754 13.4631 8.3848 13.3701L6.3848 11.3701C6.28929 11.2778 6.2131 11.1675 6.1607 11.0455C6.10829 10.9235 6.0807 10.7923 6.07955 10.6595C6.07839 10.5267 6.10369 10.395 6.15398 10.2721C6.20426 10.1492 6.27851 10.0376 6.3724 9.94369C6.46629 9.8498 6.57795 9.77555 6.70084 9.72526C6.82374 9.67498 6.95542 9.64968 7.0882 9.65084C7.22098 9.65199 7.3522 9.67958 7.4742 9.73198C7.59621 9.78439 7.70655 9.86058 7.7988 9.95609L9.0918 11.2491L12.3848 7.95609C12.477 7.86058 12.5874 7.78439 12.7094 7.73198C12.8314 7.67958 12.9626 7.65199 13.0954 7.65084C13.2282 7.64968 13.3599 7.67498 13.4828 7.72526C13.6056 7.77554 13.7173 7.8498 13.8112 7.94369C13.9051 8.03758 13.9793 8.14924 14.0296 8.27213C14.0799 8.39503 14.1052 8.52671 14.104 8.65949C14.1029 8.79227 14.0753 8.92349 14.0229 9.04549C13.9705 9.16749 13.8943 9.27784 13.7988 9.37009L9.7988 13.3701C9.70609 13.4631 9.5959 13.5369 9.47456 13.5872C9.35322 13.6375 9.22314 13.6633 9.0918 13.6631Z' fill='%235C5F62'/%3E%3C/svg%3E");
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Section .integ_footer_button_group {
    display: flex;
    gap: 16px;
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Section .integ_footer_button_group button {
    padding: 10px 14.379px 10px 17px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #C9CCCF;
    background: #FFF;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
    color: #202223;
    text-align: center;
    font-family: "SF Pro Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header .Polaris-Stack__Item .integration_logo .card_header_buttons.primary_button_group button.integration_button.true {
    color: #017EFA;
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header .Polaris-Stack__Item {
    min-width: 100%;
}

.integration_main .integration_detail_detail_wrapper .Polaris-Card__Header .Polaris-Stack__Item span.Polaris-Badge {
    width: auto !important;
}

.integ_details_content_container .integ_header_wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.integ_details_content_container .integ_header_wrapper h5 {
    font-size: 16px;
    font-weight: 900;
    color: #000;
}

button.integ_disconnect {
    background: transparent;
    color: red;
    border: 0;
    cursor: pointer;
}

button.integ_update_key {
    border: 0;
    background: transparent;
    margin: 10px 0;
    cursor: pointer;
    color: #056396;
}

button.integ_connect {
    background: transparent;
    border: 1px solid #aeaaaa;
    padding: 9px 17px;
    border-radius: 5px;
    cursor: pointer
}

button.integ_connect[disabled] {
    color: #8c9196;
    border: 1px solid #d2d5d8;
    cursor: default;
}
