.App {
  font-family: sans-serif;
  text-align: center;
}

.custom_box {
  --pc-box-border-color: var(--p-color-border-subdued);
  --pc-box-border-style: solid;
  --pc-box-border-block-end-width: var(--p-border-width-1);
  --pc-box-padding-inline-start-xs: var(--p-space-2);
  --pc-box-padding-inline-end-xs: var(--p-space-2);
}

.Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li.Polaris-Tabs__TabContainer>button {
  font-size: 1.4rem;
  margin: 0.1rem 0 -0.1rem;
  padding: 0.8rem 0.4rem;
  outline: none;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
}

.Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li.Polaris-Tabs__TabContainer>button>span.Polaris-Tabs__Title {
  font-size: 1.4rem;
  color: #6d7175;
  line-height: 2rem;
  position: relative;
  border-radius: var(--p-border-radius-base);
  display: block;
  padding: 0.8rem 1.6rem;
  min-width: 5rem;
}

.Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li.Polaris-Tabs__TabContainer>button span.Polaris-Tabs__Title {
  border-bottom: 0;
}

.Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li.Polaris-Tabs__TabContainer>button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.3rem;
  border-top-left-radius: var(--p-border-radius-base);
  border-top-right-radius: var(--p-border-radius-base);
}

.Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li.Polaris-Tabs__TabContainer>button {
  position: relative;
}

.Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li.Polaris-Tabs__TabContainer>button.Polaris-Tabs__Tab--selected::after {
  background: rgba(0, 128, 96, 1);
}

.Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li.Polaris-Tabs__TabContainer>button {
  box-shadow: none !important;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card {
  width: 100%;
  max-width: calc(100% / 2 - 10px);
  margin: 0;
  flex: calc(100% / 2 - 10px);
  padding: 20px;
  border-radius: 0.8rem;
  border: 0 !important;
  background: #fff !important;
  box-shadow: 0px 0px 5px rgba(23, 24, 24, 0.05),
    0px 1px 2px rgba(0, 0, 0, 0.15) !important;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card__SectionHeader {
  width: 100%;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .thumbnail_image {
  display: flex;
  overflow-x: auto;
  margin-top: 1.6rem;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .thumbnail_image img {
  width: 100%;
  max-width: 80px;
  height: 54px;
  object-fit: cover;
  border: 2px solid rgb(223, 227, 232);
  margin-right: 10px;
  min-width: 80px;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .top_image {
  display: flex;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .top_image img {
  width: 100%;
  filter: drop-shadow(rgba(0, 0, 0, 0.15) 0px 1px 3px);
  height: 290px;
  object-fit: contain;
}

/* .Polaris-Card {
  max-width: 1000px;
  margin: 0 auto;
  background: transparent !important;
  border: 0;
  box-shadow: none !important;
  padding: 50px 32px;
} */

/* .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section {
  padding: 1.6rem 0 0;
} */

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .thumbnail_image {
  margin-bottom: calc(1.6rem + 5px);
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .title_heading {
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: rgba(32, 34, 35, 1);
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .categories_data {
  margin-bottom: 1.6rem;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .categories_data span {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  min-height: 2.8rem;
  padding: 0 0.8rem;
  background-color: rgba(228, 229, 231, 1);
  border-radius: 0.4rem;
  color: rgba(32, 34, 35, 1);
  outline: 0.1rem solid transparent;
  font-size: 14px;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .action_button {
  display: flex;
  gap: 0.8rem;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .action_button button.use_temp {
  background: rgba(0, 128, 96, 1);
  color: rgba(255, 255, 255, 1);
  border-width: 0;
  border-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.6rem;
  font-size: 1.4rem;
  border-radius: 0.4rem;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .action_button button.coming_soon_button {
  background: #FF4444;
  border: 0;
  color: #fff;
  border-width: 0;
  border-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.6rem;
  font-size: 1.4rem;
  border-radius: 0.4rem;
  cursor: disabled;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .action_button button.prev {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.6rem;
  background: rgba(255, 255, 255, 1);
  color: rgba(32, 34, 35, 1);
  border-radius: 0.4rem;
  border: 1px solid rgba(186, 191, 195, 1);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  font-size: 14px;
}

.Polaris-Tabs__Wrapper {
  padding: 0 0.8rem;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .thumbnail_image img.active {
  border-color: rgb(0, 128, 96);
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .action_button button.prev:hover {
  background: rgba(246, 246, 247, 1);
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .action_button button.use_temp:hover {
  background: rgba(0, 110, 82, 1);
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .action_button button {
  cursor: pointer;
}

.sort-by {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.sort-by .inner-wrap-sort {
  position: relative;
  display: flex;
  white-space: nowrap;
  align-items: center;
  min-height: 3.6rem;
  padding: 0.5rem 0.8rem 0.5rem 1.2rem;
  font-size: 1.4rem;
  color: rgb(32, 34, 35);
  border: 0.1rem solid rgba(201, 204, 207, 1);
  border-bottom-color: rgba(186, 191, 196, 1);
  border-radius: var(--p-border-radius-base);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem;
}

.sort-by .inner-wrap-sort select {
  position: unset;
  opacity: 1;
  background: transparent;
  border: 0;
  transform: none;
  color: #202223;
  font-weight: 400;
}

.sort-by .inner-wrap-sort label {
  color: #6d7175;
}

.Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card__SectionHeader {
  display: none;
}

.sort-by .inner-wrap-sort svg.Polaris-Icon__Svg {
  width: 18px;
  height: 20px;
  position: absolute;
  right: 3px;
  fill: rgba(92, 95, 98, 1);
  top: 50%;
  transform: translateY(-50%);
}

.sort-by .inner-wrap-sort select {
  padding-right: 10px;
  outline: none;
  padding-left: 5px;
}

.back-button {
  margin: 0 auto;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 50px 0px 0;
  width: 100%;
}

.back-button nav {
  min-height: 3.6rem;
  min-width: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid rgba(186, 191, 196, 1);
  position: relative;
  color: rgba(109, 113, 117, 1);
  margin-right: 1.6rem;
}

.back-button nav a {
  line-height: normal;
  font-size: unset !important;
}

.back-button nav a span.Polaris-Breadcrumbs__Icon {
  position: relative;
  left: 3px;
}

.back-button h1.Polaris-Header-Title {
  word-break: break-word;
  overflow-wrap: break-word;
  font-weight: 600;
  font-size: 20px;
  line-height: 2.8rem;
  color: #202223;
}

.Polaris-Popover {
  box-shadow: -1px 0px 20px rgba(23, 24, 24, 0.05),
    0px 1px 5px rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.8rem !important;
  overflow: hidden !important;
  backface-visibility: hidden;
  will-change: left, top;
}

.Polaris-Popover ul.Polaris-Tabs__List {
  padding: 0.8rem;
}

.Polaris-Popover ul.Polaris-Tabs__List>li>button {
  min-height: 1.6rem;
  padding: 0.8rem 1rem;
  font-size: 14px;
}

.Polaris-Tabs__DisclosureActivator {
  box-shadow: none !important;
}

@media (max-width: 991px) {
  .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card {
    max-width: 100%;
    flex: 100%;
  }
}

@media (max-width: 767px) {
  .Polaris-Card {
    padding: 30px 16px;
  }

  .back-button {
    flex-wrap: wrap;
    grid-row-gap: 10px;
  }

  .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .top_image img {
    height: 201px;
    object-fit: contain;
  }
}

.rewards_choose_template .Polaris-Tabs__Wrapper {
  background: #fff !important;
  border-bottom: unset;
}


.rewards_template_wrapper .Polaris-Page {
  margin: 0 auto !important;
  padding: 0 3.2rem !important;
}

.rewards_template_wrapper .Polaris-Page .Polaris-Page-Header.Polaris-Page-Header--isSingleRow.Polaris-Page-Header--noBreadcrumbs .back-button {
  padding: 0;
}


.rewards_template_wrapper .Polaris-Page .Polaris-Page__Content .rewards_choose_template .Polaris-Card {
  background: transparent;
}

.rewards_template_wrapper .Polaris-Page .Polaris-Page__Content .rewards_choose_template .Polaris-Card .Polaris-Tabs__Wrapper {
  background: transparent !important;
  border-bottom: .1rem solid #ddd;
  padding: 0 .8rem;
}


.rewards_template_wrapper .Polaris-Page .Polaris-Page__Content .rewards_choose_template .Polaris-Card .Polaris-Tabs__Wrapper .Polaris-Tabs .Polaris-Tabs__TabContainer button .Polaris-Tabs__Title {
  background: transparent;
}

.chooseTemplateWrapper .rewards_template_wrapper .Polaris-Page {
  margin: 0 auto !important;
  padding: 0 3.2rem !important;
}

.chooseTemplateWrapper .Polaris-Page {
  max-width: 93% !important;
  padding: 0 3.2rem !important;
  margin: 0 auto !important;
}

.bundle_with_page_wrapper .Polaris-Page {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}