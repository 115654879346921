* {
  // background-color: red;
}
// Avenir Global

body, button, html {
    font-family: Avenir !important;
}

.chargezen {
  .Polaris-CalloutCard__Image {
    min-width: 200px;
  }

  .Polaris-Button--primary {
    background: linear-gradient(180deg, #000000, #000000);
    border-color: #000000;
    box-shadow: inset 0 1px 0 0 #000000, 0 1px 0 0 rgba(22, 29, 37, 0.05),
      0 0 0 0 transparent;

    .Polaris-Button__Text {
      color: #fff;
    }

    &:hover {
      background: linear-gradient(180deg, #000000, #18528e);
      border-color: #000000;
      box-shadow: inset 0 1px 0 0 #000000, 0 1px 0 0 rgba(22, 29, 37, 0.05),
        0 0 0 0 transparent;
    }
  }

  .Polaris-Page__Content {
    .Polaris-Tabs__Wrapper {
      background: #000000;
      border-radius: 9px 9px 0px 0px;

      .Polaris-Tabs__Title {
        color: white;
      }

      .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected {
        .Polaris-Tabs__Title {
          border-bottom: var(--p-override-none, 0.3rem) solid white;
        }
      }
    }

    .Polaris-Tabs__Wrapper {
      background: #000000;
      border-bottom: unset;

      .Polaris-Tabs__Title {
        color: white;
        margin-top: 13px;
        height: 31px;
        line-height: 0px;
        padding: 15px 12px;
        display: inline-block;
      }
      .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
        background: white;
        color: #000000;
        border-bottom: unset;
        border-radius: 5px 5px 0px 0px;
      }
    }
  }

  .Polaris-Modal__Body {
    overflow: hidden;
    .Polaris-Thumbnail--sizeSmall {
      display: flex;
    }
  }
  .Polaris-Modal-CloseButton--withoutTitle {
    z-index: 1;
  }
  .subscription-plan-customSlick {
    ul.slick-dots {
      margin-bottom: 1.6rem;
      li {
        &.slick-active {
          button:before {
            color: #000000;
            opacity: unset;
          }
        }
        button:before {
          font-size: 10px;
          color: #000000;
          opacity: 0.5;
        }
      }
    }

    .header {
      margin-right: 4.063rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      .top-img {
        width: 110px;
        height: 87px;
      }
      .bot-img {
        width: 160px;
        height: 130px;
        background: #c4c4c4;
      }
    }

    .Polaris-TextContainer {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .Polaris-Button {
    border-radius: 3px !important;
    background: white;
    &.Polaris-Button--primary {
      border: none;
      background: #000000;
      border-radius: 3px;
    }
    &.Polaris-Button--destructive {
      background: linear-gradient(180deg, #e6391a, #d53417);
    }
  }

  .Polaris-Card__Header .Polaris-Button {
    background: none;
    color: white;
    border: none;
    margin-right: 10px;
  }

  .hidden {
    display: none;
  }
  .d-flex {
    display: flex;
  }
  .child-full-width {
    > div {
      width: 100%;
    }
  }
  .mr-16 {
    margin-right: 1.6rem;
  }
  .Polaris-Spinner {
    display: flex;
    justify-content: center;
  }
}

.building-box-product {
  display: flex;
  position: relative;
  img.product {
    width: 65px;
    height: 65px;
    background-color: #c4c4c4;
    background-size: cover;
  }
  img.removeIcon {
    right: -6px;
    top: -6px;
    position: absolute;
  }
}
.Polaris-Stack > .Polaris-Stack__Item {
  margin-top: 1.6rem;
}

.main-header-dashboard {
  display: flex;
  justify-content: space-between;
  .left-btns {
    display: flex;
    .get-started {
      background: white;
      height: 54px;
      width: 124px;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      display: flex;
      &:last-child {
        margin-left: 29px;
      }
      a {
        color: #000000;
        margin-left: 9px;
      }

    }
  }
}
.dashboard-card-section {
  .Polaris-Card {
    border-radius: 9px;
  }
}
.dashboard-bottom-card-section {
  .Polaris-Card {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
.main-order-section {
  .analytic-section {
    width: auto;
  }
}

.analytic-section {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  .deep-analytics {
    display: inline-flex;
    justify-content: flex-end;
    flex-grow: 0;
    flex-shrink: 0;
    // float: right;
    background: #000000;
    padding: 7px 14px 7px 10px;
    border-radius: 3px;
    align-items: center;
    
    a {
      margin-left: 5px;
      color:white;
    }
  }
}
.module-Analytics {
  .analytics-page-layout {
    > .Polaris-Layout {
      flex-wrap: nowrap;
      >
      .Polaris-Stack {
        width: 97% !important;
      }
    }
    
    .data-tabels {
      width: 100%;
      .Polaris-Stack__Item {
        flex: 1;
      }
    }
    
  }
  .Polaris-Page {
    .Polaris-Tabs__TabContainer {
      button {
        &:first-child {
          padding-left: 0px;
        }
      }
    }
  }
}

.slick-dots {
  bottom: -16px !important;
}
.slider-container {
  padding-block: 25px;
}
