.wrapper{
  /* padding: 10px; */
}
.wrapper button {
  background-color: #000000;
  border-radius: 3px;
}
.button-bar-dis{
  padding: 10px;
  display: flex ;
  align-items: center;
  justify-content: space-between ;
  background-color:#E2F5FF ;
  margin-top: 14px;
}
.button-bar-dis button {
  padding: 14px 18px;
}

.wrapper > .search-bar{
  margin: 25px 0px;
  max-width:453px;
}
/* .table-bar{
  width: 100%;
  display: block;
  overflow-x: auto;
}
table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding: 8px;
  font-size: 16px;
}
tr{
  border-bottom: 1px solid #E6E6E7;
} */
.title-section{
  display: flex;
  align-items: center;
}
.title-section > *{
  padding: 5px;
}
.tag-section{
  display: flex;
}
.tag-section > *{
  margin: 0px 7px;
  padding: 2px 10px;
  border-radius: 10px !important;
}
.text-section{
  max-width: 300px;;
  font-size: 14px;
  color: #777777;
}
.active{
  color:#63AB48;
  background-color:#E0FDD6;
  font-size: 12px;
}
.in-active{
  color:#696969;
  background-color:#E7E7E7;
  font-size: 12px;
}
.expired{
  color:#AB4848;
  background-color:#FDC9D0;
  font-size: 12px;
}

/* next page */


.wrapper > *{
  margin-bottom: 20px;
}
.bread-bar{
  padding: 16px;
  display: flex;
  background-color:#E2F5FF !important;
  grid-column-gap: 5px;
  align-items: center;
}


.bread-bar > a{
  cursor: pointer;
  margin :0px 4px;
}
.Polaris-Card__Section{
  /* padding-top: 2px !important; */
}
.Polaris-Card__Section > *{
/* margin: 10px 0px !important; */
} 
.Polaris-Card__Section > p{
  /* margin-top:25px !important; */
}
.discount-type-group {
  display: flex;
  margin-bottom: 30px;
}
.discount-type-group > *{
  margin-right: 10px;
  width: 300px;
}
.type-group{
  width:595px;
  margin-bottom: 30px;
}
.type-group + p {
  font-weight: bold;
  margin-bottom: 25px;
  font-size: 16px;
}
.min-radio-group{
  display: flex;
  flex-direction: column;
}

.min-radio-group > div {
  margin-bottom: 15px;
}

.switch-wrapper {
  margin-top: 25px;
}
.switch-wrapper > *{
  margin: 16px 0px;
}
.switch-group{
  display: flex;
  max-width: 70%;
  justify-content: space-between;
}
/* Switch Code start */
.switch-section {
  display: flex;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 23px;
  margin-right: 10px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: -2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}
.switch-input:checked + .slider {
  background-color: #2196f3;
}
.switch-input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.switch-input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
/* Switch Code ends */
.limit-group-radio {
 display: flex;
 flex-direction: column;
 margin-bottom: 25px;
}

.limit-group-radio .Polaris-Choice {
  margin-bottom: 5px;
}

.btn-group > *{
  margin: 0px 10px !important;
  /* padding: 15px 40px !important; */
}
.limit-group-radio + p {
  margin-bottom: 15px;
}
.check-group{
  display: flex;
  justify-content: flex-start !important;
}
.check-group > *{
  margin: 5px 6px !important;
}

.check-group span {
  margin-left: 0px !important;
}
.promo{
margin: 20px 20px;
}
.promo p {
  font-size: 16px;
}
.promo p:first-child {
  font-weight: 900;
  margin-bottom: 7px;
  font-size: 2rem;
}

.discount-group  p {
  font-weight: 900;
  margin-bottom: 5px;
  font-size: 18px;

}
.discount-type-group + p {
  font-weight: 900;
  margin-bottom: 15px;
  font-size: 18px;
}
.discount-group .Polaris-Connected {
  max-width: 595px;
}

.promo > .discount-group{
  margin-top:15px;
  width: 70% !important;
}
.sec-5 .Polaris-Icon--colorBase svg {
  font-size: 26px !important;
  fill: rgb(17 139 18);
}

.sec-1 .Polaris-Card__Section {
  padding-top: 8px;
}

.sec-2 .Polaris-Card__Section {
  padding-top: 8px;
}

.content .wrapper span {
  font-size: 16px;
}
.sec-3 .Polaris-Card__Section p {
  font-size: 16px;
}

.sec-4 .btn-group {
  display: flex;
}

.sec-4  p {
  font-size: 16px;
}
.sec-4 .bottom-bread {
  margin: 20px 0px !important;

}

.sec-4 .bottom-bread + div{
  margin-bottom: 20px;
}

.sec-4 h2  {
  font-size: 18px;
}

.sec-4 label {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
}

.sec-5 h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.sec-5 h3 + p {
  font-size: 16px;
}