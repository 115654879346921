span[aria-live="polite"] {
    width: 100%;
}

.product-option-button span[aria-live="polite"] {
    width: calc(50% - 5px) !important;
}

.card-charzen.delivery-address.skeleton {
    min-height: fit-content;
    margin-bottom: 8px;
}

.card-charzen.delivery-address.skeleton .billing-address {
    min-height: fit-content;
    padding-bottom: 0;
}