.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner {
    display: flex;
    align-items: flex-end;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency {
    width: 100%;
    max-width: 121px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.product-variant-picker-container {
    margin: 0;
    width: 100%;
    max-width: calc(100% - 121px);
    padding-right: 30px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.product-variant-picker-container>label.input_custom_label,
.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner label.input_custom_label {
    color: #000;
    text-align: center;
    font-family: Avenir;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.6px;
    /* 130.667% */
    display: block;
    text-align: left;
    margin: 0 0 11px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.product-variant-picker-container input#search_plan_query {
    border-radius: 8px;
    border: 1px solid #898F94;
    background: #FDFDFD;
    height: 32px;
}

.product-variant-picker-container .product_searchProduct button.browse_product_button {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px -1px 0px 0px #B5B5B5 inset, 0px 0px 0px 1px rgba(0, 0, 0, 0.10) inset, 0px 0.5px 0px 1.5px #FFF inset;
    height: 32px;
    color: #303030;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 153.846% */
    padding: 6px 11.8px 6px 12px;
    min-width: 70px;
    min-height: unset;
}

.product-variant-picker-container .product_searchProduct .product_search_input {
    padding-right: 4px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.product-variant-picker-container .product_preview_row {
    margin-top: 16px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.product-variant-picker-container .product_preview_row .product_variant_preview_wrapper {
    border: 1px solid #E1E3E5;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.product-variant-picker-container .product_preview_row .product_variant_preview_wrapper span {
    color: #303030;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 153.846% */
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency label {
    color: #303030;
    font-family: Inter;
    font-size: 12.898px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 155.058% */
    display: block;
    margin: 0 0 10px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields {
    display: flex;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields>div:first-child {
    max-width: 40px;
}

.Polaris-TextField__Backdrop {
    display: none;
}

.Polaris-TextField {
    border-radius: 8px;
    border: 1px solid #8A8A8A;
    background: #FDFDFD;
}

.Polaris-TextField .Polaris-TextField__Spinner {
    align-items: center;
    justify-content: center;
}

.Polaris-TextField__Spinner .Polaris-TextField__Segment {
    background: transparent;
    border: 0;
    width: auto;
    height: auto;
    min-height: unset;
    width: auto;
    flex: unset;
}

.Polaris-TextField__Spinner .Polaris-TextField__Segment .Polaris-TextField__SpinnerIcon {
    width: 14px;
}

.create_billing.modal-popup-cstm .Polaris-TextField input {
    padding: 0 !important;
    height: 30.2px !important;
    min-height: unset;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields>div:last-child {
    width: 100%;
    max-width: calc(100% - 40px);
    padding-left: 11px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields>div:last-child select {
    width: 46px;
    padding: 0 !important;
    height: 32px;
    border-radius: 8px;
    border: 1px solid #8A8A8A;
    background: #FDFDFD;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields>div:last-child .Polaris-Select__Content {
    padding: 0 !important;
    min-height: 32px;
    border-radius: 8px;
    border: 1px solid #8A8A8A;
    background: #FDFDFD;
    padding: 3px 8px !important;
    align-items: center;
    justify-content: space-between;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields>div:last-child .Polaris-Select__Backdrop {
    display: none;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields>div:last-child .Polaris-Select__Content span.Polaris-Select__SelectedOption {
    color: #303030;
    font-family: Avenir;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 166.667% */
    position: relative;
    top: 1px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner {
    padding: 19px 0 0;
    margin: 26px 0 0;
    border-top: 1px solid #E1E3E5;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner label.input_custom_label {
    width: 100%;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner .product-variant-picker-container {
    display: flex;
    flex-wrap: wrap;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner .product-variant-picker-container>.product_preview_row {
    flex-flow: row wrap;
    align-items: flex-end;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner .product-variant-picker-container>.product_preview_row .product_variant_preview_wrapper {
    max-width: calc(100% - 151px);
    align-items: center;
    align-self: end;
    padding-right: 0;
    justify-content: space-between;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner .product-variant-picker-container>.product_preview_row .quanity_price_manager {
    width: 100%;
    max-width: 121px;
    display: flex;
    gap: 10px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner .product-variant-picker-container>.product_preview_row .product_variant_preview_wrapper .product_variant_desc span {
    color: #303030;
    font-family: 'Avenir';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 153.846% */
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner .product-variant-picker-container>.product_preview_row .quanity_price_manager label {
    color: #303030;
    font-family: Inter;
    font-size: 12.898px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 155.058% */
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner .product_searchProduct {
    width: 100%;
    max-width: calc(100% - 155px);
}


.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields .Polaris-Connected {}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields .Polaris-Connected .Polaris-TextField__Spinner {
    display: none;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields input {
    padding: 10px 0px 6px !important;
    text-align: center;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields .Polaris-Select {}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields .Polaris-Select .Polaris-Select__Content {}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields .Polaris-Select .Polaris-Select__Content span.Polaris-Select__Icon {
    position: absolute;
    right: 0;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner .product-variant-picker-container>.product_preview_row .quanity_price_manager .Polaris-Connected .Polaris-TextField__Spinner {
    display: none;
}

.create_billing.modal-popup-cstm>.modal-dialouge {
    max-width: 820px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner .product-variant-picker-container>.product_preview_row {
    justify-content: space-between;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .product_picker_contaner .product-variant-picker-container>.product_preview_row .quanity_price_manager>div input {
    text-align: center;
    padding: 8px 6px 4px !important;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .plan_picker_contaner>.setBillingFrequency .billing_frequency_fields>div:only-child {
    padding: 0;
}