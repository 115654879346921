.packs_main {
    display: flex;
    flex-flow: column;
    gap: 15px;
}

.loisa_button_wrapper {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.preview_collection_image {
    max-width: 200px;
    border: 1px solid #ddd;
    margin: -15px 0 15px 0;
    width: 100%;
}

.preview_collection_image img {
    width: 100%;
}

.collection_card_wrapper {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 25px 0;
    padding: 25px 15px;
}

button.delete_collection_button {
    background: transparent;
    border: 0;
    color: red;
    cursor: pointer;
}

.delete_collection_wrapper {
    display: flex;
    justify-content: end;
}

.upsell_images_container {
    margin-top: 25px;
}

.collection_card_wrapper .slider-preview .preview-item .img+p {
    min-height: 40px;
}

.collection_card_wrapper .slider-preview .preview-item>div:last-child {
    max-width: 170px;
    margin: 0 auto;
}

.bundle_with_page_wrapper .Polaris-Page {
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
}