.setup_guide_float_right_container {
    position: fixed;
    top: 68px;
    right: 3px;
    height: calc(100vh - 78px);
    background: #fff;
    overflow-y: auto;
    padding: 0;
    max-width: 21.5%;
    width: 100%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.setup_guide_float_right_container .heading_title.contains.progressbar_wrapper {
    flex-wrap: wrap;
    flex-flow: column;
    align-items: flex-start;
    gap: 0;
    padding: 16px;
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.setup_guide_float_right_container .heading_title.contains.progressbar_wrapper .card_title_desc {
    position: unset;
    padding: 0;
    display: none;
}

.setup_guide_float_right_container .heading_title.contains.progressbar_wrapper .progressbar_wrapper {
    width: 100%;
    margin-top: 5px;
}

.setup_guide_float_right_container .heading_title.contains.progressbar_wrapper .progressbar_wrapper .pogress_bar_details {
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 5px;
}

.setup_guide_float_right_container .Polaris-Card__Section {
    padding: 0;
}

.setup_guide_float_right_container .Polaris-Card__Section .contents_tab_wrapper {
    padding: 0 20px;
}

.setup_guide_float_right_container .Polaris-Card__Section .contents_tab_wrapper button.toggle_content_tab_button {
    margin-right: 0;
    padding: 0;
}

.setup_guide_float_right_container .Polaris-Card__Section .contents_tab_wrapper button.toggle_content_tab_button.opened {
    transform: rotate(180deg);
}



body .module-navbar .app_content.with_floating_setup_guide {
    padding-right: 21%;
}

.setup_guide_float_right_container::-webkit-scrollbar {
    width: 2px;
}

.setup_guide_float_right_container::-webkit-scrollbar-track {
    background: #ddd;
}

.setup_guide_float_right_container::-webkit-scrollbar-thumb {
    background: #00121973;
}

.setup_guide_float_right_container button.close_floating_setup_guide_button {
    position: absolute;
    right: 0;
    border: 0;
    background: #00000000;
    cursor: pointer;
}

.setup_guide_float_right_container .chargezen_custom_header {
    position: relative;
}

.setup_guide_float_right_container .chargezen_custom_header button.close_floating_setup_guide_button svg.Polaris-Icon__Svg {
    fill: #000 !important;
    color: #000;
    position: unset;
    background: #00000000;
    cursor: pointer;
}

.setup_guide_float_right_container .chargezen_custom_header button.close_floating_setup_guide_button span.Polaris-Icon {
    width: 24px;
    height: 24px;
}