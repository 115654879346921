.edit_quiz_main {
    width: 100vw;
    position: fixed;
    left: 0;
    z-index: 9999;
    background: #fff;
    top: 30px;
    min-height: 100vh;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.edit_quiz_main .boldLabel {
    font-size: 1.4rem;
    font-size: 12px;
    font-weight: 900;
    margin-bottom: 8px;
}

.boldLabel.main_heading {
    text-transform: uppercase;
}

.edit_quiz_main .preview_quiz {
    border-right: 1px solid #80808080;
    border-left: 1px solid #80808080;
    width: 100%;
    padding: 10px;
}

.rightSection .Polaris-Card__Section {
    display: flex;
    justify-content: center;
    flex-flow: column;
    gap: 20px;
    border-top: 1px solid #80808078;
}

.edit_quiz_main .rightSection {
    min-width: 300px;
    overflow: auto;
}

/* Preview CSS */
.main_div_container {
    height: fit-content;
}

.quiz_kit_sub {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 700px;
    z-index: 0;
}

.quiz-kit-content_main {
    min-height: 700px;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.quiz_kit_sub *,
.quiz_kit_sub ::before,
.quiz_kit_sub ::after {
    box-sizing: border-box;
}

.quiz_kit_question_image {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.quiz_kit_question_image picture {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.quiz_kit_question_image img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

.quiz_body_main.bottom-center,
.quiz_body_main.center-center,
.quiz_body_main.top-center {
    text-align: center;
}

.quiz_body_main {
    position: relative;
    max-width: 1060px;
    margin: 0px auto;
    width: 100%;
    text-align: center;
    visibility: visible;
    z-index: 3;
    padding: 50px 50px 60px;
}

.quiz_intro_main {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 700px;
    background-color: rgb(255, 255, 255);
}


body * {
    transition-timing-function: ease;
    transition-duration: .3s;
    pointer-events: auto;
    transition-property: opacity, transform;
    transition-delay: .3s;
}

.quiz_intro_overlay {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.quiz_heading_main {
    white-space: normal;
    max-width: 100%;
    text-align: inherit;
}

.quiz_button_main {
    position: relative;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: uppercase;
    max-width: 100%;
    cursor: pointer;
    outline: none;
    transition: all 0.25s ease 0s;
}

/* For questions */
.quiz_question_main {
    position: relative;
    max-width: 1060px;
    margin: 0px auto;
    width: 100%;
    text-align: center;
    visibility: visible;
    z-index: 3;
    padding: 150px 55px 100px;
}

.form_main_container {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin: 50px -10px 0px;
    text-align: center;
}

.quiz_form {
    max-width: 100%;
}

input,
textarea,
select {
    display: block;
    position: relative;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.5;
    font-size: 14px;
    line-height: normal;
    border: 0;
    padding: 10px 14px;
}

.input_answer_main {
    width: 370px;
    height: 55px;
    max-width: calc(100% - 20px);
    font-weight: 600;
    margin: 0px 10px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 30px;
    font-size: 16px;
    font-family: Jost;
    color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    padding: 10px 20px;
    outline: none;
    transition: all 0.3s ease 0s;
    letter-spacing: initial;
    display: initial !important;
}

.tooltip_main_container {
    font-family: Cartograph-Mono, sans-serif;
    font-size: 14px;
    position: relative;
    width: 34px;
    cursor: pointer;
    margin: 30px auto;
    z-index: 1;
}

.tooltip_svg_container {
    font-size: 14px;
}

svg:not(:root) {
    overflow: hidden;
}

.tooltip_sub_container {
    width: 300px;
    background: rgb(255, 255, 255);
    position: absolute;
    inset: auto auto calc(100% + -5px) 50%;
    transform: translateX(-50%);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px;
    padding: 15px 15px 20px;
    max-height: 195px;
    overflow: auto;
}

.next_button_main_container {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 40px 0px 0px;
    max-width: 100%;
}

.quiz_questions_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 700px;
    background-color: rgb(237, 232, 240);
}

.questionHeading {
    font-weight: 700;
    width: 100%;
    margin: 0px auto 20px;
    line-height: 1.2;
    font-size: 44px;
    color: rgb(0, 0, 0);
    text-transform: none;
    letter-spacing: initial;
}

.subHeading {
    font-weight: 400;
    width: 100%;
    margin: 0px auto 20px;
    line-height: 1.5;
    font-size: 20px;
    color: rgb(0, 0, 0);
    text-transform: none;
    letter-spacing: 0.25px;
}

.tooltipHeading {
    font-weight: 600;
    width: 100%;
    margin: 0px auto 10px;
    line-height: 1.2;
    font-size: 14px;
    color: rgb(0, 0, 0);
    text-transform: none;
    letter-spacing: initial;
}

.tooltipSubHeading {
    font-weight: 400;
    width: 100%;
    margin: 0px auto;
    line-height: 1.5;
    font-size: 10px;
    color: rgb(0, 0, 0);
    text-transform: none;
    letter-spacing: initial;
}

.next_button_main {
    height: auto;
    min-height: auto;
    width: auto;
    font-family: inherit;
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    background: none;
    border: none;
}

.form_main_container {
    justify-content: center;
    display: flex;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
    margin: auto;
    max-width: 500px;
}


.form_main_container .radioAnswers {
    display: flex;
    align-items: center;
}

.form_main_container .radioAnswers label.radioLabel {
    border: 1px solid black;
    color: #df4761;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 50px;
    background: white;
    display: flex;
    gap: 5px;
    flex-direction: row-reverse;
    align-items: center;
    margin: auto;
    margin-bottom: 0px;
}

.form_main_container select.dropDownAnsers {
    border-radius: 130px;
    padding: 5px 32px;
    background: #fff;
    border: 1px solid black;
}

.subListItems button,
.listItem .addQuestionButton {
    background: transparent;
    border: none;
    display: flex;
    gap: 5px;
    align-items: center;
    width: 100%;
    color: rgb(44, 110, 203);
}

.subListItems span.plusIcon,
.list_item span.plusIcon {
    background: rgb(44, 110, 203);
    color: #fff;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}


.subListItems .list_item {
    margin-left: 25px;
}

.subListItems .lists {
    display: flex;
    flex-flow: column;
    gap: 5px;
}

.subListItems .list_item span.answer_title {
    margin-left: 10px;
}

.subListItems .lists .list_item:hover {
    width: 100%;
    background: #80808024;
    cursor: pointer;
    border-radius: 5px;
}

.listItem .listItemHeading span.question button {
    cursor: pointer;
}

.globalButton {
    width: auto;
    height: auto;
    padding: 13px;
    border-radius: 0px;
    min-width: 100px;
    font-size: 12px;
    font-family: Jost;
    letter-spacing: 1px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    background-color: rgb(249, 145, 169);
    border: 1px solid rgb(249, 145, 169);
}

.form_main_container .textBoxAnswers label.textBoxLabel {
    width: 268px;
    margin: 0px 10px 20px;
    padding: 50px 50px 50px 40px;
    max-width: 100%;
    min-height: 268px;
    font-family: Jost;
    text-align: left;
    color: rgb(249, 145, 169);
    cursor: pointer;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 0px;
    background: rgb(255, 255, 255);
    transition: all 0.25s ease 0s;
}

label.ImageTextLabel {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-items: flex-start;
    width: 192px;
    max-width: 100%;
    min-height: 192px;
    padding: 30px 20px 20px;
    margin: 0 10px;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 0px;
    background: rgb(255, 255, 255);
    text-align: left;
    font-family: Jost;
    color: rgb(249, 145, 169);
    cursor: pointer;
    transition: all 0.25s ease 0s;
}

.ImageTextMain label.ImageTextLabel img {
    max-width: 100%;
    height: 55px;
    margin-bottom: 35px;
}

.ImageTextMain .form_main_container {
    width: 100%;
    max-width: 100%;
}

label.ImageTextLabel {
    width: 100% !important;
    height: 100% !important;
    min-height: 100%;
    padding: 30px 20px 20px;
}

.ImageTextMain .label_main {
    flex: 0 0 calc(100%/4);
    max-width: calc(100% / 4);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;

}

.ImageTextAnswers {
    width: 100%;
}

label.ImageTextLabel {
    min-height: 100%;
    padding: 30px 20px 20px;
    width: 146px;
    height: 146px;
    width: 192px !important;
    height: 192px !important;
}

.preview_quiz .form_main_container {
    max-width: 100%;
}

.ImageTextMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

label.ImageTextLabel input[type="radio"] {
    position: absolute;
    left: 42%;
    transform: translate(-50%, -50%);
    top: 39%;
    opacity: 0;
}

.range span.rangeSub div:first-child {
    width: 100%;
}

.range span.rangeSub {
    display: flex;
    align-items: center;
}

.range span.currentValueRange {
    padding-left: 5px;
    margin-top: 25px;
}

.rightSection .headingMain {
    font-size: 16px;
    font-weight: 900;
    padding: 8px 20px;
}

.settingItemsSub {
    display: flex;
    gap: 5px;
}

@media screen and (max-width: 767px) {
    .quiz_questions_wrapper {
        width: 100%;
        min-width: 100%;
        min-height: 350px;
        -webkit-box-pack: center !important;
        justify-content: center !important;
        -webkit-box-align: center !important;
        align-items: center !important;
    }

    .next_button_main_container {
        -webkit-box-pack: center;
        justify-content: center;
    }

    .tooltip_main_container {
        margin: 30px auto;
    }

    .quiz_question_main {
        padding: 100px 20px;
        text-align: center !important;
    }

    .quiz_kit_sub {
        min-height: 350px;
    }

    .quiz-kit-content_main {
        flex-direction: column-reverse;
        min-height: 350px;
    }

    .quiz_intro_main {
        width: 100%;
        min-width: 100%;
        min-height: 350px;
        -webkit-box-pack: center !important;
        justify-content: center !important;
        -webkit-box-align: center !important;
        align-items: center !important;
    }

    .quiz_heading_main {
        font-size: 30px !important;
    }

    .helping_text_main {
        font-size: 15px !important;
    }

    .helping_text_main {
        white-space: normal;
        max-width: 100%;
        text-align: inherit;
    }

    .form_main_container {
        -webkit-box-pack: center !important;
        justify-content: center !important;
    }
}

/* leftSection Css */
.leftSection {
    min-width: 300px;
    overflow: auto;
}

.leftSection .subheadTitle {
    color: #615e5e;
    font-weight: 900;
    margin-bottom: 6px;
}

.leftSection .listItemHeading {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.leftSection span.questionNumber {
    font-size: 10px;
    font-weight: 900;
    color: #fff;
    background: gray;
    width: 20px;
    border-radius: 50%;
    height: 20px;
    text-align: center;
}

.leftSection .listItem .question button {
    background: transparent;
    border: none;
    font-weight: 900;
    font-size: 12px;
}

.leftSection .listItemHeading:hover {
    background: #8080801a;
    border-radius: 5px;
}

.listItemHeading {
    padding: 2px;
}

.leftSection .listItemsItems {
    margin-left: 45px;
}

.leftSection .listItemsItems .itemTitle button {
    background: transparent;
    border: none;
}

.leftSection .itemTitle {
    padding: 5px;
    cursor: pointer;
}

.leftSection .itemTitle:hover {
    background: #87ceeb26;
    border-radius: 5px;
}