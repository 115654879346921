.condSection{
    background-color: aliceblue;
    padding: 1rem 2rem;
    margin-top: 2rem;
    max-width: 372px;
}
.gridSection{
    display: grid;
    grid-gap: 2%;
}

.centerFlex{
    align-items: center;
    justify-content: center;
    display: flex;
}
.rightFlex{
    align-items: center;
    justify-content: right;
    display: flex;
}
.action-button {
    display: flex;
    flex: 1 1 0%;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    color: cadetblue;
    align-items: center;
    justify-content: center;
}
hr {
    margin: 0;
    border-width: 0;
    border-collapse: collapse;
    border-top: 0.1rem solid #dfe4e8;
    width: 35%;
}
.boxDiv{
    cursor: pointer;
    z-index: 99999;
    background: rgb(255, 255, 255);
    border-width: 1px 1px 3px;
    border-style: solid;
    border-color: red red rgb(200, 111, 221);
    border-image: initial;
    border-radius: 0.3rem;
    position: relative;
    text-align: center;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 4px 0px;
}