.rewards_html_container {
    position: relative;
    height: 100vh;
}

@font-face {
    font-family: "Avenir";
    font-style: normal;
    font-weight: normal;
    src: url(https://shopapp.chargezen.co/assets/AvenirLTStd-82672b073ab7863a76c0114a13b52f13deffd13dd8920bfd4e30d37949af3778.ttf) format("woff");
}

.laucher_buton_container {
    border: 0;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
    height: 60px;
    outline: 0;
    overflow: hidden;
    position: absolute;
    z-index: 999999999999;
    right: 20px;
    bottom: 20px;
}

.laucher_buton_container button.launcher_button {
    background-color: #6568fe;
    border-radius: 30px;
    min-width: 60px;
    padding: 15px 20px;
    display: flex;
    height: 60px;
    align-items: center;
    gap: 12px;
    border: 0;
    color: inherit;
    font-size: 16px;
    line-height: 30px;
    white-space: nowrap;
}

.laucher_buton_container button.launcher_button span.text {
    color: #fff;
}

.chargezen_loyalt_branding>.panel_container {
    border: 0;
    box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.12);
    max-height: 600px;
    outline: 0;
    overflow: hidden;
    position: absolute;
    width: 360px;
    z-index: 999999999999;
    height: calc(100% - 120px);
    bottom: calc(100px);
    background-color: rgb(255, 255, 255);
    right: 20px;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.panel_container .main_panel_container {
    height: 100%;
}

.panel_header_container.expanded_header.compact_banner {
    height: 160px;
}

.panel_header_container.compact_banner {
    display: block;
    background: linear-gradient(180deg,
            rgba(103, 105, 254, 1) 0%,
            rgba(0, 4, 250, 1) 100%);
    padding: 24px;
    position: relative;
}

* {
    box-sizing: border-box;
}

.panel_fixed_header {
    align-items: center;
    background-color: transparent;
    color: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 12px 8px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999999999999;
    left: 0;
    color: #fff;
}

.panel_fixed_header>.header_content {
    opacity: 0;
    transition: opacity 0.1s ease-in;
}

.panel_header_container.expanded_header.compact_banner button.close_btn.panel_header_icon {
    background-color: transparent;
    background-size: 20px;
    border: none;
    border-radius: 3px;
    color: inherit;
    cursor: pointer;
    font-weight: inherit;
    line-height: inherit;
    font: inherit;
    height: 32px;
    text-align: left;
    transition: all 0.15s ease-in-out;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.panel_header_container.expanded_header.compact_banner .panel_expanded_header .banner_content {
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.panel_header_container.expanded_header.compact_banner .panel_expanded_header .banner_content .header_secondary.heading {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 8px;
}

.panel_header_container.expanded_header.compact_banner .panel_expanded_header .header_primary {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 39px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer {
    -webkit-overflow-scrolling: touch;
    height: calc(100% - 160px);
    overflow-x: hidden;
    overflow-y: scroll;
    overscroll-behavior: contain;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 30px;
    position: relative;
    z-index: 999999999999;
}

.panel_container .main_panel_container .panel_footer {
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 -3px 12px 0 rgba(0, 0, 0, 0.04);
    font-size: 14px;
    height: 52px;
    justify-content: center;
    margin: auto;
    position: absolute;
    width: 100%;
    z-index: 999999999999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.powered_by_container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.panel_container .main_panel_container .panel_footer .powered_by_container .powered_by_text {
    color: #757575;
    font-size: 14px;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container {
    border-radius: 10px;
    animation: fadeSlideIn 0.3s ease;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    background-color: #fff;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.09);
    margin-bottom: 12px;
    opacity: 1;
    overflow: hidden;
    padding: 16px 12px;
    position: relative;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container.referrals_explainer_card_container {
    margin-bottom: 20px;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .heading {
    text-align: center;
    padding-top: 8px !important;
    color: #333;
    font-family: Proxima Nova, arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 8px !important;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container>div .card_description {
    text-align: center;
    color: #637381;
    line-height: 150%;
    margin-bottom: 20px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container.join_program_card_container a.branding_primary_button {
    border-radius: 10px;
    color: #fff;
    background: #6769fe;
    background-image: none;
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    overflow: hidden;
    padding: 15px 32px;
    text-align: center;
    text-overflow: ellipsis;
    touch-action: manipulation;
    transition: background-color 0.1s ease-in;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
    margin-left: auto;
    display: flex;
    max-width: 121px;
    margin-right: auto;
    margin-bottom: 16px !important;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container.join_program_card_container .body_text_wrapper {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin-bottom: 8px !important;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_item_container>button.card_list_item {
    width: 100%;
    border: 0;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    position: relative;
    text-align: left;
    transition: background-color 0.15s ease-in-out;
    margin-bottom: 16px;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_item_container>button.card_list_item i {
    opacity: 0.3;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_item_container>button.card_list_item .card_list_item_content {
    display: flex;
    align-items: center;
    gap: 12px;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_item_container>button.card_list_item .card_list_item_content>img {
    width: 40px;
    height: 40px;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_item_container>button.card_list_item .card_list_item_content .list_item_title {
    line-height: 1.2;
    color: #333;
    font-size: 14px;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_container .card_list_item_container:last-child>button.card_list_item {
    margin: 0;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_container .card_list_item_container:last-child>button.card_list_item {
    margin: 0;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_container .card_list_item.no_hover>.card_list_item_content {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 16px;
    padding: 8px 12px;
    position: relative;
    text-align: left;
    transition: background-color 0.15s ease-in-out;
    width: 100%;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_container .card_list_item.no_hover>.card_list_item_content img.list_item_image {
    width: 40px;
    height: 40px;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_container .card_list_item.no_hover>.card_list_item_content .list_item_text_wrapper .list_item_title {
    font-size: 14px;
    line-height: 1.2;
    color: #333;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_container .card_list_item.no_hover>.card_list_item_content .list_item_text_wrapper .list_item_subtitle {
    color: #637381;
    font-size: 13.5px;
    display: block;
    margin: 5px 0 0;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_container .card_list_item.no_hover:last-child>div {
    margin: 0;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer::-webkit-scrollbar {
    width: 0;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer {
    margin-top: -50px;
}

.panel_container .main_panel_container>.panel_subview {
    position: relative;
    animation: fadeSlideIn 0.3s ease;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    margin-bottom: 0;
    width: 100%;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    overscroll-behavior: contain;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
    z-index: 999999999999;
    background: #fff;
    height: 313px;
}

.panel_container .main_panel_container>.panel_subview .list_title {
    margin-bottom: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-top: 8px;
}

.panel_container .main_panel_container>.panel_subview>.card_list_container.no_hover {
    margin-bottom: 22px;
}

.panel_container .main_panel_container>.panel_subview>.card_list_container.no_hover .card_list_item_container {
    position: relative;
}

.panel_container .main_panel_container>.panel_subview>.card_list_container.no_hover .card_list_item_container .card_list_item {
    align-items: center;
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: inherit;
    line-height: inherit;
    font: inherit;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 8px 12px;
    position: relative;
    text-align: left;
    transition: background-color 0.15s ease-in-out;
    width: 100%;
}

.panel_container .main_panel_container>.panel_subview>.card_list_container.no_hover .card_list_item_container:last-child>div {
    margin: 0;
}

.panel_container .main_panel_container>.panel_subview>.card_list_container.no_hover .card_list_item_container .card_list_item .card_list_item_content {
    display: flex;
    gap: 12px;
}

.panel_container .main_panel_container>.panel_subview>.card_list_container.no_hover .card_list_item_container .card_list_item .card_list_item_content>img {
    width: 40px;
    height: 40px;
}

.panel_container .main_panel_container>.panel_subview>.card_list_container.no_hover .card_list_item_container .card_list_item .card_list_item_content .list_item_text_wrapper {
    line-height: 1.2;
    color: #333;
    font-size: 14px;
}

.panel_container .main_panel_container>.panel_subview>.card_list_container.no_hover .card_list_item_container .card_list_item .card_list_item_content .list_item_text_wrapper .list_item_subtitle {
    font-size: 13.5px;
    color: #637381;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_item_container>button.card_list_item:hover {
    background-color: #fafafa;
}

.panel_container .main_panel_container>.panel_container.with_chargezen_footer .home_view_container .panel_card_container .card_list_item_container:not(:last-child)>button.card_list_item:after {
    content: "";
    background-color: #e6e6e6;
    bottom: -8px;
    content: "";
    height: 1px;
    left: 64px;
    position: absolute;
    width: calc(100% - 64px);
}

.panel_container .main_panel_container>.panel_subview>.card_list_container.no_hover .card_list_item_container:not(:last-child) .card_list_item:after {
    background-color: #e6e6e6;
    bottom: -8px;
    content: "";
    height: 1px;
    left: 64px;
    position: absolute;
    width: calc(100% - 64px);
}

.hidden {
    display: none !important;
}

.panel_container .main_panel_container>.panel_subview::-webkit-scrollbar {
    width: 0;
}

span.icon_container.button_icon_container {
    margin-top: 5px;
}