.earningValues .buy_subscription_checkboxes {
    display: flex;
    gap: 10px;
    margin-top: 8px;
}

.primary_button_wrapper button {
    background: #1B3A58 !important;
    width: fit-content;
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    padding: 10px 17px !important;
}

.primary_button_wrapper button span {
    margin: 0 auto !important;
}

.primary_button_wrapper {
    margin: 0 auto !important;
}

.primary_button_wrapper .Polaris-ButtonGroup__Item {
    margin: 0 auto !important;
}

.programStatusButtonWrapper.activate button {
    background: #27AE60 !important;
    color: #fff !important;
    border-radius: 3px !important;
}

.programStatusButtonWrapper.deactivate button {
    background: #E03030 !important;
    color: #fff !important;
    border-radius: 3px !important;
}

.back_button_wrapper .back-button.pointer {
    margin: 0 !important;
}

@media (max-width: 1541px) {
    .summary_width_trans .Polaris-Layout .Polaris-Layout__Section.Polaris-Layout__Section--secondary {
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: 100% !important;
    }

    .summary_width_trans .Polaris-Layout>.Polaris-Layout__Section {
        width: 100%;
        max-width: 70%;
        padding-right: 0px !important;
        flex: 70%;
        min-width: unset;
    }

    .summary_width_trans .Polaris-Layout .defauleLayoutSection {
        width: 100%;
        max-width: 30%;
    }

    .summary_width_trans .Polaris-Layout .defauleLayoutSection .Polaris-Card__Section {
        padding: 10px;
    }

    .summary_width_trans .Polaris-Layout>.Polaris-Layout__Section:last-child {
        max-width: 94%;
    }
}

@media (max-width: 1199px) {
    .defauleLayoutSection {
        width: 100%;
    }

    .defauleLayoutSection .Polaris-Layout__Section.Polaris-Layout__Section--secondary {
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: 100%;
    }

}

.icon_wrapper {
    width: fit-content;
    margin: 0 25px 0;
    padding: 8px;
    border: 1px solid #ddd;
}

.earningValues .amount_discount_wrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 12px;
}



.earningValues {
    display: flex;
    flex-flow: column;
    gap: 16px;
}