.settings-container .add_staff_settings label {
    display: flex;
    gap: 10px;
}

.settings-container .add_staff_settings {
    display: flex;
    gap: 28px;
    flex-wrap: wrap;
    margin: 32px 0;
}

.submit.staff_submit.submit_button_wrapper {
    display: flex;
    gap: 10px;
    flex-flow: row-reverse;
    justify-content: start;
}