.show_cancellation_instructions_container.cancellation_flow_row_wrapper h2.main_title {
    font-size: 16px;
    font-weight: bold;
    color: #202223;
    margin: 0 0 10px;
}

.show_cancellation_instructions_container.cancellation_flow_row_wrapper h6.description {
    font-size: 14px;
    color: #6d7175;
    font-weight: 400;
}

.show_cancellation_instructions_container.cancellation_flow_row_wrapper .Polaris-FormLayout__Item {
    margin-left: 0;
}

.cancellation_reasons_wrapper .chargezen_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 550px;
    margin: 0 0 20px;
}

.show_cancellation_instructions_container.cancellation_flow_row_wrapper {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.enable_disable_button_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cancellation_reasons_wrapper .chargezen_row .Polaris-ButtonGroup .Polaris-ButtonGroup__Item {
    margin-left: 0 !Important;
}

.add_cancellation_button_wrapper button.add_cancellation_button {
    background: none;
    border: 0;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0;
}

.add_cancellation_button span {
    color: #1f5199;
}

button.enable_disable_button {
    border: 1px solid #ddd;
    background: none;
    padding: 8px 8px 5px;
    text-align: center;
    border-radius: 4px;
}

.add_cancellation_button svg.Polaris-Icon__Svg {
    width: 18px;
    fill: #1f5199;
}

.make_cancellation_reason_optional {
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.make_cancellation_reason_optional span.Polaris-Choice__Label {
    font-weight: 900;
}

.show_cancellation_instructions_wrapper.cancellation_reasons_container h5 {
    font-weight: 800;
}


.show_cancellation_instructions_wrapper {
    display: flex;
    flex-flow: column;
    gap: 12px;
}

.chargezen_html_editor {
    border: 1px solid #b1adad;
    margin-top: 5px;
}

button.enable_disable_button.enabled {
    color: #fff;
    background: #001219;
}

.add_condition_wrapper>.add_condition_wrapper {
    margin-top: 20px;
}


.add_condition_wrapper .selected_conditions_wrapper>.searchPlanWrapper {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid #ddd;
}

.add_condition_wrapper>h2 {
    font-size: 25px;
    margin: 0 0 20px;
}

.show_cancellation_instructions_container.cancellation_flow_row_wrapper {
    padding-bottom: 0;
    border-bottom: 0;
}

.add_condition_wrapper .selected_conditions_wrapper>.searchPlanWrapper h3 {
    margin: 0;
}

.selectVariantMain .product-search {
    margin-top: 5px;
}

.show_cancellation_instructions_container.cancellation_flow_row_wrapper .Polaris-FormLayout--grouped .Polaris-FormLayout__Items {
    gap: 20px;
}

h2.add_condition_heading {
    font-size: 18px;
    color: #000;
    font-weight: 600;
}

.show_cancellation_instructions_container.cancellation_flow_row_wrapper .swap_all_products_wrapper {
    margin-bottom: 5px;
}