button.Polaris-Link>span.Polaris-Navigation__Text {
    opacity: 0 !important;
    white-space: now-wrap !important;
}

.module-navbar.dashboardArea .navBar:hover button.Polaris-Link>span.Polaris-Navigation__Text {
    opacity: 1 !important;
}

button.Polaris-Link:before,
button.Polaris-Link:after {
    display: none !important;
}

.module-navbar.dashboardArea .navBar:hover button.Polaris-Link:before,
.module-navbar.dashboardArea .navBar:hover button.Polaris-Link:after {
    display: block !important;
}

button.Polaris-Link {
    overflow: hidden !important;
    white-space: now-wrap !important;
}

.module-navbar.dashboardArea .navBar:hover button.Polaris-Link {
    overflow: visible !important;
}

.navBar .sidBartabs nav.Polaris-Navigation {
    min-width: unset !important;
}

.navBar .sidBartabs nav.Polaris-Navigation {
    min-width: unset !important;
    max-width: 100% !important;
    transition: all .5s ease-in-out !important;
}

.module-navbar.dashboardArea .navBar:hover .sidBartabs nav.Polaris-Navigation {
    max-width: 240px !important;
}

.module-navbar.dashboardArea .navBar {
    max-width: 60px !important;
}

.module-navbar.dashboardArea .navBar:hover,
.module-navbar.dashboardArea .navBar.opened {
    max-width: 240px !important;

}

.module-navbar.dashboardArea .navBar:hover {
    max-width: 240px;
}

.module-navbar.dashboardArea .chargezen_navbar:hover+.app_content,
.module-navbar.dashboardArea .chargezen_navbar.opened+.app_content {
    max-width: calc(100% - 240px) !important;
}



.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link span.Polaris-Navigation__Text {
    white-space: nowrap !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>button.Polaris-Link>span.Polaris-Navigation__Text {
    min-width: 103px !important;
    opacity: 0 !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation>.Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a span.Polaris-Navigation__Text {
    min-width: 192px;
    opacity: 0 !important;
}

.module-navbar.dashboardArea .navBar:hover nav.Polaris-Navigation>.Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a span.Polaris-Navigation__Text {
    opacity: 1 !important;
}

.module-navbar.dashboardArea .navBar:hover nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>button.Polaris-Link>span.Polaris-Navigation__Text {
    opacity: 1 !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem:Last-child>div:last-child>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem:last-child {
    bottom: 0;
    position: sticky;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>button.Polaris-Link {
    background: transparent !important;
}

.module-navbar.dashboardArea .navBar:hover nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>button.Polaris-Link.Polaris-Navigation__Item--selected.Polaris-Navigation--subNavigationActive {
    background: #fafafa !important;
}

body .module-navbar .app_content {
    max-width: calc(100% - 60px) !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>button.Polaris-Link>span.Polaris-Navigation__Text {
    min-width: 160px !important;
}

.module-navbar.dashboardArea .navBar:hover nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>button.Polaris-Link {
    min-width: 100% !important;
}



.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem:last-child {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #ebebeb !important;
    z-index: 1;
    padding: 5px 0 24px 0px !important;
    max-width: 100%;
}

.navBar .sidBartabs .Polaris-Navigation__PrimaryNavigation {
    overflow-x: hidden !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section {
    margin: 0;
    padding-bottom: 0;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem:last-child>.Polaris-Navigation__ItemWrapper {
    display: none !important;
}


.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link {
    padding: 0px 7px 6px !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link span.Polaris-Icon svg {
    width: 23px;
    height: 23px;
}

.module-navbar.dashboardArea .navBar:hover nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link span.Polaris-Icon svg {
    width: 18px;
    height: 18px;
}

.Polaris-Navigation__PrimaryNavigation::-webkit-scrollbar {
    width: 2px;
}

.Polaris-Navigation__PrimaryNavigation::-webkit-scrollbar-track {
    background: #ddd;
}

.Polaris-Navigation__PrimaryNavigation::-webkit-scrollbar-thumb {
    background: #001219;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link .sub_nav_label {
    display: flex;
    margin-left: -4px;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link .sub_nav_label>span.Polaris-Icon {
    margin: 0;
    position: relative;
    top: -6px;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link .sub_nav_label span {
    color: #616161 !important;
    font-size: 12px !important;
    line-height: 100%;
    margin: 0 !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link .sub_nav_label>span.Polaris-Icon svg {
    fill: #616161 !important;
    color: #616161 !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link .sub_nav_label>span.Polaris-Icon svg path {
    fill: #616161 !important;
}

.app-content .logout_link {
    position: -webkit-sticky !important;
    position: sticky !important;
    bottom: 0;
    z-index: 1;
    background: transparent !important;
    padding: 0 10px 7px;
    max-width: 100%;
    right: 0;
    margin: 0;
    z-index: 2;
}

.navBar .logout_link a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}


.navBar .logout_link a>span.logout_icon svg {
    fill: #fff;
    width: 15px;
    height: 15px;
}

.navBar .logout_link a>span:last-child {
    line-height: 100%;
    position: relative;
    top: 2px;
    font-size: 10px;
}

.app-content .logout_link>a.adminLogoutLink span.logout_icon {
    display: flex;
    line-height: normal !important;
    font-size: 0 !important;
}

.app-content .logout_link>a.adminLogoutLink span.logout_icon span.Polaris-Icon {
    height: auto;
    width: auto;
}

.app-content .logout_link>a.adminLogoutLink {
    width: 100%;
    text-align: center;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem:last-child .Polaris-Navigation__SecondaryNavigation {
    overflow: visible !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem:last-child>div>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link {
    padding: 0 0 0px 5px !important;
}

.app-content .logout_link>a.adminLogoutLink>span:last-child {
    display: none;
}

.module-navbar.dashboardArea .navBar:hover>.logout_link>a.adminLogoutLink>span {
    display: block;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation>.Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a>span.Polaris-Navigation__Text {
    opacity: 1 !important;
    color: transparent !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation>.Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a>span.Polaris-Navigation__Text span {
    color: transparent !important;
}


.module-navbar.dashboardArea .navBar:hover nav.Polaris-Navigation>.Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a>span.Polaris-Navigation__Text,
.module-navbar.dashboardArea .navBar:hover nav.Polaris-Navigation>.Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a>span.Polaris-Navigation__Text * {
    color: #444 !important;
}

.toggleNavWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggleNavWrapper span {
    margin: 0 !important;
    font-size: 12px !important;
}

.hidden {
    display: none !important;
}