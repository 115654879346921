.wrapper {
	/* padding: 10px; */
	
}
.wrapper button {
	background-color: #000000;
	border-radius: 3px;
}
.button-bar {
	padding: 10px;
	display: flex;
	justify-content: flex-end;
	background-color: #E2F5FF !important;
	margin-top: 14px;
	
}

.button-bar  button {
	padding: 14px 18px !important;
}
.text-wrapper {
	margin: 20px 0px;
	font-size: 16px;
	line-height: 24px;
}
.export-wrapper {
	padding: 30px;
	border: 1px solid #C4CDD5;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	
}

.export-wrapper >* {
	margin: 20px 0px;
}

.export-wrapper strong {
	margin: 0;
}

/* for password page */
.default-pass {
	margin-bottom: 57px;
	margin-top: 51px;
	font-size: 25px;
}


.default-pass strong {
  font-size: 18px;
	margin-left: 42px;
}

.password-btn {
	/* padding-bottom: 55px; */
	/* margin-top: 30px; */
}
.password-btn button {
  padding: 14px 39px;
}
.password-btn .cancel-pass-btn button {
  background-color: #4D506A;
	color: white;
	border: 0;
	padding: 14px 32px;
}
.password-btn .cancel-pass-btn button:hover {
  background: #4D506A;
	color: white;
	border: 0;
	padding: 14px 32px;
}


.password-fields .Polaris-Connected {
	max-width: 616px;
	margin-bottom: 30px;
}



/* next form */

.bread-bar{
  padding: 16px;
  display: flex;
  background-color:#E2F5FF !important;
}
.bread-bar > a{
  cursor: pointer;
  margin :0px 4px;
}

.input-section {
  margin-left: 16px;
	margin-bottom: 30px;
}
.input-section  p {
  font-size: 18px;
  margin-bottom: 5px;
}
.Polaris-Card__Section{
  /* padding-top: 2px !important; */
}
.Polaris-Card__Section > *{
	/* margin: 10px 0px !important; */
} 

.create-input .Polaris-Select {
	max-width: 595px;
}

#logs-table {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
  }
  
  #logs-table td, #logs-table th {
	border: 1px solid #ddd;
	padding: 8px;
  }
  
  #logs-table tr:nth-child(even){background-color: #f2f2f2;}
  
  #logs-table tr:hover {background-color: #ddd;}
  
  #logs-table th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: #000000;
	color: white;
  }