.Polaris-Pagination {
    width: 100%;
    justify-content: center;
}

.subscription_orders_main .Polaris-DataTable__Cell--numeric {
    text-align: left !important;
}


.Polaris-Page-Header.Polaris-Page-Header--noBreadcrumbs.Polaris-Page-Header--longTitle {
    padding-top: 0;
    padding-bottom: 101px;
}

.chargezen-summary-wrapper {
    display: flex;
}

.chargezen-summary-wrapper .summary_right_section {
    width: 70%;
    max-width: 70%;
}

.chargezen-summary-wrapper .summary_width_trans {
    transition: width 0.5s ease;
}

.chargezen-summary-wrapper.detail_view {
    display: flex;
    justify-content: end;
}

.chargezen-summary-wrapper .full_width {
    width: 100%;
}

.chargezen-summary-wrapper .summary_main_div {
    width: 100%;
    max-width: 30%;
    padding-right: 30px;
}

.chargezen-summary-wrapper .summary_main_div .summary_heading {
    color: #212B36;
    font-family: Avenir;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 15px;
}

.chargezen-summary-wrapper .summary_main_div .summary_body_text {
    color: #6D7175;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 284px;
    margin: 0 0 15px;
}


.tabButtons .expand_view_button {
    padding: 0;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    color: #212B36;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.chargezen-summary-wrapper {
    position: relative;
    margin-top: 80px;
}

.chargezen-summary-wrapper .button_wrapper {
    position: absolute;
    right: 0;
    top: -60px;
}

.subscription_orders_wrapper.chargezen-summary-wrapper {
    margin-top: 95px;
}

body .Polaris-Frame {
    padding-top: 1px !important;
}

.rangePicker {
    display: flex;
    justify-content: end;
    margin-top: 10px;
}

.rangePicker button {
    min-width: 290px;
}

.next_try_wrapper .status {
    color: #dc0a0a;
}

.next_try_wrapper .status::before {
    position: unset;
    content: "";
    width: 7px;
    height: 7px;
    background: unset;
    border-radius: 50%;
    left: -6px;
    top: 13px;
}


.Polaris-Card .Polaris-Card__Header span {
    margin-right: 0;
}

.subscription_orders_main .Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .defauleLayoutSection {
    width: 100%;
    max-width: 25%;
}

.chargezen-summary-wrapper .summary_right_section .Polaris-Layout .Polaris-Layout__Section {
    max-width: 100%;
}

.summary_width_trans.summary_right_section .setGlobalBillingHour div:first-child {
    display: flex;
    flex-flow: column;
    gap: 15px;
}

/* .summary_width_trans.summary_right_section .setGlobalBillingHour {
    margin-bottom: 20px;
} */

.summary_width_trans .update_billing_hour_wrapper {
    display: flex;
    flex-flow: column;
    gap: 15px;
}

.summary_width_trans .update_billing_hour_button_wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
}

.summary_width_trans .message_helptext_wrapper {
    color: #686767;
}

.subscription_orders_main .Polaris-Frame {
    min-height: unset;
}

.back_button_wrapper {
    width: 100%;
    justify-content: flex-start;
}

.back_button_wrapper .text_wrapper.page_heading_title_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.back_button_wrapper .text_wrapper.page_heading_title_wrapper .create_selling_plan_subscription_wrapper {
    gap: 8px;
    display: flex;
}

.back_button_wrapper .text_wrapper.page_heading_title_wrapper .create_selling_plan_subscription_wrapper button.Polaris-Button {
    color: #fff;
    background-color: #2d7cf5;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child button.Polaris-Button.Polaris-Button--plain {
    background: transparent !important;
}

.back_button_wrapper .text_wrapper.page_heading_title_wrapper .create_selling_plan_subscription_wrapper button.Polaris-Button:hover {
    background: #000;
    border-color: #000;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup {
    flex-flow: row;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack__Item.Polaris-Stack__Item--fill {
    margin-top: 0;
}

.heading_title .card_title_desc {
    position: unset;
}

.heading_title {
    flex-flow: column;
    align-items: flex-start;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline {
    margin: 0;
    flex-flow: row;
    align-items: center;
    gap: 30px;
    width: 100%;
}

.Polaris-Card .Polaris-Card__Header {
    display: flex;
    flex-flow: row !important;
}


.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item {
    margin: 0;
    width: 100%;
    max-width: fit-content;
}

.heading_title .card_title_desc {
    position: static;
    padding: 0;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline {
    justify-content: space-between;
}


.subscription_orders_main.subscription_workflows_wrapper>.create_workflow_wrapper {
    margin: 0;
}

.subscription_orders_main.subscription_workflows_wrapper>.create_workflow_wrapper>.Polaris-Frame {
    margin: 0 !important;
}

.subscription_orders_main.subscription_workflows_wrapper>.create_workflow_wrapper>.Polaris-Frame>main#AppFrameMain {
    margin: 0 !important;
}

.subscription_orders_main.subscription_workflows_wrapper>.create_workflow_wrapper>.Polaris-Frame>main#AppFrameMain>.Polaris-Frame__Content {
    margin: 0 !important;
}

.subscription_orders_main.subscription_workflows_wrapper>.create_workflow_wrapper>.Polaris-Frame>main#AppFrameMain>.Polaris-Frame__Content>.Polaris-Page {
    margin: 0 !important;
}

.subscription_orders_main.subscription_workflows_wrapper>.create_workflow_wrapper>.Polaris-Frame>main#AppFrameMain>.Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content {
    margin: 0 !important;
}

.tabButtons>button.expand_view_button svg.Polaris-Icon__Svg {
    transform: none;
}

.heading_title>span {
    display: none;
}

.sidebar_summary_main_container .summary_width_trans.full_width .Polaris-Card__Header .Polaris-Stack__Item .Polaris-ButtonGroup__Item>button.Polaris-Button.Polaris-Button--plain {
    padding-right: 0 !important;
}

.tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
    padding: 0 !important;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item.Polaris-Stack__Item--fill {
    max-width: fit-content;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain {
    margin: 0;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain {
    padding: 0 !important;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain>span.Polaris-Button__Content {
    width: 100%;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain>span.Polaris-Button__Content span.Polaris-Button__Text {
    width: 100%;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain>span.Polaris-Button__Content span.Polaris-Button__Text>.tabButtons {}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain>span.Polaris-Button__Content span.Polaris-Button__Text>.tabButtons>.Polaris-ButtonGroup {
    margin: 0;
    width: auto;
    max-width: unset;
    padding-right: 15px;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain>span.Polaris-Button__Content span.Polaris-Button__Text>.tabButtons>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item {
    margin: 0;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain>span.Polaris-Button__Content span.Polaris-Button__Text>.tabButtons>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item {}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain>span.Polaris-Button__Content span.Polaris-Button__Text>.tabButtons>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item>button {
    width: 100%;
    height: auto !important;
    line-height: normal !important;
    min-height: unset !important;
    padding: 10px 5px !important;
    white-space: nowrap;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain>span.Polaris-Button__Content span.Polaris-Button__Text>.tabButtons>button.expand_view_button {
    padding: 7px 10px !important;
    width: auto !important;
    align-items: center;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain>span.Polaris-Button__Content span.Polaris-Button__Text>.tabButtons>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item:empty {
    display: none;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain>span.Polaris-Button__Content span.Polaris-Button__Text>.tabButtons>.Polaris-ButtonGroup {
    gap: 10px;
    align-items: center;
}

button.Polaris-Button.Polaris-Button--destructive {
    background: #e02d24 !important;
}

button.Polaris-Button.Polaris-Button--destructive span.Polaris-Button__Content {
    display: flex;
    justify-content: center;
    align-items: center;
}

button.Polaris-Button.Polaris-Button--destructive span.Polaris-Button__Content {
    width: fit-content !important;
}

button.Polaris-Button.Polaris-Button--destructive span.Polaris-Button__Content span.Polaris-Button__Icon {
    width: fit-content !important;
}

button.Polaris-Button.Polaris-Button--destructive span.Polaris-Button__Content span.Polaris-Button__Icon svg.Polaris-Icon__Svg {
    height: 70% !important;
    width: fit-content !important;
}

.shpoify_style_button_wrapper button.Polaris-Button:nth-child(odd) {
    border: 0 !important;
}

@media(max-width:1439px) {
    .subscription_orders_main .Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .defauleLayoutSection {
        width: 100%;
        max-width: 39%;
    }


}

@media screen and (max-width:1470px) {
    .Polaris-Card .Polaris-Card__Header span {
        margin-right: 0;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item {
        padding: 0;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
        font-size: 14px;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary .Polaris-Button__Text {
        font-size: 12.5px;
    }

    .Polaris-Card .Polaris-Card__Header {
        padding: 15px 15px 10px;
    }
}

.subscription_orders_main .subscription_orders_main .Polaris-Page {
    margin: 0;
}

.module-navbar .app_content {
    min-height: 100vh;
}

.setGlobalBillingHour {
    /* padding: 20px; */
    /* border-bottom: 1px solid #ddd; */
}

.setGlobalBillingHour .Polaris-Select {
    margin: 7px 0 15px;
}


.update_billing_hour_wrapper {
    padding: 20px;
}

.order_notes_attribute_wrapper .order_attributes_wrapper {
    display: flex;
}

.order_notes_attribute_wrapper .order_attributes_wrapper>div {
    width: 100%;
    max-width: calc(100% - 36px);
}


.order_notes_attribute_wrapper .sub_attributes_wrapper>.order_attributes_wrapper:first-child div {
    max-width: 100%;
}

.sub_attributes_wrapper {
    margin: 15px 0 15px;
    display: flex;
    flex-flow: column;
    gap: 15px;
}

.order_notes_attribute_wrapper .order_attributes_wrapper button.order_attribute_destroy_button {
    background: transparent;
    border: 0;
}

.global_setting_top_border {
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.Polaris-Collapsible {
    opacity: 1 !important;
}

.created_at_filter_wapper {
    display: flex;
    flex-direction: column;
}

.text_wrapper.page_heading_title_wrapper .summary_body_text {
    color: #6d7175;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 10px 0 0;
}

.header_add_button_wrapper button.Polaris-Button.Polaris-Button {
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: unset;
    color: #000;
}

.header_add_button_wrapper button.Polaris-Button.Polaris-Button span.Polaris-Button__Text {
    color: #000;
    margin-top: 7px;
}

.header_add_button_wrapper button.Polaris-Button.Polaris-Button span.Polaris-Button__Text span.Polaris-Button__Content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscription_orders_wrapper.chargezen-summary-wrapper .summary_width_trans.summary_right_section .Polaris-Layout .Polaris-Page-Header.Polaris-Page-Header--noBreadcrumbs {
    position: absolute;
    right: 0;
    top: -49px;
    padding: 0;
}



.subscription_orders_wrapper.chargezen-summary-wrapper .summary_main_div {
    padding-top: 60px;
}

.subscription_orders_wrapper.chargezen-summary-wrapper .summary_width_trans.summary_right_section {
    padding-top: 50px;
}

.subscription_orders_wrapper.chargezen-summary-wrapper .summary_width_trans.summary_right_section *,
.subscription_orders_wrapper.chargezen-summary-wrapper .summary_width_trans.summary_right_section {
    overflow: visible;
}

.subscription_orders_wrapper.chargezen-summary-wrapper .summary_width_trans.summary_right_section .Polaris-Page__Content {
    margin: 0;
}

.subscription_orders_main .Polaris-Page__Content {
    margin-top: -20px !important;
}

.header_add_button_wrapper {
    display: flex;
    gap: 5px;
    flex-flow: row-reverse;
}

.header_add_button_wrapper button.Polaris-Button.Polaris-Button--destructive {
    background: linear-gradient(180deg, #e6391a, #d53417);
    color: #fff;
}

button.Polaris-Button.Polaris-Button--destructive * {
    color: #fff !important;
    background: linear-gradient(180deg, #e6391a, #d53417);
    fill: #fff;
}

.summary_heading {
    font-weight: 800;
    font-family: AvenirLTStd;
}

.sidebar_summary_main_container .summary_width_trans.full_width .Polaris-Card__Header .Polaris-Stack__Item .Polaris-ButtonGroup__Item {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.sidebar_summary_main_container .summary_width_trans.full_width .Polaris-Card__Header .Polaris-Stack__Item .Polaris-ButtonGroup__Item>button.Polaris-Button.Polaris-Button--plain {
    width: 100%;
    justify-content: flex-end;
}

.tabButtons {
    display: flex;
}

.tabButtons>button.Polaris-Button {
    background: #f5f7fb;
}

.tabButtons>button.Polaris-Button span.Polaris-Button__Text a {
    font-weight: 800;
    color: #017efa !important;
    text-decoration: none;
    font-family: 'Avenir';
}



.heading_title .card_title_desc {
    font-size: 14px;
    color: #727272;
    position: absolute;
    bottom: 5px;
}

.heading_title {
    position: relative;
}



.heading_title {
    position: relative;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child {
    max-width: fit-content !important;
    flex: unset !important;
    white-space: unset !important;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child {
    max-width: fit-content !important;
    padding: 0 !important;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child .heading_title {
    flex-wrap: wrap;
    grid-row-gap: 0;
    white-space: unset !important;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child .heading_title .card_title_desc {
    position: unset;
    display: block;
    word-break: break-word !important;
    max-width: 100%;
    box-sizing: border-box;
    white-space: unset !important;
    width: 100% !important;
    flex: unset !important;
    line-height: 150%;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item {
    width: 100%;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item>button.Polaris-Button {
    width: 100%;
    justify-content: flex-end;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item>button.Polaris-Button span.Polaris-Button__Content {
    width: 100%;
    justify-content: flex-end;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item>button.Polaris-Button span.Polaris-Button__Content>span.Polaris-Button__Text {
    width: 100%;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item>button.Polaris-Button span.Polaris-Button__Content>span.Polaris-Button__Text>.tabButtons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
}

.tabButtons>button.expand_view_button {
    background: #f5f7fb !important;
    padding: 9px 16px 7px;
    border: 0;
    overflow: visible !important;
    display: flex;
    align-items: center;
    gap: 9px;
    color: #017efa;
    font-weight: 600;
    font-family: 'Avenir' !important;
    font-size: 15px;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child>.Polaris-ButtonGroup {
    overflow: visible;
}


.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item>button.Polaris-Button span.Polaris-Button__Content>span.Polaris-Button__Text>.tabButtons>button.expand_view_button>span+span.Polaris-Icon {
    position: relative;
    top: 0;
}



.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item>button.Polaris-Button span.Polaris-Button__Content>span.Polaris-Button__Text>.tabButtons>button.expand_view_button svg.Polaris-Icon__Svg {
    transform: none;
}

.heading_title .card_title_desc {
    top: unset !important;

}


@media(max-width:1439px) {

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child {
        max-width: fit-content !important;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child {
        max-width: fit-content !important;
    }

}

@media(max-width:1365px) {

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child {
        max-width: fit-content !important;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child {
        max-width: fit-content !important;
    }

}

@media(max-width:1279px) {

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child {
        max-width: 100% !important;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child {
        max-width: 100% !important;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack {
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child {
        justify-content: flex-start;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child .heading_title .card_title_desc {
        padding-left: 0;
    }

}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child .heading_title {
    flex-wrap: wrap;
    grid-row-gap: 0;
    white-space: unset !important;
    position: unset;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child {
    max-width: fit-content !important;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child {
    max-width: fit-content !important;
    padding: 0 !important;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child .card_title_desc {
    padding: 0;
    position: absolute !important;
    bottom: 0 !important;
    top: unset;
}

.chargezen-summary-wrapper .full_width .Polaris-Card__Header .Polaris-Stack.Polaris-Stack--alignmentBaseline {
    position: relative;
    padding-bottom: 25px;
    flex-wrap: wrap;
    flex-direction: unset;
    justify-content: space-between;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item>button.Polaris-Button span.Polaris-Button__Content {
    margin: 0 !important;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
    font-size: 15px !important;
}

html body .customer_migration button.Polaris-Button {
    background: transparent !important;
    color: #000 !important;
    box-shadow: unset !important;
}

.tabButtons {
    justify-content: flex-end;
}


.tabButtons button.expand_view_button span.Polaris-Icon.Polaris-Icon--colorBase.Polaris-Icon--isColored {
    width: auto !important;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill {
    position: static;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .heading_title {
    position: static;
}

.Polaris-Card .Polaris-Card__Header {
    position: relative;
    padding-bottom: 30px;
}

.summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section {
    margin: 0 !important;
    padding: 0 0;
}

.app_content .subscription_orders_main>.Polaris-Layout {
    margin-left: 0 !important;
}

.app_content .subscription_orders_main>.Polaris-Layout>.Polaris-Layout__Section {
    max-width: 100%;
}

.app_content .subscription_orders_main>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Page {
    margin: 0;
}

.app_content .subscription_orders_main>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Page>.Polaris-Page__Content {
    margin-top: 0 !important;
}


.app_content .subscription_orders_main>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Page>.Polaris-Page__Content table.Polaris-DataTable__Table th,
.app_content .subscription_orders_main>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Page>.Polaris-Page__Content table.Polaris-DataTable__Table td {
    white-space: unset;
    min-width: 190px;
}

.app_content .subscription_orders_main>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Page>.Polaris-Page__Content .table.customer-subscription-tbl>div>.Polaris-DataTable__Navigation {
    display: none;
}

.tabButtons .header_add_button_wrapper {
    margin-right: 10px;
}

@media(max-width:1600px) {
    .tabButtons>button.expand_view_button {

        padding: 9px 8px 7px;
        font-size: 15px;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup {
        overflow: unset;
    }

}

@media(max-width:1535px) {
    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
        padding: 5px !important;
    }

    .tabButtons>button.expand_view_button {

        padding: 5px;
    }
}

@media(max-width:1439px) {
    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .heading_title {
        font-size: 18px;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child {
        max-width: fit-content !important;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child {
        max-width: fit-content !important;
    }

    .tabButtons>button.expand_view_button {
        padding: 9px 9px 7px;

    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
        font-size: 13px !important;
    }

    .tabButtons>button.expand_view_button {
        font-size: 14px;
    }
}

@media(max-width:1365px) {
    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child {
        max-width: fit-content !important;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child {
        max-width: fit-content !important;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .heading_title {
        font-size: 16px;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child {
        max-width: 100% !important;
    }

    .chargezen-summary-wrapper .full_width .Polaris-Card__Header .Polaris-Stack.Polaris-Stack--alignmentBaseline {
        flex-flow: column !important;
        align-items: flex-start;
        gap: 10px;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child {
        max-width: 100% !important;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:last-child {
        max-width: 100% !important;
        justify-content: flex-start;
        padding: 0 0 10px !important;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill {
        max-width: fit-content !important;
        max-width: fit-content !important;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item {
        max-width: fit-content !important;
        max-width: fit-content !important;
    }

    html body .customer_migration button.Polaris-Button {
        background: transparent !important;
        color: #000 !important;
        box-shadow: unset !important;
    }


    .tabButtons>button.expand_view_button.expand_view_button span:first-child {
        display: block;
        min-width: 98px;
    }


    .tabButtons {
        justify-content: flex-end;
    }
}

@media(max-width:1279px) {
    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child .heading_title {
        flex-wrap: nowrap;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item:first-child {
        max-width: 100% !important;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .heading_title {
        font-size: 20px;
    }

    .sidebar_summary_main_container>.sidebar_summary_main_left_section .chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack {
        gap: 5px;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item {
        max-width: 100%;
        flex: 100%;
        padding-bottom: 15px;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill {
        max-width: 100%;
        flex: 100%;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .tabButtons {
        justify-content: flex-start;
    }

    .heading_title .card_title_desc {
        padding-left: 0;
    }
}



.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view>.summary_width_trans.full_width .Polaris-Layout {
    margin-left: 0 !important;
}

.summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section>.Polaris-Card {
    margin: 0 !important;
}

.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view>.summary_width_trans.full_width>.subscription_orders_main * {
    margin-top: 0 !important;
}

.tabButtons>button.Polaris-Button span.Polaris-Button__Text {
    color: #8b8585;
}

.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view * {
    margin-top: 0;
}



.tabButtons>button.Polaris-Button {
    box-shadow: unset !important;
    border: 0;
    background: transparent;
    cursor: pointer;
    width: fit-content;
}

th.Polaris-DataTable__Cell.Polaris-DataTable__Cell--verticalAlignTop.Polaris-DataTable__Cell--header.Polaris-DataTable__Cell--numeric {
    text-align: left;
}

td.Polaris-DataTable__Cell.Polaris-DataTable__Cell--verticalAlignTop.Polaris-DataTable__Cell--numeric {
    text-align: left;
}

.Polaris-DataTable__Navigation {
    display: none !important;
}

.setGlobalBillingHour:not(:first-child) {
    margin-top: 18px !important;
}