* {
    box-sizing: border-box;
}

.chargezen_subscription_widget_wrapper {
    background: rgb(255, 255, 255);
    border-radius: 5px;
    padding: 20px;
    border: 1px solid rgb(204, 204, 204);
    max-width: 465px;
}

.chargezen_subscription_widget_wrapper .chargezen-purchase-options-label {
    font-size: 15.5px;
    color: #202223;
}

.chargezen_subscription_widget_wrapper .chargezen-purchase-options-label {
    font-size: 15.5px;
    color: #202223;
    font-family: "Avenir";
}

.chargezen_subscription_widget_wrapper {
    font-family: "Avenir";
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset {
    margin: 10px 0 15px;
    border-radius: 0.5em !important;
}


.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div {
    padding: 15.4px;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div {
    padding: 15.4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div:first-child {
    align-items: center;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div label {
    font-weight: 600;
    line-height: 1.2em;
    font-size: 14px;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div input {
    margin: 0 13px 0 0;
    position: relative;
    top: -2px;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div .chargezen-subscription-group-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div>div:last-child {
    width: 100%;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div .chargezen-subscription-group-wrapper .chargezen-subscription-group-discount-badge {
    color: #000000 !important;
    background-color: #f7c324 !important;
    padding: 4px 10px 2px;
    border-radius: 1.2em !important;
    line-height: normal !important;
    margin-left: 14px;
    font-size: 12.6px;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div .chargezen-subscription-group-price-container {
    width: auto !important;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div .chargezen-subscription-group-price-container .chargezen-subscription-group-price-amount {
    font-size: 14px;
    color: #202223;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div>div.chargezen-subscription-group-selling-plans-container {
    margin-left: 27px;
    margin-top: 7px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div>div.chargezen-subscription-group-selling-plans-container select {
    border-radius: 5px !important;
    margin: 6px 0px 0px 12px !important;
    padding: 7px 35px 7px 9.5px !important;
    text-transform: none !important;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div>div.chargezen-subscription-group-selling-plans-container .chargezen-selling-plan-selector-label {
    margin-top: 7px;
    font-size: 14px;
    color: #202223;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div>div.chargezen-subscription-group-selling-plans-container .chargezen-selling-plan-selector-description {
    font-size: 14px;
    line-height: 1em !important;
    color: #202223;
    margin: 6px 0 0;
}

.chargezen-tooltip {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
}

.chargezen-tooltip .chargezen-tooltiptext {
    position: absolute;
    top: 100%;
}

.chargezen-tooltip .chargezen-tooltip-label {
    font-size: 14px;
    color: #000;
    position: relative;
    top: 2px;
}

.chargezen-tooltip-image {
    height: 15px;
    width: 15px;
    margin-right: 12px;
}

.chargezen-tooltiptext {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-flex;
    display: flex;
    visibility: hidden;
    width: 70%;
    background-color: #081d33;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 1em;
    margin-top: 1em;
    position: absolute;
    z-index: 9999 !important;
    top: 100%;
    left: 0;
}

.chargezen-container-arrow.chargezen-container-arrow--tl {
    left: 32px;
    top: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-transform: translate(50%, -50%) rotate(45deg);
    transform: translate(50%, -50%) rotate(45deg);
    background-color: #081d33 !important;
    color: #fff !important;
    height: 16px;
    width: 16px;
}

.chargezen-tooltip:hover>.chargezen-tooltiptext {
    visibility: visible;
}

.chargezen-tooltip:hover>.chargezen-tooltiptext .chargezen-container-arrow.chargezen-container-arrow--tl {
    position: absolute;
}

.chargezen-tooltip .chargezen-tooltiptext p {
    margin: 0;
}

.chargezen-tooltip .chargezen-tooltiptext p strong {
    font-size: 14px;
    font-weight: 700;
    font-family: "Avenir-Black";
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div {
    padding: 15.4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    border: 2px solid #c3cfd9 !important;
}


.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div:first-child {
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 0;
}

.chargezen_subscription_widget_wrapper .chargezen-selling-plan-fieldset>div:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* compulsary css */
fieldset.chargezen-plan-picker .chargezen-group-container input {
    display: none;
}

fieldset.chargezen-plan-picker .chargezen-group-container label.chargezen-group-label .chargezen-group-topline .chargezen-radio__container {
    display: none;
}