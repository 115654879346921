/* @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/Gilroy-Regular.ttf') format('truetype');
} */

.navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #001219 !important;
    color: white;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 125%;
    position: fixed;
}

.logout_link {
    margin: 8px;
}

.navBar .logout_link span {
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.navBar .logout_link a {
    text-decoration: none;
    background: #4a4a4aa6;
    color: #ffff;
    border-radius: 4px;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 12px;
    margin: 0 0 0 auto;
}

.app-content .logout_link {
    display: flex !important;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    padding-right: 15px;
}

.app-content .logout_link span {
    text-align: center;
    white-space: nowrap;
}

.app-content .logout_link>div {
    max-width: 100%;
    display: block !important;
}

.dashboardArea,
.loginWithoutShopMain {
    display: flex;
    width: 100%;
}

.navBar {
    display: unset;
    height: 100vh;
    padding-top: 2%;
    background-color: #001219 !important;
    background-image: radial-gradient(circle at 110% 100%, #022a7c, rgba(0, 0, 0, 0) 25%), radial-gradient(circle at -20% 30%, #022b80, rgba(0, 0, 0, 0) 41%) !important;
    box-shadow: inset -1px 0px 0px #E4E5E7;
    width: 100%;
    max-width: 18%;
    transition: all 0.5s ease-in-out;
}

.navBar .sidBartabs ul.Polaris-Tabs.Polaris-Tabs--fillSpace {
    display: grid;
}

.navBar .sidBartabs ul.Polaris-Tabs.Polaris-Tabs--fillSpace {
    height: 100%;
}

.sidBartabs .Polaris-Tabs.Polaris-Tabs__TabMeasurer {
    visibility: visible;
    display: unset;
    width: 100%;
}

.navBar .sidBartabs {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
}

.navBar .sidBartabs>div {
    height: 100%;
}

.navBar .sidBartabs>div ul.Polaris-Tabs.Polaris-Tabs--fillSpace {
    display: none;
}

.navBar .sidBartabs>div .Polaris-Tabs__Wrapper {
    height: 100%;
}

.sidBartabs .Polaris-Tabs.Polaris-Tabs__TabMeasurer li.Polaris-Tabs__TabContainer {
    display: block;
    text-align: left;
    height: unset;
}

.sidBartabs .Polaris-Tabs.Polaris-Tabs__TabMeasurer li.Polaris-Tabs__TabContainer button span.Polaris-Tabs__Title {
    text-align: left;
}

.sidBartabs .Polaris-Tabs.Polaris-Tabs__TabMeasurer li.Polaris-Tabs__TabContainer button {
    padding: 0;
}



.navBar .sidBartabs ul li a span {
    margin-left: 15px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 125%;
    color: #ffff;
}

.navBar .sidBartabs ul li a span.Polaris-Icon svg {
    fill: #5c6ac4;
}

.navBar .sidBartabs ul li {
    margin-bottom: 3px;
}

.navBar .sidBartabs nav.Polaris-Navigation {
    min-width: 100%;
}



.navBar .sidBartabs ul li a {
    padding: 1px 0;
    margin: 0;
    border-radius: 0;
    position: relative;
}

.navBar .sidBartabs ul li a span.Polaris-Navigation__Text {
    padding-top: 4px;
}

.navBar .sidBartabs ul li a span.Polaris-Navigation__Text {
    padding-top: 5px;
}

.navBar .sidBartabs ul li .Polaris-Navigation__Icon svg.Polaris-Icon__Svg path {
    fill: #ffff;
}

.navBar .sidBartabs ul li a.Polaris-Navigation--subNavigationActive span.Polaris-Icon svg path {
    fill: #fff;
}

.navBar .sidBartabs ul li .Polaris-Navigation--subNavigationActive:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 36px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.navBar .sidBartabs ul li:hover:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 36px;
    background: transparent !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

body .Polaris-Frame {
    overflow: auto;
    padding-top: 40px;
}

body.chargezen {
    overflow: auto;
}

.module-navbar .app_content {
    position: relative;
    left: 18%;
    max-width: 82%;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.navBar .sidBartabs .Polaris-Navigation {
    background: #001219;
}

.navBar .changeStoreDiv {
    padding: 0 22px;
}

.changeStoreDiv .Polaris-Select {
    color: black;
}

.navBar .sidBartabs ul li a span.Polaris-Navigation__Text {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 15px;
    color: #51CBFF;
    text-transform: uppercase;
}

.Polaris-Navigation__Item:hover {
    background-color: transparent;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Collapsible {
    opacity: 1 !important;
}


.Polaris-Navigation__SecondaryNavigation li.Polaris-Navigation__ListItem a span {
    font-family: 'Avenir' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 25px;
    color: #CCD2E3 !important;
    text-transform: capitalize !important;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__List li:hover:before {
    background: #1B2B65 !important;
    border-radius: 5px;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__List li:hover * {
    color: #fff;
}

.navBar .sidBartabs ul li:hover * {
    color: #fff;
}

.navBar .sidBartabs ul li:hover span.Polaris-Icon svg path {
    fill: #fff;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation--subNavigationActive {
    background: #fafafa !important;
    border-radius: 5px !important;
    padding: 10px 0px !important;
}

.Polaris-Navigation__Item {
    padding: 0 16px !important;

}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item {
    padding: 1px 0 !important;
}

.navBar .logo {
    align-items: center;
    display: flex;
    margin-bottom: 21px;
}


.header_main_div {
    justify-content: space-between;
    display: flex;

}

.header_main_div .second_div,
.header_main_div .header_main_div .first_div {
    display: flex;
    align-items: center;
}

.notification {
    width: 100%;
    border-left: 1px solid #DADADA;
    max-width: 50px;
    position: relative;
    height: 35px;
    display: flex;
    justify-content: center;
}

.main_header h2 {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    padding-bottom: 20px;

}

.user_details:before {
    content: '';
    position: absolute;
    right: 16px;
    width: 1px;
    height: 100%;
    right: -19px;
    background: #DADADA;
}

.user_details {
    position: relative;
}


.header_main_div .first_div {
    display: flex;
    align-items: center;
}

.status {
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: #30D988;
    position: relative;
    height: 35px;
    display: flex;
    align-items: center;

}


.first_div {
    display: flex;
    align-items: center;
}

.header_main_div .search_div {
    position: relative;
    padding-left: 20px;
    margin-left: 15px;
}

.header_main_div .search_icon {
    position: absolute;
    left: 32px;
    top: 8px;
    z-index: 1024;

}

.header_main_div .search_icon svg {
    width: 18px;
    height: 18px;
}

.main_header {
    padding: 8px 0px;
    border: none !important;
}

.header_main_div .search_icon path {
    fill: #828282;
}

.header_main_div .search_div input {
    padding: 0 40px;
}

.header_main_div .search_div .Polaris-TextField {
    border: 0 !important;
}

.header_main_div .Polaris-TextField__Backdrop {
    border: 0;
    background: transparent;
}

.status:before {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    background: #30D988;
    border-radius: 50%;
    left: -6px;
    top: 13px;
}

.navBar .logo img {
    margin: auto;
}

.avatar {
    height: fit-content;
    padding: -49px !important;
    padding: 7px;
}

.avatar h2 {
    padding: 0 !important;
    font-size: 15px !important;
    margin-bottom: -7px;
}

.notification {
    border-right: 1px solid #DADADA;
}

.user_details:before {
    display: none;
}

.header_serperator {
    width: 100%;
    max-width: 100%;
}


.customer_notificaions {
    position: absolute;
    width: 384px;
    z-index: 1500;
    margin-top: 30px;
}

.customer_activity_main_div {
    margin-top: -7px;
}

.chargezen .main_header .second_div .notification .Polaris-Button {
    border: none;
    background: transparent;
    box-shadow: none;
}

.tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button__Text {
    font-size: 18px;
    font-weight: 500;
    color: #A9ABB0;
    margin: 0;
}

.tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
    border: 0;
    outline: none;
    box-shadow: none;
}

.tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary {
    background: #F5F7FB;
}

.tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary .Polaris-Button__Text {
    font-weight: 800;
    color: #017EFA;
}

.tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Content {
    margin: 0;
    text-align: center;
}

.navBar .sidBartabs .Polaris-Navigation__Section {
    margin-bottom: 20px;
}


#PolarisSecondaryNavigation21 {
    margin-bottom: 60px;
}

.Polaris-Navigation__Icon:first-child {
    padding-top: 0 !important;
}


.navBar {
    padding-top: 23px;
    max-width: 18%;
    background: #001219 !important;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section {
    padding-top: 25px;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li {
    margin: 0;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link {
    padding: 0 30px !important;
    font-family: 'Avenir';
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link span.Polaris-Navigation__Text {
    margin: 0 0 7px;
    padding-top: 0;
    color: #1BC1E0 !important;
}

.app_content {
    width: 100%;
    max-width: 82% !important;
    left: 0 !important;
    margin-left: auto;
}

.module-navbar.dashboardArea.collapsedNav .app_content {
    max-width: 93% !important;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation {
    margin: 0;
    padding-left: 5%;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link {
    font-family: 'Avenir';
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link.Polaris-Navigation__Item--selected.Polaris-Navigation--subNavigationActive {
    background: #fafafa !important;
    padding: 14px 16px !important;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link span.Polaris-Icon {
    margin: 0;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link .Polaris-Navigation__Icon {
    margin: 0;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link {
    padding: 8.5px 16px !important;
    transition: all 0.2s ease;
    border-radius: 5px !important;
    display: flex;
    align-items: center;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link span.Polaris-Navigation__Text {
    margin: 0 0 0 8px;
    padding: 0px 0 0;
    font-weight: 500 !important;
    font-family: Avenir;
    font-size: 18px !important;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link.Polaris-Navigation__Item--selected.Polaris-Navigation--subNavigationActive span.Polaris-Navigation__Text {
    color: #fff !important;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li:not(:last-child) {
    margin: 0 0 20px;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>div>div>ul>li.Polaris-Navigation__ListItem {
    margin: 0 0 1px;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link:hover {
    background: #1B3A58 !important;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link:hover {
    background: transparent !important;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link.Polaris-Navigation--subNavigationActive {
    background: #fafafa !important;
    padding: 14px 16px !important;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation ul.Polaris-Navigation__Section>li>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>button.Polaris-Link.Polaris-Navigation--subNavigationActive span.Polaris-Navigation__Text {
    color: #fff !important;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a span.Polaris-Navigation__Text {
    font-size: 16px !important;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>.Polaris-Navigation__ItemWrapper>button.Polaris-Link .Polaris-Navigation__Icon {
    padding: 0;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div.Polaris-Collapsible {
    margin: 0 !important;
}

.Polaris-Navigation__PrimaryNavigation {
    max-height: calc(100vh - 94px) !important;
}