span.left_section.lancher_settings_toggle_header_buttons {
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
}

span.left_section.lancher_settings_toggle_header_buttons button.toggle_header_button {
    background: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100%;
    align-items: center;
    border: 0;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
}

span.left_section.lancher_settings_toggle_header_buttons button.toggle_header_button.active {
    border-bottom: 2px solid #2d7cf6;
    color: #2d7cf6;

}

.color_picker_container_button {
    width: 40px !important;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.chargezen_color_picker_wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    position: relative;
}

.custom_color_picker_form {
    display: flex;
    position: absolute;
    left: 0;
    top: 36px;
    width: 100%;
    max-width: 200px;
    min-width: 200px;
    background-color: #fff;
    background: #fff !important;
    padding: 5px;
    border: 1px solid #ddd;
    z-index: 999999;
    flex-flow: column;
    gap: 12px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.custom_color_picker_form .color_picker_component {
    background: #fff !important;
}

.Polaris-ColorPicker__Dragger {
    width: 20px !important;
}

.chargezen_image_dropzone {
    border: 1px solid #ddd;
    width: 100%;
    height: 222px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f9f7f3;
    margin-top: 8px;
    margin-bottom: 17px;
}

.chargezen_image_dropzone label.select_upload_file_label input[type="file"] {
    display: none;
}

.chargezen_image_dropzone label.select_upload_file_label {
    background: #000;
    color: #fff;
    border-radius: 5px;
    min-width: 74px !important;
    width: 74px !important;
    display: block;
    text-align: center;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.image_preview_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
}

.image_preview_wrapper img.chargezen_dropzone_preview {
    max-height: 170px;
    width: auto;
}

.image_preview_wrapper .change_remove_button_wrapper {
    width: 100%;
    display: flex;
}

.image_preview_wrapper .change_remove_button_wrapper .chage_remove_button {
    width: 50%;
    background: #fff;
    border: 1px solid #C4CDD5;
    padding: 9px 0;
    text-align: center;
}

.image_preview_wrapper .preview_image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.chargezen_dropzone_helpText {
    line-height: 20px;
    color: #637381;
    font-size: 14px;
    margin-top: 5px;
}

li.opened_form_settng_list .opened_form_settng_wrapper {
    width: 100%;
}

li.opened_form_settng_list {
    padding: 0 !important;
}

.giftCard_settings_container .card_body_content {
    margin-bottom: 15px;
}

.giftCard_settings_container .card_body_content h3,
.giftCard_settings_container label {
    font-size: 14px;
    font-weight: 600 !important;
}



.content-section>.left_sidebarr .dragAndDropContentBlockContainer .drag_and_drop .drag_and_drop_list,
.content-section>.left_sidebarr ul.polaris-card.content_block_lists>li {
    border: 0 !important;
    padding: 10px !important;
    background: #fff;
    margin: 0 !important;
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 60px;
}

.content-section>.left_sidebarr .dragAndDropContentBlockContainer ul.polaris-card.content_block_lists {
    margin: 0;
    padding: 0;
    list-style: none;
}

.content-section>.left_sidebarr .dragAndDropContentBlockContainer .drag_and_drop .drag_and_drop_list {
    position: relative;
    border-bottom: 1px solid #dfe3e8 !important;
    border-right: 5px solid #fff !important;
}

.content-section>.left_sidebarr .dragAndDropContentBlockContainer .drag_and_drop>div {
    width: 100% !important;
}

.content-section>.left_sidebarr .dragAndDropContentBlockContainer .drag_and_drop .drag_and_drop_list:hover {
    border-right-color: #6268c1 !important;
}

.content-section>.left_sidebarr .dragAndDropContentBlockContainer .drag_and_drop .drag_and_drop_list .move_icon_wrapper {
    position: absolute;
    right: 5px;
}

.content-section>.left_sidebarr .dragAndDropContentBlockContainer .drag_and_drop .drag_and_drop_list .move_icon_wrapper svg {
    width: 15px;
}

li.gift_card_content_block.add_element_list {
    position: relative;
}

li.gift_card_content_block.add_element_list button.add_content_block {
    position: absolute;
    right: 24px;
    background: #000;
    border-color: #000;
    color: #fff;
    fill: #000;
    padding: 7px 12px 4px;
    border-radius: 3px;
    border: 0;
    font-family: 'Avenir';
    font-weight: 500;
    display: flex;
    align-items: center;
}

/* .chargezen_full_width_page .Polaris-Card__Section {
    padding: 0 !important;
} */

.chargezen_full_width_page .Polaris-Page {
    padding: 0 !important;
}

.chargezen_full_width_page .Polaris-Page .back-button {
    padding-left: 20px !important;
}

.remove_content_block_button_wrapper button.remove_content_block {
    fill: #637381;
    background: linear-gradient(180deg, #fff, #f9fafb);
    border-radius: 3px;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, .05);
    color: #212b36;
    cursor: pointer;
    display: flex;
    justify-content: center;
    line-height: 1;
    margin: 0;
    gap: 5px;
    max-height: 30px;
    min-width: 100%;
    padding: 0.7rem 1.6rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.64, 0, .35, 1);
    user-select: none;
    align-items: center;
    border: 1px solid #cdc8c8;
}

.remove_content_block_button_wrapper button.remove_content_block svg {
    width: 15px;
}

.remove_content_block_button_wrapper {
    display: flex;
    justify-content: center;
    padding: 16px 20px 36px;
    position: relative;
}

.remove_checkbox_help_text {
    position: absolute;
    bottom: 0;
}

.gift_card_main_container>div>.Polaris-Page__Content {
    margin-top: 0;
}


.gift_card_main_container>div>.Polaris-Page__Content .bundle_with_page_wrapper .Polaris-Frame__Content>.Polaris-Card {
    border-radius: 0 !important;
}

.gift_card_main_container>div>.Polaris-Page__Content .bundle_with_page_wrapper .Polaris-Frame__Content>.Polaris-Card>.Polaris-Card__Header {
    display: table-column;
}

.gift_card_main_container>div>.Polaris-Page__Content .bundle_with_page_wrapper .Polaris-Frame__Content>.Polaris-Card>.Polaris-Card__Section {
    padding: 20px 20px 82px;
}


.gift_card_main_container>div>.Polaris-Page__Content .bundle_with_page_wrapper .Polaris-Frame__Content>.Polaris-Card>.Polaris-Card__Section>.rewards_launcher_settings_wrapper>div>span.left_section {
    max-width: 30%;
}

.gift_card_main_container>div>.Polaris-Page__Content .bundle_with_page_wrapper .Polaris-Frame__Content>.Polaris-Card>.Polaris-Card__Section>.rewards_launcher_settings_wrapper>div>.left_sidebarr {
    max-width: 30%;
}

.gift_card_main_container>div>.Polaris-Page__Content .bundle_with_page_wrapper .Polaris-Frame__Content>.Polaris-Card>.Polaris-Card__Section>.rewards_launcher_settings_wrapper>div>.right-section {
    max-width: 70%;
}

.gift_card_main_container>div>.Polaris-Page__Content .bundle_with_page_wrapper .Polaris-Frame__Content>.Polaris-Card>.Polaris-Card__Section>.rewards_launcher_settings_wrapper>div>.heder-right-section {
    max-width: 70%;
}

.rewards_template_wrapper.zenn_portal_wrapper.gift_card_main_container>.Polaris-Page>.Polaris-Page-Header {
    background: #fff;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid rgba(218, 218, 218, 0.50);
    padding: 15px 15px 31px;
}

.rewards_template_wrapper.zenn_portal_wrapper.gift_card_main_container>.Polaris-Page>.Polaris-Page-Header .flex_header {
    width: 100%;
    display: flex;
    align-items: center;
}

.rewards_template_wrapper.zenn_portal_wrapper.gift_card_main_container>.Polaris-Page>.Polaris-Page-Header .flex_header button.save-button.gift_card_save_button {
    border-radius: 3px;
    border: 1px solid #C4CDD5;
    background: linear-gradient(180deg, #6371C7 0%, #5563C1 100%);
    box-shadow: 0px 1px 0px 0px rgba(22, 29, 37, 0.05);
    min-width: 50px;
    color: #FFF;
    text-align: center;
    font-family: Avenir;
    font-size: 13.3px;
    font-style: normal;
    font-weight: 350;
    line-height: 13.33px;
    min-height: 28.5px;
}

.rewards_template_wrapper.zenn_portal_wrapper.gift_card_main_container>.Polaris-Page>.Polaris-Page-Header .flex_header nav {
    margin-right: 8px;
}

.rewards_template_wrapper.zenn_portal_wrapper.gift_card_main_container>.Polaris-Page>.Polaris-Page-Header .flex_header h1.Polaris-Header-Title {
    color: #1C1F37;
    font-family: 'Avenir-Medium';
    font-size: 20.453px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    position: relative;
    top: 1px;
}