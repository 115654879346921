.integration_main .Polaris-Layout__Section {
    padding: 0px;
}

.integration_main .Polaris-Layout {
    padding: 0px;
}

.integration_main .back-button.pointer {
    border: 1px solid #babfc3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    margin-left: -16px;
}