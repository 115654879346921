.chargezen_customer_detail_wrapper .App {
    text-align: center;
}


.chargezen_customer_detail_wrapper .App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {

    .chargezen_customer_detail_wrapper .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.chargezen_customer_detail_wrapper svg {
    width: 20px;
    height: 20px;
}

.chargezen_customer_detail_wrapper .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}


.chargezen_customer_detail_wrapper .App-link {
    color: #61dafb;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

*,
::after,
::before {
    box-sizing: border-box;
}

.chargezen_customer_detail_wrapper img {
    width: 100%;
}


:root {
    --primaryColor: #017efa;
    --buttonColroLight: #017efa;
}


.chargezen_customer_detail_wrapper .mainContent {
    padding: 0;
}

.chargezen_customer_detail_wrapper .Polaris-Page {
    margin: 0 !important;
    padding: 0 !important;
}

.chargezen_customer_detail_wrapper {
    padding: 0 4%;
}

.chargezen_customer_detail_wrapper .mainContent .back-btn-main {
    width: 100%;
    max-width: 50px;
    height: 50px;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chargezen_customer_detail_wrapper .mainContent .back-btn-main svg {
    width: 30px;
    height: 30px;
}

.chargezen_customer_detail_wrapper .mainContent .text-button {
    display: flex;
    align-items: center;
    gap: 12px;
}

.chargezen_customer_detail_wrapper .mainContent .text-button button {
    padding: 15px;
    border-radius: 30px;
    border: 0;
    font-size: 15px;
    font-weight: 500;
    background: var(--buttonColroLight);
    color: #fff;
}


.chargezen_customer_detail_wrapper .cardBox img {
    width: 20px;
    height: 20px;
}

.chargezen_customer_detail_wrapper .block_section {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
}

.chargezen_customer_detail_wrapper .block_section .cardBox {
    background: #fff;
    box-shadow: 0 0 0 #000, 0 0 5px #cdcdcd94;
    margin-bottom: 22px;
    padding-bottom: 15px;
}



.chargezen_customer_detail_wrapper .block_section .detail-customer-add-product .list-section-main ul {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 14px;
    padding: 0;
}

.chargezen_customer_detail_wrapper .block_section .detail-customer-add-product .list-section-main ul li.locationName {
    font-size: 25px;
    font-weight: 500;
}

.chargezen_customer_detail_wrapper .block_section .detail-customer-add-product .list-section-main ul li:not(:first-child, :last-child) {
    font-size: 14px;
    color: gray;
}

.chargezen_customer_detail_wrapper .block_section .detail-customer-add-product .list-section-main ul li:last-child {
    color: var(--primaryColor);
    margin: 0 0 0 auto;
}

.chargezen_customer_detail_wrapper .block_section .detail-customer-add-product .list-section-main ul li:last-child svg {
    fill: var(--primaryColor);
}

.chargezen_customer_detail_wrapper .block_section .detail-customer-add-product .list-section-main ul {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 14px;
    padding: 0;
    background: #f2f2f2;
    padding: 15px;
    margin: 0;
}

.chargezen_customer_detail_wrapper .payment-section {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 15px 15px 40px;
}


.chargezen_customer_detail_wrapper .commanButton {
    display: flex;
    padding: 3px 8px;
    border: 1px solid var(--buttonColroLight);
    font-size: 13px;
    align-items: center;
    gap: 6px;
    color: var(--buttonColroLight);
    background: #fff;
    border-radius: 5px;
    height: 30px;
}


.chargezen_customer_detail_wrapper .commanButton svg {
    fill: var(--buttonColroLight);
}


.chargezen_customer_detail_wrapper .bottom-section-main table tbody tr td img {
    max-width: 100px;
}

.chargezen_customer_detail_wrapper .bottom-section-main table {
    width: 100%;
    border-collapse: collapse;
}

.chargezen_customer_detail_wrapper .bottom-section-main table tbody tr td:not(:first-child),
.bottom-section-main table thead tr th:not(:first-child) {
    text-align: left;
}

.chargezen_customer_detail_wrapper table thead tr th,
table tbody tr td {
    padding: 15px 5px;
}

.chargezen_customer_detail_wrapper .bottom-section-main table thead tr th {
    border-bottom: 1px solid #e6e2e2;
}

.chargezen_customer_detail_wrapper .product_ {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 8px;
}

.chargezen_customer_detail_wrapper .bottom-section-main table tbody tr {
    border-bottom: 1px solid #e6e2e2;
}


.chargezen_customer_detail_wrapper .add-product-button {
    padding: 15px;
}

.chargezen_customer_detail_wrapper .add-product-button button.commanButton {
    margin: 0 0 0 auto;
    font-size: 14px;
    font-weight: 800;
}

.chargezen_customer_detail_wrapper .addAddress {
    padding: 0 15px;
}


.chargezen_customer_detail_wrapper .commanBoxS .heading_ h2 {
    margin: 0;
    text-align: left;
    font-size: 20px;
    color: var(--primaryColor);
    opacity: 0.5;
    font-weight: 400;
}

.chargezen_customer_detail_wrapper .commanBoxS .heading_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chargezen_customer_detail_wrapper .commanBoxS {
    text-align: left;
    padding: 15px;
}

.chargezen_customer_detail_wrapper .commanBoxS p {
    color: #2c2929;
    /* opacity: 0.7; */
    font-size: 15px;
}


.chargezen_customer_detail_wrapper .commanBoxS span {
    display: flex;
    line-height: 30px;
    color: var(--primaryColor);
    font-weight: 600;
    align-items: center;
    line-height: 40px;
    gap: 5px;
}


.chargezen_customer_detail_wrapper .payMShow {
    display: flex;
    align-items: center;
    gap: 20px;
}

.chargezen_customer_detail_wrapper .payMShow .iconPay {
    flex: 0 0 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chargezen_customer_detail_wrapper .payMShow p {
    margin: 0;
    line-height: 30px;
}

.chargezen_customer_detail_wrapper .payMShow a {
    color: gray;
    font-size: 14px;
    text-decoration: none;
}

.chargezen_customer_detail_wrapper .accordian span.cardBox {
    display: flex;
    padding: 15px;
    text-align: left;
    align-items: center;
}

.chargezen_customer_detail_wrapper .accordian span.cardBox h4 {
    margin: 0;
    font-size: 25px;
    font-weight: 500;
    padding-left: 15px;
}

.chargezen_customer_detail_wrapper .payMShow .iconPay img {
    width: 100%;
    height: auto;
    max-width: 40px;
}

.chargezen_customer_detail_wrapper .payMShow .iconPay icon:first-child img {
    max-width: 20px;
}

.chargezen_customer_detail_wrapper .payMShow .iconPay icon:last-child {
    height: 14px;
}

.chargezen_customer_detail_wrapper .block_section .accordian {
    margin-top: 15px;
}

.chargezen_customer_detail_wrapper .actionB {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
    cursor: pointer;
}

li.actionB.sub_tab_wrapper {
    display: flex;
    gap: 20px;
}

li.actionB.sub_tab_wrapper .sub_tab {
    cursor: pointer;
}

.chargezen_customer_detail_wrapper .bottom-section-main>table>thead>tr>th:first-child {
    text-align: left;

}

.chargezen_customer_detail_wrapper .bottom-section-main>table>tbody>tr>td,
.bottom-section-main>table>thead>tr>th {
    padding-left: 15px;
    padding-right: 15px;
}


.chargezen_customer_detail_wrapper .accordian span.cardBox.active svg {
    transform: rotate(90deg);
}

.chargezen_customer_detail_wrapper .accordian span.cardBox.active {
    color: inherit !important;
}

.chargezen_customer_detail_wrapper .accordian .accordion_wrapper {
    margin-top: -13px;
    background: #f8f8f8;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-top: 0;
}

.chargezen_customer_detail_wrapper .accordian .accordion_wrapper>table {
    width: 100%;
}

.chargezen_customer_detail_wrapper .accordian .accordion_wrapper>table>thead>tr>th {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

.chargezen_customer_detail_wrapper .accordian .accordion_wrapper>table>tbody>tr>td {
    padding-left: 20px;
    padding-right: 20px;
}

.chargezen_customer_detail_wrapper .accordian .accordion_wrapper.hidden {
    display: block !important;
    height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in;
    transform: scaleY(0);
    transform-origin: top;
}

.chargezen_customer_detail_wrapper .accordian .accordion_wrapper {
    transform-origin: top;
    transition: all 0.2s ease-in;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content h4 {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Avenir';
    margin-bottom: 20px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .points_adjustment_form {
    display: flex;
    flex-flow: column;
    gap: 15px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .new_balance_wrapper {
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content .new_balance_wrapper span {
    font-weight: 600;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content h4 p {
    color: #637381;
}

.discount_code_copy_wrapper {
    display: flex;
    justify-content: space-between;
}

.discount_code_copy_wrapper input {
    width: 100%;
}

.discount_code_copy_wrapper div:first-child {
    width: 70%;
}

.discount_code_copy_wrapper div:first-child .Polaris-Connected {
    width: 100%;
}

.discount_code_copy_wrapper div:first-child .Polaris-Connected .Polaris-TextField.Polaris-TextField--hasValue.Polaris-TextField--disabled {
    width: 100%;
}

.discount_code_copy_wrapper button.copy_code_button {
    border: 1px solid #988d8d;
    background: transparent;
    padding: 0px 14px;
}

/* Height fix customer modal css */
.detail-customer-overview-main>.Polaris-Card {
    min-height: 449px;
}

.detail-customer-overview-main .detail-customer-add-product.cardBox {
    margin-bottom: 0;
}

.detail-customer-overview-main .detail-customer-add-product.cardBox .add-product-button {
    padding-bottom: 0;
}

.button-accordian-payment-main .customer-overview {
    min-height: 202px;
}

/* Height fix customer modal css */

/* css for payment secton design */
.button-accordian-payment-main>.payment-methods.cardBox.commanBoxS .visa-section img {
    width: 100%;
    max-width: 70px;
    height: 40px;
    -o-object-fit: cover;
    object-fit: cover;
}

.button-accordian-payment-main>.payment-methods.cardBox.commanBoxS .visa-section .visa-text p {
    margin: 10px 0 0;
    font-size: 1em;
    font-weight: 400;
    text-transform: capitalize;
}

.button-accordian-payment-main>.payment-methods.cardBox.commanBoxS .visa-section .visa-text p span {
    display: inline-block;
    line-height: 150%;
    color: #212b36;
}

.button-accordian-payment-main>.payment-methods.cardBox.commanBoxS .visa-section .visa-text p span b {
    margin-left: 5px;
    font-weight: 600;
}

.button-accordian-payment-main>.payment-methods.cardBox.commanBoxS .visa-section {
    margin: 0 0 0;
}

.chargezen_no_content_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 306px;
    flex-flow: column;
}

.no-height {
    height: 0;
    display: none !important;
}

.chargezen_no_content_wrapper h2 {
    font-family: 'Avenir';
    font-size: 25px;
    font-weight: 700;
    color: #017efa;
}

.chargezen_no_content_wrapper p {
    margin-top: 10px;
    max-width: 500px;
    width: 100%;
    text-align: center;
}

.customer_modal_show_more_line_items {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #000;
    text-align: center;
    color: #fff;
    opacity: 0.6;
    cursor: pointer;
}

/* css for payment secton design */

@media(max-width:1535px) {


    .detail-customer-overview-main .Polaris-Card>.Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item.Polaris-Stack__Item--fill {
        max-width: 250px;
    }

    .detail-customer-overview-main .Polaris-Card>.Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child {
        max-width: calc(100% - 250px);
        flex: calc(100% - 250px);
    }

    .detail-customer-overview-main .Polaris-Card>.Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item.Polaris-Stack__Item--fill .heading_title {
        font-size: 20px;
    }

    .detail-customer-overview-main .Polaris-Card>.Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item.Polaris-Stack__Item--fill .heading_title span {
        margin-right: 5px;
    }


}

@media(max-width:1439px) {


    .detail-customer-overview-main .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
        font-size: 15px;
    }

    .detail-customer-overview-main .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary .Polaris-Button__Text {
        font-size: 12.5px;
    }
}

@media(max-width:1365px) {

    .detail-customer-overview-main .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary {
        padding: 5px !important;
    }

    .detail-customer-overview-main .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
        padding: 5px !important;
    }

}

@media(max-width:1279px) {

    .detail-customer-overview-main .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item {
        padding-right: 0;
    }



    .detail-customer-overview-main .Polaris-Card>.Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item.Polaris-Stack__Item--fill:first-child {
        max-width: 200px;
        flex: 200px;
    }

    .detail-customer-overview-main .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item {
        max-width: calc(100% - 200px);
        flex: calc(100% - 200px);
        padding-right: 0 !important;
        width: 100% !important;
        min-width: calc(100% - 200px);
    }

    .detail-customer-overview-main .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary .Polaris-Button__Text,
    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
        font-size: 12.5px;
    }

    .detail-customer-overview-main .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .heading_title {
        font-size: 15px !important;
    }


}
