.pick_product_main_container {
    margin: 0 7% !important;
}

.text-center.pick_product_header {
    text-align: center;
}

.text-center.pick_product_header h1 {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    font-family: Avenir;
    color: #111;
    text-align: center;
    font-family: Avenir;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px;
    margin-bottom: 18px;
}

.text-center.pick_product_header h5 {
    color: #111;
    text-align: center;
    font-family: Avenir;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.pick_product_container {
    margin-top: 20px;
    padding: 12px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 40px;
}

.pick_product_container .card_box {
    display: flex;
    width: 100%;
    max-height: 283.15px;
    height: 100%;
    padding: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 6px;
    border: 1px solid #DADBD2;
    background: #FFF;
}

.pick_product_container .card_box .iconWrapper {
    margin: 0;
    display: flex;
    width: 40px;
    height: 42.41px;
    padding: 0 !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #F3F4EF;
}

.pick_product_container .card_box .iconWrapper svg {
    width: 28px;
    height: 28px;
}



.pick_product_container .card_box h6 {
    color: #111;
    font-family: Avenir;
    font-size: 15.631px;
    font-style: normal;
    font-weight: 800;
    line-height: 25.74px;
}

.pick_product_container .card_box p {
    color: #111;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: -11px;
}

.pick_product_container .card_box button.get_started {
    border-radius: 6px;
    border: 1px solid #2D7CF6;
    background: #FFF;
    box-shadow: 0px 3px 0px -1px #2D7CF6;
    width: 99.09px;
    height: 34px;
    flex-shrink: 0;
}

.pick_product_container .card_box button.get_started:hover {
    background: #2D7CF6;
    color: #fff;
}

.pick_product_container .card_box:hover {
    scale: 1.08;
    box-shadow: 0px 3px 0px 0px #DADBD2;
}
