@font-face {
    font-family: 'Avenir-Black';
    src: url('Avenir-Black.eot');
    src: url('Avenir-Black.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Black.woff2') format('woff2'),
        url('Avenir-Black.woff') format('woff'),
        url('Avenir-Black.ttf') format('truetype'),
        url('Avenir-Black.svg#Avenir-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir-Book';
    src: url('Avenir-Book.eot');
    src: url('Avenir-Book.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Book.woff2') format('woff2'),
        url('Avenir-Book.woff') format('woff'),
        url('Avenir-Book.ttf') format('truetype'),
        url('Avenir-Book.svg#Avenir-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirLT-Black';
    src: url('AvenirLT-Black.eot');
    src: url('AvenirLT-Black.eot?#iefix') format('embedded-opentype'),
        url('AvenirLT-Black.woff2') format('woff2'),
        url('AvenirLT-Black.woff') format('woff'),
        url('AvenirLT-Black.ttf') format('truetype'),
        url('AvenirLT-Black.svg#AvenirLT-Black') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirLTStd-Black';
    src: url('AvenirLTStd-Black.eot');
    src: url('AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
        url('AvenirLTStd-Black.woff2') format('woff2'),
        url('AvenirLTStd-Black.woff') format('woff'),
        url('AvenirLTStd-Black.ttf') format('truetype'),
        url('AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir-Heavy';
    src: url('Avenir-Heavy.eot');
    src: url('Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Heavy.woff2') format('woff2'),
        url('Avenir-Heavy.woff') format('woff'),
        url('Avenir-Heavy.ttf') format('truetype'),
        url('Avenir-Heavy.svg#Avenir-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir-BlackOblique';
    src: url('Avenir-BlackOblique.eot');
    src: url('Avenir-BlackOblique.eot?#iefix') format('embedded-opentype'),
        url('Avenir-BlackOblique.woff2') format('woff2'),
        url('Avenir-BlackOblique.woff') format('woff'),
        url('Avenir-BlackOblique.ttf') format('truetype'),
        url('Avenir-BlackOblique.svg#Avenir-BlackOblique') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-BoldCn';
    src: url('AvenirNextLTPro-BoldCn.eot');
    src: url('AvenirNextLTPro-BoldCn.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-BoldCn.woff2') format('woff2'),
        url('AvenirNextLTPro-BoldCn.woff') format('woff'),
        url('AvenirNextLTPro-BoldCn.ttf') format('truetype'),
        url('AvenirNextLTPro-BoldCn.svg#AvenirNextLTPro-BoldCn') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-BoldCnIt';
    src: url('AvenirNextLTPro-BoldCnIt.eot');
    src: url('AvenirNextLTPro-BoldCnIt.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-BoldCnIt.woff2') format('woff2'),
        url('AvenirNextLTPro-BoldCnIt.woff') format('woff'),
        url('AvenirNextLTPro-BoldCnIt.ttf') format('truetype'),
        url('AvenirNextLTPro-BoldCnIt.svg#AvenirNextLTPro-BoldCnIt') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirLTStd-Book';
    src: url('AvenirLTStd-Book.eot');
    src: url('AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
        url('AvenirLTStd-Book.woff2') format('woff2'),
        url('AvenirLTStd-Book.woff') format('woff'),
        url('AvenirLTStd-Book.ttf') format('truetype'),
        url('AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-Cn';
    src: url('AvenirNextLTPro-Cn.eot');
    src: url('AvenirNextLTPro-Cn.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-Cn.woff2') format('woff2'),
        url('AvenirNextLTPro-Cn.woff') format('woff'),
        url('AvenirNextLTPro-Cn.ttf') format('truetype'),
        url('AvenirNextLTPro-Cn.svg#AvenirNextLTPro-Cn') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-Bold';
    src: url('AvenirNextLTPro-Bold.eot');
    src: url('AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('AvenirNextLTPro-Bold.woff') format('woff'),
        url('AvenirNextLTPro-Bold.ttf') format('truetype'),
        url('AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-CnIt';
    src: url('AvenirNextLTPro-CnIt.eot');
    src: url('AvenirNextLTPro-CnIt.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-CnIt.woff2') format('woff2'),
        url('AvenirNextLTPro-CnIt.woff') format('woff'),
        url('AvenirNextLTPro-CnIt.ttf') format('truetype'),
        url('AvenirNextLTPro-CnIt.svg#AvenirNextLTPro-CnIt') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir-Medium';
    src: url('Avenir-Medium.eot');
    src: url('Avenir-Medium.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Medium.woff2') format('woff2'),
        url('Avenir-Medium.woff') format('woff'),
        url('Avenir-Medium.ttf') format('truetype'),
        url('Avenir-Medium.svg#Avenir-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-HeavyCnIt';
    src: url('AvenirNextLTPro-HeavyCnIt.eot');
    src: url('AvenirNextLTPro-HeavyCnIt.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-HeavyCnIt.woff2') format('woff2'),
        url('AvenirNextLTPro-HeavyCnIt.woff') format('woff'),
        url('AvenirNextLTPro-HeavyCnIt.ttf') format('truetype'),
        url('AvenirNextLTPro-HeavyCnIt.svg#AvenirNextLTPro-HeavyCnIt') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-HeavyCn';
    src: url('AvenirNextLTPro-HeavyCn.eot');
    src: url('AvenirNextLTPro-HeavyCn.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-HeavyCn.woff2') format('woff2'),
        url('AvenirNextLTPro-HeavyCn.woff') format('woff'),
        url('AvenirNextLTPro-HeavyCn.ttf') format('truetype'),
        url('AvenirNextLTPro-HeavyCn.svg#AvenirNextLTPro-HeavyCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-DemiCnIt';
    src: url('AvenirNextLTPro-DemiCnIt.eot');
    src: url('AvenirNextLTPro-DemiCnIt.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-DemiCnIt.woff2') format('woff2'),
        url('AvenirNextLTPro-DemiCnIt.woff') format('woff'),
        url('AvenirNextLTPro-DemiCnIt.ttf') format('truetype'),
        url('AvenirNextLTPro-DemiCnIt.svg#AvenirNextLTPro-DemiCnIt') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-It';
    src: url('AvenirNextLTPro-It.eot');
    src: url('AvenirNextLTPro-It.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-It.woff2') format('woff2'),
        url('AvenirNextLTPro-It.woff') format('woff'),
        url('AvenirNextLTPro-It.ttf') format('truetype'),
        url('AvenirNextLTPro-It.svg#AvenirNextLTPro-It') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-Demi';
    src: url('AvenirNextLTPro-Demi.eot');
    src: url('AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-Demi.woff2') format('woff2'),
        url('AvenirNextLTPro-Demi.woff') format('woff'),
        url('AvenirNextLTPro-Demi.ttf') format('truetype'),
        url('AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-DemiCn';
    src: url('AvenirNextLTPro-DemiCn.eot');
    src: url('AvenirNextLTPro-DemiCn.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-DemiCn.woff2') format('woff2'),
        url('AvenirNextLTPro-DemiCn.woff') format('woff'),
        url('AvenirNextLTPro-DemiCn.ttf') format('truetype'),
        url('AvenirNextLTPro-DemiCn.svg#AvenirNextLTPro-DemiCn') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-MediumCn';
    src: url('AvenirNextLTPro-MediumCn.eot');
    src: url('AvenirNextLTPro-MediumCn.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-MediumCn.woff2') format('woff2'),
        url('AvenirNextLTPro-MediumCn.woff') format('woff'),
        url('AvenirNextLTPro-MediumCn.ttf') format('truetype'),
        url('AvenirNextLTPro-MediumCn.svg#AvenirNextLTPro-MediumCn') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-DemiIt';
    src: url('AvenirNextLTPro-DemiIt.eot');
    src: url('AvenirNextLTPro-DemiIt.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-DemiIt.woff2') format('woff2'),
        url('AvenirNextLTPro-DemiIt.woff') format('woff'),
        url('AvenirNextLTPro-DemiIt.ttf') format('truetype'),
        url('AvenirNextLTPro-DemiIt.svg#AvenirNextLTPro-DemiIt') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-Regular';
    src: url('AvenirNextLTPro-Regular.eot');
    src: url('AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('AvenirNextLTPro-Regular.woff') format('woff'),
        url('AvenirNextLTPro-Regular.ttf') format('truetype'),
        url('AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-MediumCnIt';
    src: url('AvenirNextLTPro-MediumCnIt.eot');
    src: url('AvenirNextLTPro-MediumCnIt.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-MediumCnIt.woff2') format('woff2'),
        url('AvenirNextLTPro-MediumCnIt.woff') format('woff'),
        url('AvenirNextLTPro-MediumCnIt.ttf') format('truetype'),
        url('AvenirNextLTPro-MediumCnIt.svg#AvenirNextLTPro-MediumCnIt') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir-Roman';
    src: url('Avenir-Roman.eot');
    src: url('Avenir-Roman.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Roman.woff2') format('woff2'),
        url('Avenir-Roman.woff') format('woff'),
        url('Avenir-Roman.ttf') format('truetype'),
        url('Avenir-Roman.svg#Avenir-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-UltLtCnIt';
    src: url('AvenirNextLTPro-UltLtCnIt.eot');
    src: url('AvenirNextLTPro-UltLtCnIt.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-UltLtCnIt.woff2') format('woff2'),
        url('AvenirNextLTPro-UltLtCnIt.woff') format('woff'),
        url('AvenirNextLTPro-UltLtCnIt.ttf') format('truetype'),
        url('AvenirNextLTPro-UltLtCnIt.svg#AvenirNextLTPro-UltLtCnIt') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirNextLTPro-UltLtCn';
    src: url('AvenirNextLTPro-UltLtCn.eot');
    src: url('AvenirNextLTPro-UltLtCn.eot?#iefix') format('embedded-opentype'),
        url('AvenirNextLTPro-UltLtCn.woff2') format('woff2'),
        url('AvenirNextLTPro-UltLtCn.woff') format('woff'),
        url('AvenirNextLTPro-UltLtCn.ttf') format('truetype'),
        url('AvenirNextLTPro-UltLtCn.svg#AvenirNextLTPro-UltLtCn') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir-Roman_1';
    src: url('Avenir-Roman_1.eot');
    src: url('Avenir-Roman_1.eot?#iefix') format('embedded-opentype'),
        url('Avenir-Roman_1.woff2') format('woff2'),
        url('Avenir-Roman_1.woff') format('woff'),
        url('Avenir-Roman_1.ttf') format('truetype'),
        url('Avenir-Roman_1.svg#Avenir-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvenirLTStd-Roman';
    src: url('AvenirLTStd-Roman.eot');
    src: url('AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
        url('AvenirLTStd-Roman.woff2') format('woff2'),
        url('AvenirLTStd-Roman.woff') format('woff'),
        url('AvenirLTStd-Roman.ttf') format('truetype'),
        url('AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

