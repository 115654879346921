.Polaris-Page__Content .Polaris-Card {
    overflow: visible !important;
}

.chargezen_extra_header_buttons_container .drop_down_wrapper_container {
    position: absolute;
    display: flex;
    left: 0;
    transform: translate(-50%);
    top: 35px;
    flex-flow: column;
    gap: 5px;
    z-index: 9999;
    padding: 0;
    margin: 0;
    background: #fff;
    padding: 8px 8px;
    border-radius: 12px;
    max-width: 160px !important;
    width: 100%;
    min-width: 154px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.chargezen_extra_header_buttons_container .more_icon_wrapper {
    background: transparent;
    color: #f5f7fb;
    border: none;
    margin: 0;
    font-size: 15px;
    font-weight: 800;
    padding: 4px;
    border-radius: 6px;
    text-align: center;
}

.chargezen_extra_header_buttons_container .more_icon_wrapper svg {
    fill: #8a8a8a;
}

.chargezen_extra_header_buttons_container {
    position: relative;
    overflow: visible;
}

.chargezen_extra_header_buttons_container .drop_down_wrapper_container button.Polaris-Button {
    background: transparent !important;

    padding: 10px 8px !important;
}

.chargezen_extra_header_buttons_container .drop_down_wrapper_container button.Polaris-Button .Polaris-Button__Content .Polaris-Button__Text {
    color: #000 !important;
    font-size: 14px !important;
    text-align: left;
}

.chargezen_extra_header_buttons_container .drop_down_wrapper_container button.Polaris-Button.Polaris-Button--primary * {
    color: #017efa !important;
}

.chargezen_extra_header_buttons_container .drop_down_wrapper_container button.Polaris-Button:hover {
    background: #f5f7fb !important;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .chargezen_extra_header_buttons_container .drop_down_wrapper_container .Polaris-Button .Polaris-Button__Text {
    font-size: 13px !important;
    font-weight: 500;
    padding: 6px;
    display: flex;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .chargezen_extra_header_buttons_container .drop_down_wrapper_container .Polaris-Button:hover,
.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .chargezen_extra_header_buttons_container .drop_down_wrapper_container .Polaris-Button.Polaris-Button--primary {
    background: #f1f1f1 !important;
}

.chargezen_extra_header_buttons_container .more_icon_wrapper:hover {
    background: #ebeef4;
}

span.dismiss_wrapper_button {
    font-size: 13px !important;
    font-weight: 400 !important;
    display: flex;
    gap: 3px;
    align-items: center;
}

span.dismiss_wrapper_button svg {
    fill: #8a8a8a !important;
    height: 14px;
}