body,
html {
    height: 100%;
}

.yodaRevenue_main {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    padding: 40px 8px;
    position: relative;
}

.yodaRevenue_main_sub {
    max-height: 75vh;
    height: 100%;
    position: relative;
    overflow: auto;
}

.yodaRevenue_main .model_selector_sub {
    display: flex;
    justify-content: center;
}

.yodaRevenue_main button.model_selector_main {
    font-size: 14px;
    line-height: 20px;
    background: transparent;
    width: 100%;
    max-width: 398px;
    height: 58px;
    margin: 0 auto;
    text-align: left;
    padding: 8px 12px;
    outline: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
}

.yodaRevenue_main button.model_selector_main:focus {
    border: 1px solid rgba(16, 163, 127, 1);
}

.yodaRevenue_main button.model_selector_main span.model_name {
    display: block;
}

.yodaRevenue_main button.model_selector_main label.heading {
    margin-bottom: 0;
    color: rgb(64, 65, 79);
}

.yodaRevenue_main button.model_selector_main span.icon {
    position: absolute;
    right: 12px;
    top: 17px;
}

.yodaRevenue_main .footer_main .footer_heading {
    font-size: 14px;
    line-height: 20px;
    color: rgb(86, 88, 105);
    padding-bottom: 8px;
}

.message_sender_main .message_sender_sub textarea {
    border: 0;
    width: 100%;
    padding-right: 40px;
    resize: none;
}

.message_sender_main .message_sender_sub {
    position: relative;
    padding: 12px 16px;
    background: #fff !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    max-width: 750px;
}

.message_sender_main .message_sender_sub {
    position: relative;
}

.message_sender_main .message_sender_sub button.send_message_button {
    position: absolute;
    bottom: 0px;
    top: auto;
    right: 0px;
    border: 0;
    background: transparent;
    outline: 0;
    height: 100%;
    max-height: 50px;
    width: 40px;
}

.message_sender_main .message_sender_sub button.send_message_button svg {
    width: 16px;
    height: 16px;
}

.message_sender_main .message_sender_sub button.send_message_button:hover {
    background: rgb(236, 236, 241);
    border-radius: 5px;
}

.message_sender_main .message_sender_sub button.send_message_button:focus-visible,
.message_sender_main .message_sender_sub textarea:focus {
    border: 0;
    outline: 0;
}

.footer_links {
    text-align: center;
}

.footer_links span {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.5);
}

.footer_links span a.underline {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.5);
}

.footer_main {
    position: absolute;
    top: auto;
    left: 50%;
    transform: translate(-50%, -4%);
    bottom: 0;
    background: transparent;
    border-radius: 10px;
}

.model_options_main ul.modalOptions {
    position: absolute;
    top: 56px;
    list-style: none;
    width: 100%;
    padding-left: 0;
    left: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    z-index: 99;
    background: #fff;
}

.model_options_main ul.modalOptions li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
    color: rgb(52, 53, 65);
}

.model_options_main ul.modalOptions li.active {
    background: rgba(0, 0, 0, 0.1);
}

.model_options_main ul.modalOptions li:last-child {
    border-bottom: 0;
}

.model_options_main ul.modalOptions li:hover {
    background: rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
    .message_sender_main .message_sender_sub {
        max-width: 96%;
    }
}

body.chargezen {
    background: #f8f9ff;
}

.yodaRevenue_main.Yoda {
    background-color: #B0C4B1;
}

.yodaRevenue_main.Shuri {
    background-color: #D1BCE6;
}

.yodaRevenue_main.Hermione-Granger {
    background-color: #F3E2C7;
}

.yodaRevenue_main.Sherlock-Holmes {
    background-color: #C4CFD9;
}

.yodaRevenue_main.Aang {
    background-color: #B5DFF7;
}

.yodaRevenue_main .footer_main {
    box-shadow: 0px 0px 0px #888, 0px -10px 86px #fff;
}

.yodaRevenue_main .body_content {
    margin: auto;
    font-size: 16px;
    word-break: break-all;
    max-height: 100%;
}

.yodaRevenue_main .posted_message_sub {
    word-break: break-all;
}

.yodaRevenue_main .question_main_div {
    font-weight: 900;
    font-size: 18px;
    margin-top: 15px;
    padding: 12px 0px;
}

.yodaRevenue_main .question_main_div {
    font-size: 15px;
    margin-top: 15px;
    padding: 12px 0px;
    max-width: 1000px;
    margin: auto;
}

.yodaRevenue_main .question_sub_div {
    max-width: 70%;
    width: fit-content;
    border-radius: 15px;
    padding: 15px 15px;
    background: #76767666;
}

.yodaRevenue_main .answer_main_div {
    font-size: 15px;
    margin-top: 15px;
    padding: 12px 0px;
    max-width: 1000px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: end;
}

.yodaRevenue_main .answer_sub_div {
    max-width: 70%;
    border-radius: 15px;
    padding: 15px 15px;
    background: #59BCBC66;
    color: black;
}