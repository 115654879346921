.subscription_widget_style_tab .form-wrapper {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    background: #fff;
    padding: 20px;
    width: 100%;
    border-top: 2px solid #ddd;
}

.subscription_widget_style_tab .form-wrapper h1 {
    text-align: center;
    font-size: 20px;
    font-family: inherit;
}

.subscription_widget_style_tab .form-wrapper .field_wrapper_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subscription_widget_style_tab .form-wrapper .field_wrapper_container .field_wrapper {
    display: flex;
    gap: 10px;
}

.subscription_widget_style_tab .form-wrapper .field_wrapper_container h6 {
    width: 100%;
    max-width: 250px;
}

.subscription_widget_style_tab {
    display: flex;
    flex-flow: column;
}

.subscription_widget_style_tab .form-wrapper .collapsible_header {
    font-weight: 900;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subfields_wrapper_main {
    display: flex;
    flex-flow: column;
    gap: 15px;
}

.Polaris-Collapsible--expandOnPrint>div:first-child {
    padding: 20px;
    margin: 10px -20px 0px;
    cursor: pointer;
    border-top: 2px solid #ddd;
}

.subscription_widget_style_tab input[type="color"] {
    min-width: 60px;
}

.collapsible_header span.Polaris-Icon.Polaris-Icon--colorBase.Polaris-Icon--isColored {
    max-width: fit-content !important;
    margin: 0 !important;
}

.subscription_widget_style_tab .Polaris-Collapsible--expandOnPrint>div:first-child {
    padding-bottom: 0 !important;
}

.subscription_widget_style_tab .Polaris-Collapsible {
    opacity: 1 !important;
}

.subscription_widget_style_tab .field_wrapper_container .sub_fields_wrapper input {
    padding: 2px;
    text-align: center
}