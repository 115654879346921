.selected-plan-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    border: 1px solid #00000014;
    padding: 5px;
    border-radius: 8px;
}

.selected-plan-container>img {
    cursor: pointer;
}

.build-box-search {
    display: flex;
    align-items: center;
    border: 1px solid #00000014;
    padding: 5px;
    border-radius: 8px;
    max-width: 600px;
}

.build-box-search h3.Polaris-Subheading {
    white-space: nowrap;
}

.search {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
}

.build-box-search>* {
    margin-inline: 5px;
}

.date-range-label {
    margin-bottom: 4px;
}

.build-a-box-create .Polaris-Card__Header {
    background: #fff;
    border-radius: 5px 5px 0px 0px;
    color: #1c1f37;
    padding-bottom: 10px;
}

.limit-section .product-stack>div {
    margin-bottom: 26px;
    margin-left: 22px;
    margin-top: 10px;
}

.limit-section .building-box-collection>div {
    margin-bottom: 26px;
    margin-left: 22px;
    margin-top: 10px;
}


.head-search+div {
    width: 450px;
}

.without_header_card>.Polaris-Card>.Polaris-Card__Header>.Polaris-Stack>.Polaris-Stack__Item>.Polaris-ButtonGroup button.Polaris-Button.Polaris-Button--destructive {
    background: linear-gradient(180deg, #e6391a, #d53417) !important;
}

.polaris_header_wrapper .heading_subtitle {
    color: #6F6F6F;
    font-size: 13px;
    font-style: normal;
    font-weight: 350;
    line-height: 19.6px;
}

.Polaris-Card__Section {
    margin-top: 20px !important;
}

.card_header_buttons.primary_button_group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}