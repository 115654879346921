.commission_profile_container {}

.commission_profile_container h1 {}

.commission_profile_container>h1 {
    color: #6F6F6F;
    font-family: Avenir;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    margin: 0 0 15px;
}

.commission_profile_container .without_header_card>.Polaris-Card {
    background: transparent !important;
}

.commission_profile_container .without_header_card>.Polaris-Card>.Polaris-Card__Header {
    padding: 0;
}

.commission_profile_container .without_header_card>.Polaris-Card .Polaris-Card__Section {
    padding: 0;
}

.commission_profile_container .without_header_card>.Polaris-Card .Polaris-Card__Header {
    background: #fff;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid rgba(218, 218, 218, 0.50);
    padding: 17px 5px;
    margin: 0 0 10px;
}


.commission_profile_container .without_header_card>.Polaris-Card .Polaris-Card__Header .card_header_button_group .card_header_buttons .Polaris-ButtonGroup__Item button.Polaris-Button {
    padding: 5px 4.8px 5px 5px !important;
    color: #A9ABB0;
    text-align: center;
    font-family: 'Avenir';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.commission_profile_container .without_header_card>.Polaris-Card .Polaris-Card__Header .card_header_button_group .card_header_buttons>.Polaris-ButtonGroup {}

.commission_profile_container .without_header_card>.Polaris-Card .Polaris-Card__Header .card_header_button_group .card_header_buttons>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item:empty {
    display: none;
}

.card_header_buttons.secondary_button_group {}

.commission_profile_container .without_header_card>.Polaris-Card .Polaris-Card__Header .card_header_button_group .card_header_buttons button.Polaris-Button span {
    color: #017EFA !important;
    text-align: center;
    font-family: Avenir;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 900;
    line-height: normal !important;
}

.commission_profile_container .without_header_card .Polaris-Card__Section .contents_tab_wrapper {
    padding: 12px 13px 12px 12px;
    color: #000;
    font-family: Avenir;
    font-size: 12.797px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    background: #F7F7F7;
    border: 0;
}

.setup_guide_content_wrapper .contents_wrapper_container {
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
    padding: 20px 18px;
}

/* .progress_bar_in_header.polaris-no-padding-section {
    margin-top: 24px;
} */

.setup_guide_content_wrapper .contents_wrapper_container .affiliate_content_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .setup_guide_content_wrapper .contents_wrapper_container .affiliate_content_section>.right_section {
    padding: 0;
    max-width: 32px;
} */

/* .setup_guide_content_wrapper .contents_wrapper_container .affiliate_content_section>.right_section .toggleButtonWrapper>div {
    width: 32px !important;
}

.setup_guide_content_wrapper .contents_wrapper_container .affiliate_content_section>.right_section .toggleButtonWrapper>div>div:nth-child(1) {
    background: #1A1A1A !important;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05) inset;
    width: 32px !important;
    border-radius: 10px !important;
} */

.setup_guide_content_wrapper .contents_wrapper_container .left_section {}

.setup_guide_content_wrapper .contents_wrapper_container .left_section>ul {
    padding: 0px 0;
    list-style: none;
    margin: 0;
}

.setup_guide_content_wrapper .contents_wrapper_container .left_section>ul>li:not(:last-child) {
    margin: 0 0 10px;
}

.setup_guide_content_wrapper .contents_wrapper_container .left_section>ul>li {
    color: #000;
    font-family: Avenir;
    font-size: 12.797px;
    font-style: normal;
    line-height: 20px;
    /* 156.288% */
}

.setup_guide_content_wrapper .contents_wrapper_container .left_section>ul>li>strong {
    font-weight: 900;
}

h2.next_thing_heading {
    color: #303030;
    font-family: 'Avenir';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 3% 0 18px;
}

/*settings css*/
.affiliate_setting_wrapper {
    margin-top: 3%;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.affiliate_setting_wrapper .affiliate_setting_side_container {
    max-width: 23.5%;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0.125rem #00000012, 0 0.0625rem #00000012, 0.0625rem 0 #e3e3e3, -0.0625rem 0 #e3e3e3, 0 -0.0625rem #e3e3e3;
}

.affiliate_setting_sidebar_header .header_dropdown_button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--p-space-200);
    background: #f3f3f3;
    padding: 12px;
    width: 100%;
}

.affiliate_setting_sidebar_header .header_dropdown_button .icon-text {
    height: 40px;
    width: 40px;
}

.affiliate_setting_sidebar_header .header_dropdown_button .affiliate_user_name_wrapper {
    color: #000;
    font-weight: 900;
    font-family: 'Avenir';
    text-align: left;
    font-size: 14px;
}

.affiliate_setting_sidebar_header .header_dropdown_button .affiliate_email_wrapper {
    color: #616161;
}

.affiliate_setting_sidebar_body {
    width: 100%;
}

.affiliate_setting_sidebar_body ul.setting_item_list {
    list-style: none !important;
    width: auto;
    padding: 2px 8px;
}

.affiliate_setting_sidebar_body ul.setting_item_list li {
    padding: 7px 8px 4px;
    width: 100%;
    list-style: none;
    height: fit-content;
    margin: 0 0 5px;
    border-radius: 6px;
    cursor: pointer;
}

.affiliate_setting_sidebar_body ul.setting_item_list li:hover {
    background: #f3f3f3;
}

.affiliate_setting_sidebar_body ul.setting_item_list li .setting_list_item_wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    border: 0;
    gap: 5px;
}

body {
    box-sizing: border-box !important;
}

.affiliate_setting_sidebar_body ul.setting_item_list li .setting_list_item_wrapper span.icon_wrapper svg {
    margin: 0 !important;
    padding: 0;
    width: 16px;
}

.affiliate_setting_sidebar_body ul.setting_item_list li .setting_list_item_wrapper span.icon_wrapper {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.affiliate_setting_sidebar_body ul.setting_item_list li:after,
.affiliate_setting_sidebar_body ul.setting_item_list li:before,
.affiliate_setting_sidebar_body ul.setting_item_list:before {
    content: unset !important;
}

.affiliate_setting_main_container span.icon_wrapper {
    border: 0 !important;
}

.affiliate_setting_sidebar_body ul.setting_item_list li.active {
    background: #f3f3f3;
    color: #4a4a4a;
    font-size: 14px;
}

.setting_list_item {
    display: flex;
    align-items: center;
}

.affiliate_setting_sidebar_body ul.setting_item_list li span.tab_text span {
    font-weight: 600;
    font-size: 13px;
}

.affiliate_setting_body_container {
    width: 75%;
}

.affiliate_email_wrapper {
    font-size: 12px;
}

h1.shopify_style_header {
    word-break: break-word;
    overflow-wrap: break-word;
    line-height: 18px;
    font-size: 20px;
    font-weight: 900;
    margin: 20px 0;
    font-family: 'Avenir';
}


.shopify_base_card .Polaris-Card {
    border-radius: 12px !important;
    box-shadow: 0 0.125rem #00000012, 0 0.0625rem #00000012, 0.0625rem 0 #e3e3e3, -0.0625rem 0 #e3e3e3, 0 -0.0625rem #e3e3e3;
}

.shopify_base_card .Polaris-Card .Polaris-Card__Header {
    display: flex !important;
    padding: 0 !important;
    background: #f7f7f7f7 !important;
    border-radius: 15px 15px 0 0 !important;
}

.shopify_base_card .Polaris-Card .Polaris-Card__Header .card_title {
    display: flex !important;
    padding: 8px 15px;
    width: 100%;
    justify-content: space-between !important;
}

.shopify_base_card .Polaris-Card .Polaris-Card__Header .card_title span {
    font-size: 14px !important;
    font-weight: 900 !important;
}

.shopify_base_card .Polaris-Card .Polaris-Card__Header .card_title span>svg {
    width: 14px;
}

.chargezen_shopify_style_settings_section .contents_tab_wrapper {
    padding: 2%;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.chargezen_shopify_style_settings_section h6 {
    font-weight: 900 !important;
    color: #000;
}

.chargezen_shopify_style_settings_section p {
    font-weight: 900 !important;
    color: #616161 !important;
}

.chargezen_shopify_style_settings_section .contents_tab_wrapper>span {
    font-size: 16px !important;
    font-weight: 900 !important;
}

/* promotional type cart */
.promotional_types_card {
    padding: 10px;
    margin: 15px 0 0;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 8px;
}

.promotional_types_card .icon_wrapper {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
}

.promotional_types_card .promotion_type_toggle {
    display: flex;
    align-items: center;
    color: #444;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
}

.promotional_types_card .icon_wrapper svg {
    fill: #2d7cf5;
    height: 20px;
    margin-right: 4px;
    position: relative;
    transition: all .2s ease;
    width: 18px;
}

.promotional_types_card .promotional_types_container {
    background-clip: initial;
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #eee;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 0.5rem;
    position: relative;
}

.promotional_types_card .promotional_types_container:last-child {
    border: 0;
    padding-bottom: 0;
}

.promotional_types_wrapper {
    display: block;
    margin-bottom: -1px;
    padding: 10px 1px;
    border-bottom: 1px solid #e0e0e0;
}

.promotional_types_wrapper .promitional_type_question {
    display: flex;
    align-items: center;
}

.promotional_types_wrapper .promitional_type_question span {
    line-height: normal;
    color: #777;
    cursor: pointer;
    display: inline;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 4px;
}

.promotional_types_wrapper .promitional_type_question input[type="checkbox"] {
    margin: 0;
}

.promotional_types_wrapper .primary_promotional_type {
    display: flex;
    align-items: center;
}

.promotional_types_wrapper .primary_promotional_type input {
    margin: 0;
}

.promotional_types_wrapper .primary_promotional_type span {
    color: #777;
    cursor: pointer;
    display: inline;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 3px 0 3px 10px;
}

.promotional_types_helptext {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
}

.promotional_types_card .promotion_type_toggle.opened .icon_wrapper {
    transform: rotate(90deg) translateY(7px);
}

.chargezen_customer_detail_wrapper .payMShow .no_payout_methods {
    font-weight: 900;
}

.chargezen_customer_detail_wrapper .payMShow {
    margin: auto 0 0;
}