.detail-customer-overview-main.affiliate-overview-container .accordian span.cardBox {
    padding: 15px !important;
    margin: 0 0 17px;
    color: #212B36 !important;
    font-family: Avenir;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

.detail-customer-overview-main.affiliate-overview-container .accordian svg {
    top: 0;
    position: relative;
}

.detail-customer-overview-main.affiliate-overview-container .accordian span.cardBox>h4 {
    color: #212B36;
    font-size: 25px;
    font-style: normal;
    font-weight: 200;
    line-height: 30px;
    font-family: 'Avenir' !important;
}



.setup_guide_content_wrapper.contents_tab_wrapper {
    padding: 0 12px;
    height: 40px !important;
}



.setup_guide_content_wrapper .contents_tab_wrapper {
    padding: 0 12px;
    height: 40px;
}

.setup_guide_content_wrapper .contents_tab_wrapper span {
    color: #303030;
    font-family: 'Avenir' !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 20px;
}

.contents_wrapper_container {
    padding: 2%;
}

.contents_wrapper_container>.profile_row:not(:last-child) {
    padding: 0 0 14.65px;
    border-bottom: 1px solid #E3E3E3;
}

.contents_wrapper_container>.profile_row:not(:first-child) {
    padding-top: 12px;
}

.contents_wrapper_container>.profile_row>h6 {
    color: #303030;
    font-family: Avenir;
    font-size: 12.898px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 155.058% */
}

.contents_wrapper_container>.profile_row>h6+p {
    color: #616161;
    font-family: Avenir;
    font-size: 12.898px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: block;
    margin-top: 5px;
}

/* edit modal */


.Polaris-Modal-Dialog__Container .Polaris-Modal-Header {
    padding: 16px 16px 17px 16px;
    border-bottom: 1px solid #E3E3E3;
    background: #F3F3F3;
    align-items: center !important;
}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Header div.Polaris-Modal-Header__Title {
    margin: 0;
}

div.Polaris-Modal-Dialog__Container .Polaris-Modal-Dialog__Modal {
    margin: 15px auto;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 20px 20px -8px rgba(26, 26, 26, 0.28);
    overflow: hidden;
}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Header h2 {
    color: #303030;
    font-family: 'Avenir';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 143.982% */
    position: relative;
    top: 2px;
}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Header button.Polaris-Modal-CloseButton {
    padding: 0;
    margin: 0;
}

.Polaris-Modal__BodyWrapper {}

.Polaris-Modal__BodyWrapper label {
    color: #303030;
    font-family: Avenir;
    font-size: 12.898px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 155.058% */
}

.Polaris-Modal__BodyWrapper input,
.Polaris-Modal__BodyWrapper select {
    color: #303030;
    font-family: Avenir;
    font-size: 12.898px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 155.058% */
    border-radius: 8px;
    border: 1px solid #898F94;
    background: #FDFDFD;
}

.Polaris-Modal__BodyWrapper input+.Polaris-TextField__Backdrop {
    opacity: 0;
}

.Polaris-Modal__BodyWrapper select+div {
    color: #303030;
    font-family: Avenir;
    font-size: 12.898px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid #898F94 !important;
    background: #FDFDFD;
    border-radius: 8px;
    border: 1px solid #898F94;
    background: #FDFDFD;
}

.Polaris-Modal__BodyWrapper+.Polaris-Select__Backdrop {}

.Polaris-Modal__BodyWrapper .Polaris-Select__Backdrop {
    display: none;
}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Section .chargezen_form_row.flex_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.affiliate_country_helptext a {
    text-decoration: none;
    font-weight: bold;
    color: #005BD3;
}

.Polaris-Modal-Section .chargezen_form_row {
    margin: 16px 0 0;
}



.detail-customer-overview-main.affiliate-overview-container>.without_header_card>.Polaris-Card>.Polaris-Card__Header {
    padding: 14px 17px;
}


.detail-customer-overview-main.affiliate-overview-container>.without_header_card>.Polaris-Card>.Polaris-Card__Header>div>.Polaris-Stack__Item .Polaris-ButtonGroup__Item .card_header_button_group .card_header_buttons {
    width: 100%;
}

.detail-customer-overview-main.affiliate-overview-container>.without_header_card>.Polaris-Card>.Polaris-Card__Header>div>.Polaris-Stack__Item .Polaris-ButtonGroup__Item .card_header_button_group .card_header_buttons>.Polaris-ButtonGroup {
    justify-content: flex-start !important;
}


.detail-customer-overview-main.affiliate-overview-container>.without_header_card>.Polaris-Card>.Polaris-Card__Header>div>.Polaris-Stack__Item .Polaris-ButtonGroup__Item .card_header_button_group .card_header_buttons>.Polaris-ButtonGroup .Polaris-ButtonGroup__Item>button.Polaris-Button span {
    color: #A9ABB0 !important;
    text-align: center;
    font-family: Avenir;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 106.667% */
}

.detail-customer-overview-main.affiliate-overview-container>.without_header_card>.Polaris-Card>.Polaris-Card__Header>div>.Polaris-Stack__Item .Polaris-ButtonGroup__Item .card_header_button_group .card_header_buttons>.Polaris-ButtonGroup .Polaris-ButtonGroup__Item>button.Polaris-Button {
    padding: 5px 11.5px !important;
}

.detail-customer-overview-main.affiliate-overview-container>.without_header_card>.Polaris-Card>.Polaris-Card__Header>div>.Polaris-Stack__Item .Polaris-ButtonGroup__Item .card_header_button_group .card_header_buttons>.Polaris-ButtonGroup .Polaris-ButtonGroup__Item>button.Polaris-Button.Polaris-Button.Polaris-Button--primary span.Polaris-Button__Text {
    color: #017EFA !important;
    text-align: center;
    font-family: 'Avenir';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 113.778% */
}

.setup_guide_content_wrapper .contents_wrapper_container {
    border-radius: 0 0 12px 12px;
    background: #FFF;
    border-bottom: 0 !important;
}

.detail-customer-overview-main.affiliate-overview-container .accordian span.cardBox>h4 {
    font-weight: 500;
}

.detail-customer-overview-main.affiliate-overview-container .accordian svg {
    top: -3px !important;
}

.chargezen_customer_detail_wrapper .block_section .cardBox p+p {
    margin: 0 0 13.5px;
}


.button-accordian-payment-main>.payment-methods.cardBox.commanBoxS {
    min-height: 222px;
    display: flex;
    flex-flow: column;
}

.button-accordian-payment-main>.payment-methods.cardBox.commanBoxS span {
    margin-top: auto;
}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Section {
    padding: 16px;
}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Section>.chargezen_form_row {
    margin: 0 !important;
}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Section>.chargezen_form_row:not(:last-child) {
    margin: 0 0 16px !important;
}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Section>.chargezen_form_row label {
    color: #303030;
    font-family: 'Avenir';
    font-size: 12.898px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: block;
    margin: 0 0 4px;
}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Section>.chargezen_form_row .Polaris-Select.Polaris-Select--disabled select+div {
    background: #f2f2f2 !important;
    pointer-events: none !important;

}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Section>.chargezen_form_row .Polaris-Select.Polaris-Select--disabled select+div span.Polaris-Select__SelectedOption {
    color: #B5B5B5 !important;
}

.Polaris-Modal-Footer button.Polaris-Button {
    display: flex;
    /* width: 64.07px; */
    height: 28px;
    padding: 6px 11.8px 6px 12px !important;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px !important;
}

.Polaris-Page__Content .Polaris-Layout {
    align-items: unset !important;
    gap: 20px;
}
