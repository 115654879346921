body {
    color: #6f6f6f !important;
}

@media(max-width:1600px) {
    .Polaris-Card .Polaris-Card__Header .Polaris-Stack {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        align-items: center;
    }

    .rewards_choose_template .Polaris-Card__Section {
        padding: 0;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill {
        margin: 0;
        width: 100%;
        max-width: 100%;
        flex: 100%;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .heading_title {
        font-size: 22px;
        line-height: normal;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item {
        width: 100%;
        margin: 0;
        max-width: calc(100% - 320px);
        padding: 0 0 0 20px;
        flex: calc(100% - 320px);
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain {
        margin: 0;
        width: 100%;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain {
        padding: 0 !important;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content {
        margin: 0;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text {
        margin: 0;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup {
        margin: 0;
        display: flex;
        justify-content: flex-end;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item {
        margin: 0;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary {
        padding: 5px !important;

    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary .Polaris-Button__Text {
        font-size: 12.5px;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
        padding: 10px 10px !important;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
        font-size: 18px;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain {
        width: 100%;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain span {
        width: 100%;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill span {
        line-height: normal;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup {
        margin: 0 !important;
    }

}

@media(max-width:1365px) {
    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .heading_title {
        font-size: 19px;

    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill {
        max-width: 270px;
        flex: 270px;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item {
        max-width: calc(100% - 270px);
        flex: calc(100% - 270px);
    }

}

@media(max-width:1279px) {

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .heading_title {
        font-size: 17px;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill {
        max-width: 250px;
        flex: 250px;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item {
        max-width: calc(100% - 250px);
        flex: calc(100% - 250px);
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
        font-size: 16px;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary .Polaris-Button__Text {
        font-size: 12.5px;
    }

}


.back_button_wrapper {
    display: flex;
    gap: 0.5%;
    align-content: center;
    justify-content: center;
}

.back_button_wrapper .text_wrapper {
    text-align: start;
    padding-top: 0.4%;
}

/* new shopify like header and nav*/
.main_header {
    background: #001219;
    padding: 12.5px 16px;
    margin: 0 0 -1px;
    z-index: 999999999999;
    position: sticky;
    top: 0;
}

/*This should be greater than the header z-index*/
.Polaris-Frame__ContextualSaveBar.Polaris-Frame-CSSAnimation--startFade.Polaris-Frame-CSSAnimation--endFade {
    z-index: 9999999991
}

.main_header .first_div {
    display: none;
}

.main_header .header_main_div .second_div {
    margin-right: 0;
    gap: 8px;
}

.main_header .header_main_div .second_div .status {
    display: none;
}

.main_header .header_main_div .second_div .notification {
    border: 0;
    height: 31px;
    max-width: unset;
    width: 31px;
    background: #303030;
    color: #e3e3e3;
    border-radius: 0.5rem;
}

.main_header .header_main_div .second_div .notification button.Polaris-Button {
    padding: 0 !important;
    width: auto;
    height: auto;
    min-width: unset;
    min-height: unset;
}

.main_header .header_main_div .second_div .notification button.Polaris-Button span.Polaris-Button__Content {
    width: auto;
    height: auto;
    min-width: unset;
    height: auto;
    min-height: unset;
}


.main_header .header_main_div .second_div .userDetailMain .avatar {
    display: none;
}

.main_header .header_main_div .second_div .userDetailMain .left_section p.changeStoreDiv span.icon-text {
    border-radius: 0.375rem !important;
    font-size: 11px !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-left: 2px;
    padding-top: 3px;
    border-radius: 0.375rem;
}

.main_header .header_main_div .second_div .userDetailMain .left_section p.changeStoreDiv button.header_dropdown_button {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    min-height: 31px;
}

.main_header .header_main_div .second_div .userDetailMain .left_section p.changeStoreDiv button.header_dropdown_button span {
    font-size: 12px;
    font-weight: 600;
}

.main_header .header_main_div .second_div .notification svg.Polaris-Icon__Svg {
    fill: #fff;
}



.header_main_div .logo img {
    max-width: 140px;
}

.module-navbar.dashboardArea .navBar {
    background: #ebebeb !important;
    max-width: 240px;
}

.module-navbar.dashboardArea .navBar .logo_main {
    display: none;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation {
    background: #ebebeb;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section {
    padding-top: 4px;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem {
    margin: 0;
    padding: 0 0 16px;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation>.Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a {
    padding: 0 12px 10px 20px !important;
    line-height: 100% !important;
    height: auto !important;
    min-height: unset !important;
    font-size: 0 !important;
    flex-grow: unset !important;
    width: 100% !important;
    display: flex;
    align-items: center;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation>.Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a span.Polaris-Navigation__Text {
    font-size: 12px !important;
    line-height: 100%;
    margin: 0 !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation>.Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a::before {
    width: 0;
    height: 0;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation {
    padding: 0 12px 0 13px;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item {
    padding: 0 !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item span.Polaris-Navigation__Text {
    font-size: 13px !important;
    color: #303030 !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item span.Polaris-Icon svg {
    width: 23px;
    height: 23px;
}

.module-navbar.dashboardArea .navBar:hover nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item span.Polaris-Icon svg {
    width: 18px;
    height: 18px;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item span.Polaris-Icon svg path {
    fill: #4a4a4a;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item {
    align-items: center;
    display: flex;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item .Polaris-Navigation__Icon {
    position: relative;
    top: 2px;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem:not(:last-child) {
    margin: 0 0 7px;
}

.navBar .sidBartabs nav.Polaris-Navigation .Polaris-Navigation__PrimaryNavigation>ul.Polaris-Navigation__Section>li .Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item:hover {
    background: #fafafa !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>button.Polaris-Navigation__Item {
    display: none !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation {
    padding: 9px 0px 0 0px;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li:not(:last-child) {
    margin: 0 0 8px;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>a.Polaris-Navigation__Item {
    padding: 3px 5px !important;
    min-height: 25px;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li>div>a.Polaris-Navigation__Item>span.Polaris-Navigation__Text {
    font-size: 13px !important;
    line-height: 13px !important;
    color: #303030 !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item {
    padding: 0px 7px 6px !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div.Polaris-Collapsible {
    padding: 0 0 0 15px !important;
}

/* shopify like header and nav end */

span.chargezen_round_text_icon {
    width: 100%;
    max-width: 42px;
    min-height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: #017efa;
    color: #fff;
    font-weight: 600;
}

.header_main_div .logo {
    display: flex;
    align-items: center;
}

.header_main_div .logo .shop_tier_wrapper {
    color: #fff;
    font-style: italic;
    font-size: 18px;
    padding-top: 4px;
    margin-right: 20%;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div.Polaris-Collapsible>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List {
    margin-top: 10px;
}



.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div.Polaris-Collapsible>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item * {
    color: #303030 !important;
    font-size: 13px !important;
    line-height: 100%;
    padding: 0 !important;
    margin-bottom: 0;
    fill: #4a4a4a;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div.Polaris-Collapsible>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item {
    padding: 3px 5px 3px 15px !important;
}

.module-navbar.dashboardArea .navBar nav.Polaris-Navigation ul.Polaris-Navigation__Section>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div.Polaris-Collapsible>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__SecondaryNavigation>div>ul.Polaris-Navigation__List>li.Polaris-Navigation__ListItem>.Polaris-Navigation__ItemWrapper>a.Polaris-Navigation__Item.Polaris-Navigation--subNavigationActive::before {
    width: 0;
    height: 0;
}

.rewards_template_wrapper.zenn_portal_wrapper .expand_collapse_button_wrapper button.expand_view_button span.Polaris-Icon {
    margin: 0;
}


.expand_collapse_button_wrapper button.expand_view_button,
.expand_collapse_button_wrapper_main button.expand_view_button {
    background: #fff !important;
    padding: 9px 16px 7px;
    border: 0;
    overflow: visible !important;
    display: flex;
    align-items: center;
    grid-gap: 9px;
    gap: 9px;
    color: #017efa;
    font-weight: 600;
    font-family: Avenir !important;
    font-size: 15px;
    display: flex;
    margin-left: auto;
    width: 100%;
    max-width: fit-content;
    margin-bottom: 15px;
    border-radius: 5px;
}

.expand_collapse_button_wrapper button.expand_view_button svg.Polaris-Icon__Svg,
.expand_collapse_button_wrapper_main button.expand_view_button svg.Polaris-Icon__Svg {
    fill: #017efa !important;
    position: relative;
    top: -1px;
}

.custom_email_notification_bottom_section {
    width: 100%;
}

.custom_email_notification_bottom_section .email_editor {
    margin-bottom: 38px;
}

.text-align-left {
    text-align: left !important;
}

.app-content .Polaris-Page .Polaris-Page {
    margin: 0;
}

.manage_apps_icon_wrapper {
    padding-left: 25px;
    border-left: 1px solid #ddd;
}

.manage_apps_icon_wrapper .manage_apps_wrapper {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.manage_apps_icon_wrapper svg path {
    fill: transparent;
    color: #001219;
}

.manage_apps_icon_wrapper svg {
    width: 28px;
    height: 28px;
    fill: #fff;
}

.manage_apps_icon_wrapper svg rect {
    color: #001219;
}

.manage_apps_icon_wrapper span.text_wrapper {
    color: #fff;
}

.manage_apps_icon_wrapper.activate svg,
.manage_apps_icon_wrapper.activate .manage_apps_wrapper span.text_wrapper {
    fill: #2d7cf5 !important;
    color: #2d7cf5 !important;
}

.app-content .Polaris-Page {
    margin: 3%;
}

.back_button_wrapper>.text_wrapper.page_heading_title_wrapper {
    padding: 0;
}

.back_button_wrapper>.text_wrapper.page_heading_title_wrapper {
    align-items: center;
}

.back_button_wrapper>.text_wrapper.page_heading_title_wrapper>span {
    position: relative;
    top: 1px;
    font-size: 28px;
    font-weight: 700;
    font-family: 'Avenir';
    color: #212B36;
    line-height: 32px;
    text-transform: capitalize;
}

.chargezen_custom_page_header .sidebar_summary.header_description {
    color: #6f6f6f;
    margin-top: 1%;
    font-size: 13px;
    line-height: 20px;
}

.without_header_card .Polaris-Card .Polaris-Card__Header .Polaris-Stack__Item.Polaris-Stack__Item--fill {
    display: none;
}

.without_header_card .Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item {
    max-width: 100%;
    min-width: 100%;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup {
    margin: 0;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain {
    width: 100%;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain {
    width: 100%;
}

.card_header_button_group {
    display: flex;
    justify-content: space-between;
}

.card_header_button_group>.card_header_buttons.primary_button_group>.Polaris-ButtonGroup {
    margin: 0;
}

.card_header_button_group>.card_header_buttons.primary_button_group>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item:empty {
    display: none;
}

.card_header_button_group>.card_header_buttons.primary_button_group>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item {
    margin: 0 !important;
    border-radius: 0 !important;
    outline: none !important;
    box-shadow: none !important;
}

.card_header_button_group>.card_header_buttons.primary_button_group>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item>button {
    padding: 5px 10px !important;
    font-size: 15px !important;
    color: #A9ABB0 !important;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 3px !important;
}

.card_header_button_group * {
    margin: 0 !important;
}

.card_header_button_group>.card_header_buttons.primary_button_group>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item>button.Polaris-Button.Polaris-Button--primary {
    background: #F5F7FB !important;
}

.card_header_button_group>.card_header_buttons.primary_button_group>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item>button.Polaris-Button.Polaris-Button--primary span.Polaris-Button__Text {
    color: #017EFA !important;
    font-weight: 700;
    font-family: 'Avenir';
    font-size: 10px !important;
}

.card_header_button_group>.card_header_buttons.primary_button_group>.Polaris-ButtonGroup {
    gap: 7px;
}

.card_header_button_group .card_header_buttons.secondary_button_group {
    gap: 10px;
    display: flex;
    align-items: center;
    margin-left: auto !important;
}

.card_header_button_group .card_header_buttons.secondary_button_group>button {
    padding: 10px 7px !important;
    min-width: 143px;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 3px !important;
}

.card_header_button_group .card_header_buttons.secondary_button_group>button span.Polaris-Button__Text,
.card_header_button_group .card_header_buttons.secondary_button_group>button a {
    font-size: 14px !important;
    line-height: 16px !important;
    color: #017EFA !important;
    font-weight: 700;
    font-family: 'Avenir';
    text-decoration: none !important;
}

.card_header_button_group>.card_header_buttons.primary_button_group>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item>button {
    display: inline-flex;
    align-items: center;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--primary .Polaris-Button__Text {
    font-size: 12.5px !important;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup {
    align-items: baseline;
}

.card_header_button_group .card_header_buttons.secondary_button_group>button {
    background: #F5F7FB !important;
}

.Polaris-Layout__Section.Polaris-Layout__Section--secondary {
    padding: 0 !important;
}