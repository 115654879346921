.all_offers_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    grid-gap: 50px;
    gap: 50px;
    width: 100%;
    border-radius: 5px;
    position: relative;
    padding: 55px 0;
}

.all_offers_container h4.offer_header {
    position: absolute;
    left: 8px;
    top: 8px;
    word-break: break-word;
    overflow-wrap: break-word;
    font-weight: 600;
    font-size: 20px;
    line-height: 2.8rem;
    color: #202223;
}


.all_offers_container .upsell_offer_wrapper_container .upsell_offer_wrapper img {
    width: 144px;
    height: 144px;
    object-fit: cover;
    border: 1px solid #ddd;
}

.all_offers_container .upsell_offer_wrapper_container .upsell_offer_wrapper {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
}

.all_offers_container .upsell_offer_wrapper_container .Polaris-Card {
    max-width: 100% !important;
}

.all_offers_container .upsell_offer_wrapper_container .upsell_offer_wrapper .content_block {
    display: flex;
    flex-flow: column;
    gap: 16px;
    font-size: 16px;
    font-weight: 900;
    width: 100%;
    max-width: calc(100% - 144px);
    padding: 0 0 0 26px;
}

.all_offers_container .upsell_offer_wrapper_container .upsell_offer_wrapper .content_block .date_wrapper {
    font-size: 14px;
    font-weight: 500;
}

.upsell_offer_wrapper .more_options {
    position: absolute;
    right: 8px;
    background: transparent;
    z-index: 999;
    border: 0;
    width: fit-content;
    padding: 0;
    margin: 0;
}

.upsell_offer_wrapper_container {
    position: relative;
}

.all_offers_container .upsell_offer_wrapper_container .upsell_offer_wrapper .options_lists {
    background: #fff;
    margin-top: 40px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 8px;
    padding: 10px 0;
    width: fit-content;
}

.all_offers_container .upsell_offer_wrapper_container .upsell_offer_wrapper .options_lists .option_wrapper {
    padding: 12px 20px 12px 12px;
    cursor: pointer;
}

.upsell_offer_wrapper .option_wrapper:hover {
    background: #dddddd5c;
    opacity: 1;
}

.upsell_offer_wrapper .more_options.icon_wrapper {
    cursor: pointer;
}

.upsell_offer_wrapper .option_wrapper.delete_offer {
    color: #d82c0d;
}

.upsell_offer_wrapper_container {
    width: 100%;
    max-width: calc(100%/2 - 25px);
    flex: calc(100%/2 - 25px);
}

.all_offers_container .upsell_offer_wrapper_container .upsell_offer_wrapper {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;
}

.header_content.toggleAccordian {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.header_content.toggleAccordian button.icon_wrapper {
    padding: 0;
    background: transparent;
    border: 0;
    margin: 0;
}

.sub_contentBlock .header_content.toggleAccordian {
    display: flex;
    gap: 15px;
}

.content_block_form_wrapper {
    position: relative;
}

.or_and_wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 5%);
    z-index: 9999;
    background: #dddddd4a;
    padding: 2px 15px;
    border-radius: 20px;
    border: 1px solid #c9c3c3;
}

.content_block_wrapper .sub_contentBlock {
    position: relative;
    margin-top: 25px;
    background: #dddddd4a;
    padding: 12px 8px 1px;
    border-radius: 5px;
}

.upsells_templates_container .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card {
    max-width: calc(33% - 10px) !important;
}

.upsell_setting_container .Polaris-Layout__Section.Polaris-Layout__Section--secondary {
    max-width: 22%;
    margin-left: 0;
    margin-right: 0;
    min-width: 290px;
    padding-right: 0;
}


.upsell_offer_wrapper_container.cart_funnel_wrapper_container {
    max-width: calc(33.33% - 19px);
    min-width: 483px;
}

.upsell_offer_wrapper_container.cart_funnel_wrapper_container .content_block_wrapper {
    position: relative;
    padding-bottom: 30px;
}

.upsell_offer_wrapper_container.cart_funnel_wrapper_container .content_block_wrapper .content_block_footer {
    display: flex;
    padding: 0 0 0 26px;
    gap: 8px;
    margin: 15px 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
}

.upsell_offer_wrapper_container.cart_funnel_wrapper_container .content_block_wrapper .content_block_footer .more_options {
    position: unset;
    width: 28px;
    height: 28px;
}

.upsell_offer_wrapper_container.cart_funnel_wrapper_container .content_block_wrapper .content_block_footer .more_options>.more_options.icon_wrapper {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px -1px 0px 0px #B5B5B5 inset, 0px 0px 0px 1px rgba(0, 0, 0, 0.10) inset, 0px 0.5px 0px 1.5px #FFF inset;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upsell_offer_wrapper_container.cart_funnel_wrapper_container .content_block_wrapper .content_block_footer button {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px -1px 0px 0px #B5B5B5 inset, 0px 0px 0px 1px rgba(0, 0, 0, 0.10) inset, 0px 0.5px 0px 1.5px #FFF inset;
    width: 85.38px;
    height: 28px;
    padding: 6px 11.8px 6px 12px;
    border: 0;
    color: #303030;
    text-align: center;
    font-family: Inter;
    font-size: 11.813px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 135.45% */
}

.upsell_offer_wrapper_container.cart_funnel_wrapper_container .content_block_wrapper .content_block_footer button.chargezen_primary_button {
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0.00) 63.53%, rgba(255, 255, 255, 0.15) 100%), #001219;
    box-shadow: 0px -1px 0px 1px rgba(0, 0, 0, 0.80) inset, 0px 0px 0px 1px #303030 inset, 0px 0.5px 0px 1.5px rgba(255, 255, 255, 0.25) inset;
    color: #FFF;
}

.upsell_offer_wrapper_container.cart_funnel_wrapper_container .content_block_wrapper span.offer_title {
    color: #6F6F6F;
    font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    /* 125% */
}

.upsell_offer_wrapper_container.cart_funnel_wrapper_container .content_block_wrapper .date_wrapper {
    color: #6F6F6F;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.upsell_offer_wrapper_container.cart_funnel_wrapper_container .content_block_wrapper .content_block {
    max-width: 100%;
}

.all_offers_container .upsell_offer_wrapper_container .upsell_offer_wrapper {
    align-items: unset;
}