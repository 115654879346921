.Polaris-Popover__Content {
    min-height: 313px;
}

.Polaris-Popover__PopoverOverlay .Polaris-Popover {
    outline: none;
    border-radius: 12px;
}

.Polaris-Popover__Wrapper {
    border-radius: 12px;
    outline: none;
}

.header_dropdown_button {
    padding: 2px;
    border-radius: 8px;
    border: none;
    background: #303030;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    transition: all ease-in-out 0.3s;
}

.header_dropdown_button:hover,
.header_dropdown_button[aria-expanded="true"] {
    background: #616161;
}

.header_dropdown_button .icon-text {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1BC1E0;
    border-radius: 6px;
    padding: 0;
}

.header_dropdown_button span {
    padding: 0 8px 0 10px;
}

.header_dropdown_items_wrapper {
    margin: 0;
    padding: 0;
}

.header_dropdown_items_wrap .sub_item_wrapper {
    padding: 6px;
}

.header_dropdown_items_wrap .sub_item_wrapper:not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 4px;
    border-bottom: 1px solid #ebebeb;
}

.header_dropdown_items_wrapper .header_dropdown_item {
    padding: 6px;
    border-radius: 8px;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}

.header_dropdown_items_wrapper .header_dropdown_item:hover,
.header_dropdown_items_wrapper .header_dropdown_item.active {
    background-color: #f1f1f1;
    color: inherit;
}

.header_dropdown_items_wrapper .header_dropdown_item:not(:last-child) {
    margin-bottom: 5px;
}

.header_dropdown_items_wrapper .header_dropdown_item .icon_text {
    background-color: #1BC1E0;
    height: 24px;
    width: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    font-size: 10px;
    line-height: 17px;
}

.sub_item_heading {
    padding: 6px;
}

.header_dropdown_items_wrapper .header_dropdown_item a {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    text-decoration: none;
}

span.header_icon-wrapper {
    margin-right: 6px;
    width: 24px;
}