.header_wraper {
    display: flex;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.15);
    min-height: 69px;
}

.header_wraper span.left_section {
    background-color: #f9fafb;
    align-items: center;
    justify-content: center;
    padding: 16px;
    width: 100%;
    max-width: 400px;
    display: flex;
    position: relative;
    border: 0.1rem solid #dfe3e8;
    border-top: 0;
    border-left: 0;
}

.header_wraper span.left_section span.Polaris-Icon.Polaris-Icon--colorBase {
    position: absolute;
    left: 12px;
}

.header_wraper span.left_section span.Polaris-Icon.Polaris-Icon--colorBase svg.Polaris-Icon__Svg {
    width: 20px;
    height: 20px;
}

.header_wraper span.left_section h2 {
    margin: 0;
    font-size: 14px;
    line-height: normal;
}

body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

.heder-right-section {
    width: 100%;
    max-width: calc(100% - 400px);
    padding: 0 16px;
    background-color: #fff;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.heder-right-section button.save-button {
    margin-left: auto;
    background: linear-gradient(to bottom, #6371c7, #5563c1) !important;
    border-color: #3f4eae;
    box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
        0 0 0 0 transparent;
    color: #fff;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0.66rem 1rem;
    background: linear-gradient(to bottom, #fff, #f9fafb);
    border: 0.1rem solid #c4cdd5;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border-radius: 3px;
    line-height: 1;
    color: #fff;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    text-decoration: none;
    transition-property: background, border, box-shadow;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
    margin-left: auto;
}

.heder-right-section button.save-button.disabled {
    background: linear-gradient(to bottom, #bac0e6, #bac0e6) !important;
}

.header_wraper span.left_section {
    border-bottom: 0;
}

.content-section {
    display: flex;
    flex-wrap: wrap;
}

.content-section>.left_sidebarr {
    width: 100%;
    max-width: 400px;
}

.content-section>.right-section {
    width: 100%;
    max-width: calc(100% - 400px);
}

.content-section {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.content-section>.left_sidebarr {
    width: 100%;
    max-width: 400px;
    border: 0.1rem solid #dfe3e8;
    background: #f9fafb;
}

.content-section>.right-section {
    width: 100%;
    max-width: calc(100% - 400px);
}

.content-section>.left_sidebarr>ul.polaris-card {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 1.6rem;
}

.content-section>.left_sidebarr>ul.polaris-card>li:not(:first-child) {
    border-top: 0.1rem solid #dfe3e8;
}

.content-section>.left_sidebarr>ul.polaris-card>li {
    display: flex;
    padding: 20px;
    box-shadow: none;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    align-items: center;
    gap: 10px;
    background: #fff;
}

.content-section>.left_sidebarr>ul.polaris-card>li>span.left_section {
    font-size: 14px;
    color: #212b36;
}

.customize-card {
    margin-top: 20px;
    padding: 20px;
}

.customize-card p {
    margin: 0;
    font-size: 14px;
    line-height: 130%;
    color: #212b36;
}

.content-section>.left_sidebarr>ul.polaris-card>li:hover {
    box-shadow: inset -4px 0 0 0 #5c6ac4;
    background-color: #f4f6f8;
}

.content-section>.left_sidebarr>ul.polaris-card {
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.colors_content_wrapper {
    padding: 16px;
    display: flex;
    flex-flow: column;
    gap: 16px;
    background: #fff;
}

.select_colors_container {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.select_colors_container div:first-child {
    width: 100%;
}

.Polaris-Card__Section .card_body_content .card_description {
    color: #637381;
    margin: 14px 0;
}

.rewards_launcher_settings_wrapper .radio_butons_wrapper {
    display: flex;
    flex-flow: column;
    gap: 2px;
    margin-bottom: 10px;
}

.rewards_launcher_settings_wrapper .rewards_button_icons_wrapper {
    display: flex;
    gap: 16px;
    justify-content: space-between;
}

.rewards_launcher_settings_wrapper .rewards_button_icons_wrapper .icon {
    max-width: 25%;
    border: 1px solid #ddd;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #dddddd45;
}

.rewards_button_icons_wrapper .icon.selected {
    border: 1px solid #001219;
}

.customise_susbcription_wrapper {
    display: flex;
    flex-flow: column;
    gap: 12px;
}

.customise_susbcription_wrapper h4 {
    font-size: 15px;
    font-weight: 900;
}

.fields_wrapper {
    border-bottom: 1px solid #ddd !important;
    padding: 16px 0;
}

.widget_html_container {
    margin: auto;
    width: fit-content;
    margin-top: 100px;
}

.chargezen_subscription_widget_wrapper {
    font-family: Avenir;
    box-shadow: 0 0 80px 0 rgba(0, 0, 0, .12);
}