.chargezen_nav_contents {
    padding-top: 23px;
}

.chargezen_nav_contents>.nav_list_item {
    margin: 0;
    padding: 0 0 16px;
}

.chargezen_nav_contents>.nav_list_item ul.subNavItems {
    padding: 0;
    margin: 0;
    list-style: none;
}

h2.nav_item_header {
    color: #444 !important;
    font-size: 12px !important;
    line-height: 100%;
    margin: 0 !important;
    font-family: inherit;
    padding: 0 12px 10px 20px !important;
    line-height: 100% !important;
    height: auto !important;
    min-height: unset !important;
    flex-grow: unset !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
}

.chargezen_nav_contents>.nav_list_item ul.subNavItems {
    padding: 0 12px 0 13px;
}

.chargezen_nav_contents>.nav_list_item ul.subNavItems>li.subnav_list {
    display: flex;
    align-items: center;
    padding: 0 7px 6px !important;
    justify-content: flex-start;
    cursor: pointer;
}

.chargezen_nav_contents>.nav_list_item ul.subNavItems>li.subnav_list svg {
    width: 18px;
    height: 18px;
    min-width: 18px;
}

.chargezen_nav_contents>.nav_list_item ul.subNavItems>li.subnav_list svg path {
    fill: #4a4a4a;
}

.chargezen_nav_contents>.nav_list_item ul.subNavItems>li.subnav_list .nav_icon_wrapper {
    display: flex;
}

.chargezen_nav_contents>.nav_list_item ul.subNavItems>li.subnav_list span.nav_text {
    font-size: 13px !important;
    color: #303030 !important;
    font-family: inherit;
    margin: 0 0 0 8px;
    white-space: nowrap;
}

.module-navbar.dashboardArea .chargezen_navbar {
    width: 100%;
    max-width: 60px;
    transition: all 0.3s ease;
    height: 100vh;
    overflow: auto;
    position: fixed;
    z-index: 99999;
    background: #ebebeb;
}

h2.nav_item_header {
    opacity: 0;
}

.chargezen_nav_contents>.nav_list_item ul.subNavItems>li.subnav_list span.nav_text {
    opacity: 0;
}

.module-navbar.dashboardArea .chargezen_navbar:hover,
.module-navbar.dashboardArea .chargezen_navbar.opened {
    max-width: 240px;
}

.module-navbar.dashboardArea .chargezen_navbar:hover h2.nav_item_header,
.module-navbar.dashboardArea .chargezen_navbar.opened h2.nav_item_header {
    opacity: 1
}

.module-navbar.dashboardArea .chargezen_navbar:hover h2.nav_item_header,
.module-navbar.dashboardArea .chargezen_navbar.opened h2.nav_item_header {
    font-size: 12px !important;
}

.module-navbar.dashboardArea .chargezen_navbar:hover span.nav_text,
.module-navbar.dashboardArea .chargezen_navbar.opened span.nav_text {
    opacity: 1 !important;
}

.chargezen_nav_contents>.nav_list_item ul.subNavItems>li.subnav_list:not(:last-child) {
    margin: 0 0 7px;
}

.chargezen_nav_contents>.nav_list_item ul.subNavItems>li.subnav_list:hover {
    background: #fafafa !important;
}

.Polaris-Page {
    width: 100% !important;
    max-width: 100% !important;
    padding: 3.5% !important;
    margin: 0 !important;
}

.Polaris-Page .Polaris-Page__Content {
    margin-top: 0;
}


.chargezen_navbar::-webkit-scrollbar {
    width: 2px;
}

.chargezen_navbar::-webkit-scrollbar-track {
    background: #ddd;
}

.chargezen_navbar::-webkit-scrollbar-thumb {
    background: #001219;
}

h2.nav_item_header .sub_nav_label {
    display: flex;
    gap: 2px;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

h2.nav_item_header .sub_nav_label span.Polaris-Icon {
    margin: 0;
}

.sub_nav_label span,
.sub_nav_label svg {
    color: #444 !important;
    fill: #444 !important;
}

.chargezen_navbar:hover+.app_content {
    max-width: calc(100% - 240px) !important;
}

.toggle_nav_button {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: -14px;
    padding: 0 8px;
    margin-bottom: 16px;
}

.toggle_nav_button>div>div {
    max-height: 15px !important;
    max-width: 44px;
}