.translations_form_wrapper .content_wrapper_container {
    margin-top: 30px;
}

.rewards_template_wrapper.zenn_portal_wrapper .Polaris-Tabs__Wrapper {
    background: transparent;
}

.chargezen .Polaris-Page__Content .Polaris-Tabs__Wrapper .Polaris-Tabs__Title {
    height: unset !important;
}