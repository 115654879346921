.side_bar_container.Polaris-Card ul.Polaris-Tabs {
    justify-content: space-between;
    gap: 0;
    flex-wrap: nowrap;
}

.side_bar_container.Polaris-Card ul.Polaris-Tabs li.Polaris-Tabs__TabContainer {
    /* width: 50%; */
    width: 100%;
}

.side_bar_container.Polaris-Card .field_row.navi.Polaris-Card__Section:hover {
    background: #dddddd33;
}

.side_bar_container.Polaris-Card .field_row.Polaris-Card__Section .field_row_label {
    font-size: 12px;
    font-weight: 900;
    line-height: 1.6rem;
    text-transform: uppercase;
}

.side_bar_container.Polaris-Card .field_row.Polaris-Card__Section .field_row_content {
    margin-top: 0px;
    color: #6d7175;
    font-size: 14px;
}

.field_row.navi.Polaris-Card__Section:hover {
    background: #dddddd33;
}

.field_row.navi.Polaris-Card__Section .field_row_label {
    font-size: 12px;
    font-weight: 900;
    line-height: 1.6rem;
    text-transform: uppercase;
}

.field_row.navi.Polaris-Card__Section .field_row_content {
    margin-top: 10px;
    color: #6d7175;
    font-size: 12px;
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.flex.justify-between.sidebar_action_buttons button {
    width: 100%;
    background: transparent;
}

.flex.justify-between.sidebar_action_buttons {
    gap: 16px;
}

.field_row_content.upsell_color_picker {
    display: flex;
    align-items: center;
    gap: 16px;
}

.field_row_content.upsell_color_picker .Polaris-Label .Polaris-Label__Text {
    font-size: 10px !important;
}

.field_row_content.upsell_color_picker input.Polaris-TextField__Input {
    padding: 4px !important;
}

.main_header_content {
    display: flex;
    justify-content: space-between;
    padding: 17px 20px;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.main_header_content button.back_button_pointer {
    display: flex;
    justify-content: start;
    width: fit-content;
    background: none;
    border: 0;
    gap: 5px;
}

button.back_button_pointer span.icon svg {
    width: 16px;
    height: 16px;
    font-weight: 800;
}

.main_header_content .button_text {
    display: flex;
    justify-content: center;
    width: 100%;
}

.side_bar_container .select_discount_type {
    max-width: fit-content;
}

.side_bar_container .discount_field_wrapper {
    display: flex;
    gap: 5px;
}

.upsell_setting_container .button_group.toggleButtons {
    display: flex;
    border: 1px solid #ddd;
    width: fit-content;
    border-radius: 3px;
}

.upsell_setting_container .button_group.toggleButtons button.Polaris-Button {
    border: 0;
    max-height: 25px !important;
    min-height: 25px !important;
    height: 100% !important;
}

.upsell_setting_container .button_group.toggleButtons button.Polaris-Button.Polaris-Button--primary {
    background: #008161;
}

.upsell_setting_container .order_condition_help_text {
    margin-top: 16px;
    font-size: 13px;
    color: #000000b5;
}

.add_new_content_container {
    margin-top: 16px;
    border-top: 1px solid #ddd;
    padding-top: 12px;
}

.add_new_content_container button.add_new_content_button {
    background: transparent;
    border: 0;
    font-size: 24px;
    color: #008262;
    font-weight: 500;
    padding: 0 !important;
    cursor: pointer;
}

.mt-16 {
    margin-top: 16px !important;
}

.layout_selector_wrapper {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.layout_selector_wrapper .img_wrapper {
    width: 100%;
    border: 1px solid #33242461;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
}

.layout_selector_wrapper .img_wrapper img {
    width: 100%;
}

.layout_selector_wrapper .img_wrapper img {
    width: 100%;
}

.layout_subselector_wrapper {
    position: relative;
}

.layout_subselector_wrapper .text {
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
    font-size: 13px;
}


.upsell_setting_container .Polaris-Page__Content .Polaris-Tabs__Wrapper {
    background: transparent !important;
    border-bottom: 1px solid #ddd;
}

.upsell_setting_container .Polaris-Page__Content .field_row.Polaris-Card__Section {
    flex-flow: column;
    justify-content: center;
    padding: 16px 32px;
}

.field_row.Polaris-Card__Section {
    flex-flow: column;
    justify-content: center;
}

.discount_field_wrapper div:first-child {
    width: 100%;
}

.add_new_content_container .content_block_wrapper>.sub_contentBlock:not(:last-child) {
    margin-bottom: 10px;
}

.add_new_content_container .content_block_wrapper>.sub_contentBlock {
    padding: 0px 10px 1px;
    border-radius: 4px;
    background-color: rgb(250, 251, 251);
    position: relative;
}

.add_new_content_container .content_block_wrapper>.sub_contentBlock .header_content.toggleAccordian {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
}

.add_new_content_container .content_block_wrapper>.sub_contentBlock .header_content.toggleAccordian button.icon_wrapper {
    width: auto;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
}

.add_new_content_container .content_block_wrapper>.sub_contentBlock .header_content.toggleAccordian button.icon_wrapper svg.Polaris-Icon__Svg {
    fill: #8c9196;
}


.add_new_content_container .content_block_wrapper>.sub_contentBlock .header_content.toggleAccordian:focus button.icon_wrapper {
    transform: rotate(-180deg);
}

.add_new_content_container .content_block_wrapper>.sub_contentBlock .content_block_form_wrapper>.row_wrapper:not(:first-child) {
    margin-top: 1.6rem;
}

.add_new_content_container .content_block_wrapper>.sub_contentBlock .content_block_form_wrapper .row_wrapper:first-child {
    position: relative;
}

.add_new_content_container .content_block_wrapper>.sub_contentBlock .content_block_form_wrapper .row_wrapper:first-child>input {
    font-size: 0;
    width: 100%;
    padding: 7px 16px;
    min-height: 36px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.add_new_content_container .content_block_wrapper>.sub_contentBlock .content_block_form_wrapper .row_wrapper:first-child label {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 36px;
    align-items: center;
    padding: 7px 16px;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #babfc4;
    background: #fff;
    border-radius: 0.4rem;
    cursor: pointer;
    z-index: 1;
    position: relative;
}

.add_new_content_container .content_block_wrapper>.sub_contentBlock .content_block_form_wrapper>.row_wrapper .Polaris-Select__Backdrop,
.add_new_content_container .content_block_wrapper>.sub_contentBlock .content_block_form_wrapper>.row_wrapper .Polaris-TextField__Backdrop {
    background: #fff;
    border: 0.1rem solid #c9cccf;
    border-bottom-color: #babfc4;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}



.add_new_content_container .flex.justify-between {
    align-items: center;
    margin: 0 0 12px;
}


.side_bar_container.Polaris-Card .card_content_wrapper>.form_field_wrapper {
    max-height: calc(100vh - 10px);
    overflow-y: auto;
}

.upsell_setting_container .side_bar_container.Polaris-Card .form_field_wrapper .preview_variants_wrapper .slick-list {
    transform: none !important;
}

.upsell_setting_container .side_bar_container.Polaris-Card .form_field_wrapper .preview_variants_wrapper .slick-list .slick-track {
    transform: none !important;
    width: 100% !important;
    display: flex !important;
    flex-flow: column;
}

.upsell_setting_container .side_bar_container.Polaris-Card .form_field_wrapper .preview_variants_wrapper .slick-list .slick-track .slick-slide {
    width: 100% !important;
}

.upsell_setting_container .side_bar_container.Polaris-Card .form_field_wrapper .preview_variants_wrapper .slick-list .slick-track .slick-slide div.preview-item {
    display: flex !important;
    align-items: center;
}

.upsell_setting_container .side_bar_container.Polaris-Card .form_field_wrapper .preview_variants_wrapper .slick-list .slick-track .slick-slide div.preview-item>.img {
    width: 100%;
    max-width: 100px;
    margin: 0;
    height: auto;
}

.upsell_setting_container .side_bar_container.Polaris-Card .form_field_wrapper .preview_variants_wrapper .slick-list .slick-track .slick-slide div.preview-item>.img img {
    height: auto;
}

.upsell_setting_container .side_bar_container.Polaris-Card .form_field_wrapper .preview_variants_wrapper .slick-list .slick-track .slick-slide div.preview-item>p {
    width: 100%;
    max-width: calc(100% - 100px);
    text-align: left;
    margin: 0;
}

.form_field_wrapper.schedule_form {
    padding: 20px;
    overflow: unset;
    max-width: 100%;
    margin: 0 auto;
}

.form_field_wrapper.schedule_form>div:not(:first-child) {
    margin-top: 1.6rem;
}

.form_field_wrapper.schedule_form>div.radio_button_wrapper {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.form_field_wrapper.schedule_form span.Polaris-Choice__Label {
    font-size: 14px;
    color: #000;
    font-weight: 900;
    line-height: 1.6rem;
    text-transform: uppercase;
}



.form_field_wrapper.schedule_form .publish_date_wrapper .column_wrapper {
    margin: 0;
}

.form_field_wrapper.schedule_form .publish_date_wrapper .column_wrapper>div {
    margin: 0 0 20px;
}

.form_field_wrapper.schedule_form .set_end_date_check {
    margin: 0 0 20px;
}


.form_field_wrapper.schedule_form label {
    font-weight: 900 !important;
    line-height: 1.6rem;
    text-transform: uppercase;
}

.upsell_setting_container .removeButtonWrapper {
    width: 100%;
    margin: 20px 0;
}

.upsell_setting_container .removeButtonWrapper button.remove_upsell_block {
    background: transparent;
    width: 100%;
    border: 1px solid red;
    color: red;
    padding: 10px;
    font-size: 12px;
    border-radius: 5px;
}

.action_needed.banner_wrapper {
    margin-bottom: 50px;
}

.upsell_setting_container .Polaris-Page__Content .Polaris-Layout {
    align-items: unset !important;
}

.upsell_setting_container .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section.Polaris-Layout__Section--secondary .side_bar_container.Polaris-Card {
    height: 100%;
    position: relative;
    padding-bottom: 70px;
}

.upsell_setting_container .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section.Polaris-Layout__Section--secondary .side_bar_container.Polaris-Card .form_field_wrapper .field_row.navi.Polaris-Card__Section:last-child {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
}

.main_header_content>div.status_text_wrapper {
    background: #ddd;
    padding: 8px 18px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
}

.upsell_setting_container .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section.Polaris-Layout__Section--secondary .side_bar_container.Polaris-Card .form_field_wrapper {
    max-height: calc(100vh - 265px);
    overflow-y: auto;
}

.action_needed.banner_wrapper button {
    background: transparent;
}

a.enable_post_checkout_button {
    background: transparent;
    text-decoration: none;
    border: 1px solid #e1b878;
    padding: 5px 15px 3px;
    border-radius: 3px;
    color: #000;
    cursor: pointer;
    margin-top: 15px;
    display: flex;
    width: fit-content;
    text-align: center;
    align-items: center;
    justify-content: center;
}

button.changeDeskMobileView {
    background: transparent;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    padding: 3px 10px;
}

.for_variant_container {
    align-items: center;
    display: flex;
    gap: 5px;
    margin-top: 6px;
}

.variants_setting_card {
    background: #d9d7d736;
    padding: 8px;
    margin-bottom: 18px;
}

.for_variant_container img {
    max-width: 50px;
    height: auto;
}

.Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop {
    overflow: hidden;
}