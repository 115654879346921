#chargezen_gift_card_container #chargezen-modal {
    width: 100%;
    max-width: calc(100% - 40px);
    padding: 30px 24px;
    border: 2px solid #000;
    border-radius: 32px;
    background-color: #f7f1f9;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media screen and (min-width: 750px) {
    #chargezen_gift_card_container #chargezen-modal {
        max-width: 496px;
        padding: 48px 40px;
    }

    #chargezen_gift_card_container .chargezen-heading {
        margin-bottom: 24px;
        font-size: 3.6rem;
    }
}

#gvloBulkModal,
#chargezen-modal {
    -ms-overflow-style: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: 95vh;
    max-width: 450px;
    overflow-y: auto;
    padding: 24px;
    position: relative;
    scrollbar-width: none;
}

#chargezen_gift_card_container .close-btn {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
}

#chargezen_gift_card_container .close-btn {
    display: flex !important;
    top: 30px !important;
    right: 30px !important;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
}

.chargezen-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

#chargezen_gift_card_container .chargezen-carousel-container .image-container,
#chargezen_gift_card_container .chargezen-carousel-container .image-container .chargezen-img-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
}

#chargezen_gift_card_container .chargezen-carousel-container .image-container .chargezen-img-container {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    max-height: 400px;
    max-width: 100%;
    min-width: 100%;
    position: relative;
    -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
}

#chargezen_gift_card_container .chargezen-gallery img {
    width: 352px !important;
}

@media screen and (min-width: 750px) {
    #chargezen_gift_card_container .chargezen-gallery .chargezen-carousel-container {
        height: 236px;
    }

    #chargezen_gift_card_container .chargezen-gallery {
        margin-bottom: 24px;
    }
}

#chargezen_gift_card_container .chargezen-heading {
    margin: 0 0 24px;
    font-size: 34px;
    font-weight: normal;
    line-height: 1;
    text-align: left;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle {
    margin-bottom: 24px;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle label {
    margin: 0 0 8px;
    padding-left: 28px;
    color: #000;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer {
    flex-direction: row-reverse;
    align-items: flex-start;
    gap: 0;
    padding: 0;
    border: none;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer span {
    width: 100%;
    font-family: "Mier A", helvetica, arial, sans-serif;
    font-size: 1.6rem;
    line-height: 2.4rem;
}

#chargezen_gift_card_container .chargezen-form-content input {
    padding: 8px 24px;
    border: 2px solid #000;
    font-family: "Mier A", helvetica, arial, sans-serif;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer button[role="switch"] {
    flex-shrink: 0 !important;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    transform: translateY(-26px);
    border: 2px solid #000;
    background-color: rgba(0, 0, 0, 0);
    position: relative;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer input:checked+button[role="switch"]::after {
    background-color: #000;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer button[role="switch"]::after {
    left: 2px !important;
    width: 12px;
    height: 12px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
}

/* font-family: "Avenir Light";
font-family: "Avenir-Black";
font-family: "Avenir-Medium";
font-family: "Avenir Black";
font-family: "Avenir Heavy"; */

* {
    box-sizing: border-box;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle>label {
    font-size: 16px;
    font-family: "Avenir-Medium";
    margin: 0 0 8px;
    display: block;
}

#chargezen_gift_card_container *,
#chargezen_gift_card_container :after,
#chargezen_gift_card_container :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

#chargezen_gift_card_container *,
#chargezen_gift_card_container :after,
#chargezen_gift_card_container :before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0px;
}

#chargezen_gift_card_container .chargezen-gallery img {
    height: 236px;
    object-fit: cover;
}

div#chargezen_gift_card_container {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-scroll-chaining: none;
    align-items: center;
    /* background: -webkit-gradient( linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.5)) ); */
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    justify-content: center;
    left: 0;
    margin: 0;
    overscroll-behavior: contain;
    padding: 0;
    /* position: fixed; */
    top: 0;
    width: 100%;
    z-index: 99999999;
    z-index: 999999999;
    display: flex;
}

#chargezen_gift_card_container #chargezen-modal::-webkit-scrollbar {
    width: 0;
}

.chargezen-form-content .chargezen-heading {
    font-family: "Avenir Heavy" !important;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer span,
form.chargezen-form .chargezen-field>label {
    font-size: 16px;
    line-height: 100%;
    font-family: "Avenir Light";
    color: #000;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer {
    display: flex;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer button {
    border-radius: 9999px;
}

#chargezen_gift_card_container .chargezen-form-content .chargezen-toggle-disclaimer button[role="switch"]:before {
    content: "";
    left: 2px !important;
    width: 12px;
    height: 12px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    background-color: #000;
    display: block;
    border-radius: 100%;
    position: absolute;
    top: 2px;
}

form.chargezen-form .chargezen-field>label {
    display: block;
    width: 100%;
    margin: 0 0 8px;
    font-family: 'Avenir';
}

a.gift_card_custom_button {
    margin: 0 0 24px;
    display: flex;
    width: 100%;
    min-height: 48px;
    background: #c5ff89;
    box-shadow: inset 0 0 0 1.5px #000;
    border-radius: 48px;
    color: #000;
    font-size: 16px;
    font-family: 'Avenir';
    text-transform: uppercase;
    text-decoration: none;
    align-items: center;
    justify-content: center;
}

.chargezen-custom_paragraph {
    margin: 0 0 24px;
    font-size: 16px;
    text-align: left;
    font-family: 'Avenir';
    line-height: 24px;
    color: #000;
}

form.chargezen-form .chargezen-field {
    margin: 0 0 12px;
}

form.chargezen-form .chargezen-field input,
form.chargezen-form .chargezen-field textarea {
    width: 100%;
    border-radius: 999px;
    height: 48px;
    padding: 12px 24px !important;
    border: 2px solid #000;
    font-family: "Avenir-Medium";
    resize: none;
    white-space: pre-wrap;
    overflow: hidden;
    color: #000;
}

form.chargezen-form button.chargezen-button {
    margin: 12px 0 0;
    width: 100%;
    min-height: 48px;
    background: #c5ff89;
    box-shadow: inset 0 0 0 0.5px #000;
    border-radius: 48px;
    color: #000;
    font-size: 16px;
    font-family: "Avenir Heavy";
    text-transform: uppercase;
}


h1.chargezen-heading {
    font-size: calc((clamp(34px, 3.7vw, 72px) * 84) / 100) !important;
}

h2.chargezen-heading {
    font-size: calc((clamp(30px, 3vw, 62px) * 84) / 100) !important;
}

h3.chargezen-heading {
    font-size: calc((clamp(24px, 2vw, 44px) * 84) / 100) !important;
}