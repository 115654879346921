.manage_apps_icon_wrapper {
    position: relative;
}

.manage_apps_icon_wrapper .manage_app_container {
    position: absolute;
    top: 28px;
    background-color: #011f2b;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid #dddddd45;
    width: 100%;
    min-width: 910px;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper {
    display: flex;
    gap: 0;
    min-width: 450px;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .left_section_wrapper ul {
    list-style: none;
    display: flex;
    flex-flow: column;
    gap: 10px;
    margin: 0;
    padding-top: 39px;
    padding-bottom: 40px;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .left_section_wrapper ul li.manage_app_list_wrapper {
    cursor: pointer;
    font-weight: 600;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .left_section_wrapper ul li.manage_app_list_wrapper:hover {
    color: #407af1;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper>.left_section_wrapper {
    width: 100%;
    max-width: 270px;
    padding-right: 30px;
    border-right: 1px solid rgba(251, 251, 251, 0.2);
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper {
    width: 100%;
    max-width: calc(100% - 270px);
    padding: 40px 30px;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper .title_icon_wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 0 0 30px;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper .title_icon_wrapper h1 {
    color: #fff;
    opacity: 0.7;
    font-weight: bold;
    font-size: 25px;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper .title_icon_wrapper svg.lucide.lucide-box {
    width: 50px;
    height: 50px;
    opacity: 0.7;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper .title_icon_wrapper svg.lucide.lucide-box path {
    stroke: #fff;
    strokeWidth: 1px;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper p.desc_wrapper {
    font-size: 16px;
    margin: 0 0 15px;
    display: block;
    color: #fff;
    line-height: 24px;
    font-weight: 400;
    max-width: 96%;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper button.activate_deactivate_button {
    background: #2d7cf5;
    width: 100%;
    max-width: 180px;
    min-height: 40px;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    border-radius: 4px;
}

li.manage_app_list_wrapper.activate {
    color: #2d7cf5;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper .close_icon_wrapper {
    position: absolute;
    right: 10px;
    top: 25px;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper .close_icon_wrapper svg,
.close_icon_wrapper svg path {
    color: #fff !important;
    fill: #fff !important;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper .title_icon_wrapper>span.Polaris-Icon svg.Polaris-Icon__Svg {
    fill: #fff;
    stroke: #fff;
    margin-top: -3px;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper .title_icon_wrapper svg {
    fill: #fff;
    color: #fff !important;
    stroke: #fff;
}

.manage_apps_icon_wrapper .manage_app_container .manage_app_container_wrapper .right_section_wrapper .title_icon_wrapper span.Polaris-Icon {
    margin: 0 !important;
    width: fit-content !important;
}