.customize .Polaris-Card {
    box-shadow: none;
    border: 1px solid #e1e3e5;
}

.customize .Polaris-Label__Text {
    color: #000;
}

.customize .card-box {
    font-size: 1.2rem;
    color: #a2adb8;
}

.email-triggers .card-box .blue-title,
.customize .card-box .blue-title {
    background: #007eff;
    padding: 1.5rem 2.5rem;
    font-size: 1.5rem;
    font-family: "Helvetica";
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    margin: -2rem -2rem 2rem;
}

.email-triggers .card-box .description {
    margin-bottom: 20px;
    color: #777;
}

.email-triggers .card-box .form-holder {
    width: 90%;
}

.email-triggers .card-box .form-holder>div {
    margin-bottom: 20px;
}

.customize .card-box .holder {
    margin-top: 2em;
}

.customize .card-box h3 {
    font-size: 1.5rem;
    font-family: "Helvetica";
    margin-bottom: 1.6rem;
    color: #000;
    font-weight: 500;
}

.customize .card-box .color-holder input[type="text"] {
    width: 30rem;
}

.cards-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.email-triggers .cards-list {
    margin-top: 20px;
}

.email-triggers .cards-list .Polaris-Card {
    border: 1px solid #c4cdd5;
    box-shadow: none;
}

.email-triggers .cards-list .card-footer {
    border: none;
    padding-bottom: 5px;
}

.cards-list .Polaris-Card {
    width: 49%;
    margin: 0 !important;
}

.cards-list .Polaris-Card:nth-child(n+3) {
    margin: 1.5rem 0 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-2 {
    margin-top: 1rem;
}

.mt-3 {
    margin-top: 1.2rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.mb-2 {
    margin-bottom: 1rem;
}

.mb-3 {
    margin-bottom: 1.2rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.card-header {
    position: relative;
    margin-bottom: 1rem;
}

.card-header .button-holder {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.button-holder button {
    min-height: 2rem;
    padding: 0.2rem 1rem !important;
}

.button-holder div+button,
.button-holder button+button {
    margin-left: 0.7rem;
}

.button-holder button span {
    font-size: 1.2rem;
}

.card-header h2 {
    font-size: 1.6rem;
    font-family: "Helvetica";
    line-height: 1.4;
    font-weight: 700;
    color: #000;
    display: inline-block;
}

.card-header h2 small {
    font-weight: 400;
    font-size: 1.4rem;
    font-family: "Helvetica";
    position: relative;
}

#activated h2 small:after {
    content: "";
    position: absolute;
    right: -20px;
    top: 4px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background: #36b700;
    border: 3px solid #bae7a8;
}

#not-activated h2 small:after {
    content: "";
    position: absolute;
    right: -20px;
    top: 4px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background: #f3bb01;
    border: 3px solid #fae59c;
}

.card-body {
    padding: 1rem 0;
    font-size: 1.2rem;
    font-family: "Helvetica";
    min-height: 7rem;
}

.email-triggers .cards-list .card-body {
    height: 100px;
    color: #888;
}

.email-triggers .cards-list .card-body p strong {
    color: #000;
}

.other-list .card-body {
    min-height: 4rem;
}

.card-footer {
    border-top: 1px solid #dfe4e8;
    margin: 0 0 -1rem;
    padding: 1rem 0 0;
}

#warning-outline {
    border-color: #fae59c;
    color: #f4c329;
}

#danger-outline {
    border-color: #ff8888;
    color: #ff1a1d;
}

#success-outline {
    border-color: #bbe7a9;
    color: #4fc026;
}

.preview-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.prepare-to-launch {
    background: #fff;
    padding: 2rem 4rem 8rem;
    overflow: hidden;
}

.prepare-to-launch .top-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
}

.prepare-to-launch .top-title h3 {
    color: #ff0100;
    font-size: 2.8rem;
    font-family: "Helvetica";
    font-weight: bold;
    margin-left: 20px;
}

.prepare-to-launch .top-title .ico {
    width: 60px;
    height: 60px;
}

.prepare-to-launch .top-title .ico img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prepare-to-launch .cta {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 7rem;
}

.prepare-to-launch .cta:after {
    content: "";
    position: absolute;
    width: 450px;
    height: 450px;
    border-radius: 100%;
    background: #007eff;
    top: -43px;
    right: -190px;
}

.prepare-to-launch .cta .left-section,
.prepare-to-launch .cta .right-section {
    width: 50%;
    font-size: 1.8rem;
    line-height: 1.4;
    color: #657582;
}

.prepare-to-launch .cta .left-section h3 {
    font-size: 3.5rem;
    line-height: 1.2;
    font-weight: bold;
    font-family: "Helvetica";
    margin-bottom: 1.5rem;
    color: #000;
}

.prepare-to-launch .cta .image-holder {
    width: 85%;
    height: auto;
    z-index: 2;
    position: relative;
    margin: auto;
}

.prepare-to-launch .cta .image-holder img {
    width: 100%;
    height: auto;
}

.prepare-to-launch h4,
.how-it-works h4,
.more-points h4 {
    font-size: 2.4rem;
    line-height: 1.2;
    font-weight: bold;
    font-family: "Helvetica";
    margin-bottom: 2rem;
    color: #000;
    text-align: center;
}

.more-points {
    background: #fff;
    padding: 6rem 4rem;
    margin-top: -2rem;
}

.rewards-list,
.how-it-works .box-holder,
.more-points .box-holder {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
}

.how-it-works .box-holder .box {
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.4;
    margin: 2rem 2.5rem;
}

.more-points .box-holder .box {
    text-align: center;
    border: 1px solid #e8e8e8;
    flex: 1 1;
    margin: 0 15px;
    border-radius: 6px;
    box-shadow: 0 2px 5px #ddd;
    padding: 25px;
    font-size: 1.4rem;
    color: #000;
}

.reward-list {
    text-align: center;
    border: 1px solid #e8e8e8;
    flex: 1;
    margin: 0 15px;
    border-radius: 6px;
    box-shadow: 0 2px 5px #ddd;
    padding: 25px;
    font-size: 1.4rem;
    color: #000;
}

.reward-list .text-muted {
    color: #6e7d8a;
    margin-bottom: 5px;
}

.how-it-works .ico,
.reward-list .ico,
.more-points .ico {
    width: 70px;
    height: 70px;
    margin: 0 auto 15px;
}

.how-it-works .ico img,
.reward-list .ico img,
.more-points .ico img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.more-points h5 {
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 5px;
}

.reward-list h5 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
}

.how-it-works {
    background: #ecf2f8;
    padding: 6rem 4rem;
    margin-top: -2rem;
}

.campaign-search {
    padding: 5px 0 10px;
}

.campaign-search input {
    width: 30rem;
}

/* The switch - the box around the slider */
.switch-status {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-right: 0;
}

/* Hide default HTML checkbox */
.switch-status input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider-status {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E4E5E7;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.slider-status:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 1px;
    background-color: #6D7175;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider-status {
    background-color: #007eff;
}

input:focus+.slider-status {
    box-shadow: 0 0 1px #007eff;
}

input:checked+.slider-status:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: #fff;
}

.filters .Polaris-Collapsible {
    opacity: 1;
}

.filters button .Polaris-Stack__Item {
    margin: 0;
}

.filters button .Polaris-Icon {
    color: #000;
}

.filters button .Polaris-Button__Content {
    line-height: 1.4;
}

.filters button .Polaris-Button__Text {
    text-decoration: none !important;
}

.filters .filter-list+.filter-list {
    margin-top: 10px;
}

.filters .filter-list .Polaris-TextContainer {
    font-size: 14px;
}

.filters .filter-list .Polaris-Select {
    width: 15rem;
}

.filters .filter-list .Polaris-TextField {
    width: 6rem;
}

.filters .filter-list .Polaris-TextField input {
    text-align: center;
}

.filters .filter-list button {
    width: 12rem;
    margin-top: 10px;
}

.loyalty-performance {
    margin-top: 30px;
}

.loyalty-performance .cards-list .Polaris-Card {
    width: 23.5%;
    margin: 0 !important;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
}

.loyalty-performance .cards-list .Polaris-Card:nth-child(n+5) {
    margin-top: 15px !important;
}

.loyalty-performance h3 {
    font-size: 2.1rem;
    font-weight: normal;
    margin: 20px 0 15px;
}

.loyalty-performance .Polaris-TextContainer {
    color: #4D506A;
    font-size: 1.4rem;
}

.loyalty-performance .Polaris-TextContainer strong {
    color: #212B36;
    font-size: 2.2rem;
    margin-top: 10px;
    display: block;
    font-weight: 800;
}

.bottom-section-customer {
    margin-top: 20px;
}

.bottom-section-customer .cards-list .Polaris-Card {
    width: 48%;
}

.total-rev-chart {
    background: #fff;
    padding: 30px 0;
    margin-top: 30px;
    box-shadow: 0px 16px 24px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 0px 1px rgb(0 0 0 / 4%);
    border-radius: 6px;
}

.Polaris-Modal-Dialog__Modal .Polaris-Modal__Body {
    overflow: auto;
}

.Polaris-Modal-Dialog__Container .Polaris-Modal-Section {
    padding: 10px 3rem;
}

.input-group {
    position: relative;
}

.input-group .icon-before,
.input-group .icon-after {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #C4CDD5;
    z-index: 999;
    height: 35px;
    padding: 6px 15px;
    text-align: center;
    border-radius: 4px 0 0 4px;
}

.input-group .icon-after {
    right: 0;
    left: auto;
    border-radius: 0 4px 4px 0;
}

.with-helper-text>div {
    display: flex;
    flex-direction: column;
}

.with-helper-text .Polaris-Labelled__LabelWrapper {
    margin: 0;
}

.with-helper-text .Polaris-Connected,
.with-helper-text .Polaris-Select {
    order: 3;
}

.with-helper-text .Polaris-Labelled__HelpText {
    order: 2;
    font-size: 1.2rem;
    margin: 0 0 0.5rem;
}

.top-section {
    text-align: center;
    margin-bottom: 3rem;
    padding-top: 2rem;
}

.top-section img {
    margin-bottom: 2rem;
}

.top-section p {
    text-align: left;
}

.with-premium-label-tag .Polaris-Label__Text {
    position: relative;
}

.with-premium-label-tag .Polaris-Label__Text:after {
    content: "Premium";
    position: absolute;
    left: 105%;
    right: auto;
    top: 1px;
    background: #007eff;
    padding: 3px 10px 4px;
    white-space: nowrap;
    color: #fff;
    font-size: 1rem;
    line-height: 1;
    border-radius: 4px;
    text-transform: uppercase;
}

.chargezen-summary-wrapper .summary_width_trans.full_width .Polaris-Page__Content {
    margin: 0;
}

.summary_width_trans .Polaris-Page {
    margin: 0;
    padding: 0;
}

.summary_width_trans .Polaris-Frame {
    min-height: auto !important;
    background-color: inherit !important;
}


.Polaris-Card__Section .content_wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 17px;
    align-items: center;
    border-bottom: 1px solid #ececec;
    padding-bottom: 20px;
    width: 100%;
}

.Polaris-Card__Section .footer_text.link_text {
    text-align: end;
    padding: 20px 0 0;
    font-size: 17px;
    color: #017efa;
}


.chargezen-summary-wrapper .full_width .Polaris-Stack__Item {
    margin: 0;
}

.chargezen-summary-wrapper .full_width .Polaris-Stack__Item .Polaris-Button.Polaris-Button--plain {
    padding: 0 !important;
}

.chargezen-summary-wrapper .full_width .Polaris-Stack__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content {
    margin: 0;
}

.chargezen-summary-wrapper .full_width .Polaris-Stack__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text {
    margin: 0;
}

.chargezen-summary-wrapper .full_width .Polaris-Stack__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup {
    margin: 0;
}

.chargezen-summary-wrapper .full_width .Polaris-Card__Header .Polaris-Stack.Polaris-Stack--alignmentBaseline {
    margin: 0;
}

.chargezen-summary-wrapper .full_width .Polaris-Card__Header .Polaris-Stack.Polaris-Stack--alignmentBaseline {
    align-items: center;
}

@media (max-width:1600px) {
    .chargezen-summary-wrapper .full_width .Polaris-Stack.Polaris-Stack--alignmentBaseline .Polaris-Stack__Item:last-child {
        display: flex;
        justify-content: end;
    }

    .chargezen-summary-wrapper .full_width .Polaris-Stack.Polaris-Stack--alignmentBaseline .Polaris-Stack__Item:last-child .Polaris-Button__Content {
        margin-right: 17px !important;
    }

    .chargezen-summary-wrapper .full_width .Polaris-Stack.Polaris-Stack--alignmentBaseline .Polaris-Stack__Item:last-child .Polaris-Button__Content .Polaris-Button {
        text-align: center !important;
        justify-content: center !important;
        display: inline-flex;
    }

    .chargezen-summary-wrapper .full_width .Polaris-Stack.Polaris-Stack--alignmentBaseline .Polaris-Stack__Item:last-child .Polaris-Button__Content .Polaris-Button .Polaris-Button__Content {
        margin: 0 !important;
    }
}

@media(max-width:1439px) {
    .chargezen-summary-wrapper .full_width .Polaris-Stack.Polaris-Stack--alignmentBaseline .Polaris-Stack__Item:last-child .Polaris-Button__Content {
        margin-right: 17px !important;
    }
}


.customize .card-header {
    display: flex;
    justify-content: space-between;
}

.customize .action_button_wrapper {
    display: flex;
    gap: 8px;
}

.customize .action_button {
    border-radius: 2px;
    border: 0;
    display: inline-flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 350;
    line-height: 12px;
}

.customize .action_button.sucess_button {
    background: #27AE60;
}

.customize .action_button.warning_button {
    background: #E03030;
}


.card-box .rewards_settings.main-title {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: start;
    padding-bottom: 14px;
    border-bottom: 1px solid #d0c8c8;
    margin-bottom: 23px;
}

.card-box .rewards_settings.main-title .heading_icons {
    background: #017EFA;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-box .rewards_settings.main-title .title_wrapper {
    color: #001219;
    font-family: 'Avenir';
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 33px;
}


.image_selector_main .thumbnail_image {
    display: flex;
    overflow-x: auto;
    margin-top: 1.6rem;
    flex-wrap: wrap;
}

.image_selector_main .thumbnail_image img {
    width: 100%;
    max-width: 80px;
    height: 54px;
    -o-object-fit: cover;
    object-fit: cover;
    border: 2px solid #dfe3e8;
    margin-right: 10px;
    min-width: 80px;
}

.image_selector_main .thumbnail_image img.active {
    border-color: #008060 !important;
}

.customize .button_wrapper {
    padding-top: 20px;
}


.work_preview_image {
    max-width: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 25px;
    align-items: center;
}

.work_preview_image img {
    max-width: 55px;
    width: 100%;
}

.work_preview_image .change_image {
    border: 1px solid green;
    background: transparent;
    color: #000;
    font-family: 'Avenir';
    padding: 5px 15px;
    font-size: 13px;
}

.customize .how_it_works .Polaris-DropZone-FileUpload img {
    width: 40px;
}

.referrals_main_container {
    display: flex;
    flex-flow: column;
    gap: 15px;
}


.loyalty_tiers_wrapper {
    margin-bottom: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.loyalty_tiers_wrapper:first-child {
    border: 0;
    padding-top: 0;
}


.tiers_wrapper_main {
    display: flex;
    flex-flow: column;
    gap: 15px;
    margin: 15px 0;
}

.tiers_wrapper_main .tier_wrapper {
    font-size: 15px;
    font-family: 'Avenir';
    color: #000;
    display: flex;
    flex-flow: column;
    gap: 8px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.tiers_wrapper_main .tier_wrapper .manage_benefits_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.primary_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: end;
}

.points_program_emal_wrapper .Polaris-Stack.Polaris-Stack--vertical {
    margin: 0;
    display: flex;
    flex-flow: column;
    gap: 22px;
}

.points_program_emal_wrapper .Polaris-Stack.Polaris-Stack--vertical .Polaris-Stack__Item .Polaris-Stack.Polaris-Stack--distributionEqualSpacing {
    margin: 0;
}

.points_program_emal_wrapper .Polaris-Stack.Polaris-Stack--vertical .Polaris-Stack__Item .Polaris-Stack.Polaris-Stack--distributionEqualSpacing .Polaris-Button__Content {
    display: block;
    margin: 0;
    background: transparent;
}

.points_program_emal_wrapper .Polaris-Stack.Polaris-Stack--vertical .Polaris-Stack__Item .Polaris-Button.Polaris-Button--plain.Polaris-Button--textAlignLeft {
    margin: 0;
    height: auto !important;
    line-height: normal !important;
    padding: 0 !important;
    background: transparent !important;
    min-height: unset;
}

.points_program_emal_wrapper .Polaris-Frame #AppFrameMain .Polaris-Card__Section.Polaris-Card__Section--subdued {
    padding-left: 0;
    padding-right: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

@media(max-width: 1700px) {
    .summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout {
        display: flex;
        flex-flow: row wrap;
        margin-top: 0;
    }

    .summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section {
        margin-left: 0;
        padding-left: 0;
        margin-top: 0;
    }

    .summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout>.Polaris-Layout__Section:first-child {
        max-width: 60%;
    }

    .summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout>.defauleLayoutSection {
        max-width: 40%;
    }

    .summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .points_program_emal_wrapper .Polaris-Layout .Polaris-Layout__Section {
        max-width: 100% !important;
    }

    .summary_main_div_wrapper.chargezen-summary-wrapper.point_program_tiers:nth-child(6) .Polaris-Page .Polaris-Page__Content .Polaris-Layout {
        margin: 0;
    }

    .summary_main_div_wrapper.chargezen-summary-wrapper.point_program_tiers:nth-child(6) .Polaris-Page .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section {
        max-width: 100%;
        margin-left: 0;
    }

}


@media(max-width: 1439px) {
    .summary_width_trans .Polaris-Layout>.Polaris-Layout__Section {
        width: 100%;
        max-width: 100%;
        padding-right: 0 !important;
        flex: 100%;
        min-width: unset;
        clear: both;
        display: inline-block;
        margin-top: 50px !important;
        margin-left: 0;
        padding-left: 0;
    }

    .summary_width_trans .Polaris-Layout .defauleLayoutSection {
        max-width: 100%;
    }

    .summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout>.Polaris-Layout__Section:first-child {
        max-width: 100%;
    }

    .summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout>.defauleLayoutSection {
        max-width: 100%;
        margin-top: 30px;
    }

    .summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout>.defauleLayoutSection>.Polaris-Layout__Section.Polaris-Layout__Section--secondary {
        padding-right: 0 !important;
    }

}

.referrals_main_container .Polaris-Frame {
    min-height: fit-content !important;
}

/* new sidebar with curvos portal */

.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view {
    flex-flow: column;
    gap: 0px;
    margin: 0;
}

.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view>.summary_main_div {
    max-width: 100%;
    flex: 100%;
    padding-right: 0;
}

.chargezen-summary-wrapper.detail_view>.summary_main_div>.summary_body_text {
    max-width: 100%;
    margin: 0;
}

.sidebar_summary_main_container {
    display: flex;
}

.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view:not(:last-child) {
    margin: 0 0 50px;
}

.sidebar_summary_main_container>.sidebar_summary_right_section {
    width: 100%;
    max-width: 18%;
    padding-right: 0px;
}

/* static summary content */
.sidebar_summary_main_container>.sidebar_summary_right_section {
    position: fixed;
    top: 70px;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section {
    width: 100%;
    max-width: 100%;
    padding-left: 21%;
    padding-right: 5px;
}

/* static summary content end */

.sidebar_summary_main_container>.sidebar_summary_right_section>.summary_side_nav_container {
    background: #fff;
    border-radius: 5px;
    height: 100%;
    min-height: 90vh;
}

.sidebar_summary_main_container>.sidebar_summary_right_section>.summary_side_nav_container ul.summary_list {
    padding: 10px;
    margin: 0;
    list-style: none;
}

.sidebar_summary_main_container>.sidebar_summary_right_section>.summary_side_nav_container ul.summary_list>li.list_item {
    display: flex;
    align-items: center;
    padding: 8px 6px;
    border-radius: 10px;
    cursor: pointer;
}

.sidebar_summary_main_container>.sidebar_summary_right_section>.summary_side_nav_container ul.summary_list>li.list_item>.icon_wrapper {
    margin: 0;
    padding: 0;
    border: 0;
    width: 18px;
    height: 18px;
}

.sidebar_summary_main_container>.sidebar_summary_right_section>.summary_side_nav_container ul.summary_list>li.list_item>.icon_wrapper svg {
    width: 18px;
    height: 18px;
    fill: #4a4a4a;
}

.sidebar_summary_main_container>.sidebar_summary_right_section>.summary_side_nav_container ul.summary_list>li.list_item>.list_title {
    font-weight: 400;
    font-family: Avenir;
    color: #4a4a4a;
    width: 100%;
    max-width: calc(100% - 20px);
    padding-left: 10px;
    font-size: 13px;
}

.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view>.summary_main_div {
    position: relative;
    padding-right: 130px;
}

.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view>.summary_main_div button.expand_view_button {
    margin-top: 8px;
}

.sidebar_summary_main_container .summary_main_div_wrapper.chargezen-summary-wrapper.point_program_tiers {
    margin: 0 0 50px;
    flex-flow: column;
    gap: 0px;
}

.sidebar_summary_main_container .summary_main_div_wrapper.chargezen-summary-wrapper.point_program_tiers .Polaris-Page-Header.Polaris-Page-Header--isSingleRow.Polaris-Page-Header--noBreadcrumbs.Polaris-Page-Header--mediumTitle {
    padding: 0 0 20px;
}

.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view .Polaris-Page-Header--noBreadcrumbs {
    padding: 0 0 20px;
}

.summary_main_div_wrapper.chargezen-summary-wrapper.point_program_tiers .summary_main_div {
    max-width: 100%;
    padding-right: 0;
    padding-right: 130px;
}

.summary_main_div_wrapper.chargezen-summary-wrapper.point_program_tiers .summary_main_div .summary_body_text {
    max-width: 100%;
}

.summary_main_div_wrapper.chargezen-summary-wrapper.point_program_tiers .summary_main_div button.expand_view_button {
    position: absolute;
    right: 0;
    top: 0;
}

.sidebar_summary_main_container>.sidebar_summary_right_section>.summary_side_nav_container ul.summary_list>li.list_item.active {
    background: #f8f8f8;
}

.sidebar_summary_main_container>.sidebar_summary_right_section>.summary_side_nav_container ul.summary_list {
    display: flex;
    flex-flow: column;
    gap: 9px;
}

.sidebar_summary_main_container>.sidebar_summary_main_left_section .summary_heading {
    font-weight: 900 !important;
    margin-bottom: 2px !important;
}

.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view .Polaris-Page-Header.Polaris-Page-Header--isSingleRow.Polaris-Page-Header--noBreadcrumbs {
    display: none;
}

.sidebar_summary.header_description {
    margin: 15px 0 2.5%;
    color: #4a4a4a;
}

.thumbnail_image_with_label {
    display: flex;
    flex-flow: column;
    grid-gap: 5px;
    gap: 0;
    margin-top: 20px;
}

.thumbnail_image_with_label h5.custom_image_label {
    color: #000;
    font-size: 14px;
}

button.add_more_wr_content {
    border-radius: 3px !important;
    background: #fff;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 3.6rem;
    min-width: 3.6rem;
    margin: 0;
    padding: .7rem 1.6rem;
    border: .1rem solid #c4cdd5;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, .05);
    border-radius: 3px;
    line-height: 1;
    color: #212b36;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    margin-top: 10px
}

.way_to_redeem_content_wrapper {
    margin-top: 16px;
}


.heading_title .card_title_desc {
    position: unset !important;
}


@media(mx-width:1365px) {

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .tabButtons {
        overflow-x: auto;
    }

    .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .tabButtons>button.Polaris-Button {
        min-width: 100px;
    }

    .Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item.Polaris-Stack__Item--fill,
    .Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item.Polaris-Stack__Item--fill * {
        flex: unset !important;
        word-break: break-all !important;
        white-space: unset !important;
    }

}
