.create_workflow_wrapper .events_wrapper {
    display: flex;
    flex-flow: column;
    gap: 15px;
}

.create_workflow_wrapper .condtions_wrapper {
    margin-top: 20px;
}

.text_flex_wrapper label.Polaris-Choice {
    width: 100%;
}

.text_flex_wrapper {
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.selected_plan_wrapper {
    display: flex;
    gap: 15px;
    margin-bottom: 8px;
}

.selectedPlan {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #ddd;
    padding: 2px 8px;
    border-radius: 4px;
}

.summary_width_trans.summary_right_section .Polaris-Layout__Section {
    padding: 0 !important;
    margin: 0 !important;
}

.summary_width_trans.summary_right_section .Polaris-Layout__Section:not(:last-child) {
    margin: 0 0 20px !important;
}


.condtions_wrapper .Polaris-Card:nth-child(1) .Polaris-Card__Section {
    padding: 0;
}

.condtions_wrapper .Polaris-Card:nth-child(1) .Polaris-Card__Section .text_flex_wrapper {
    gap: 0;
}

.condtions_wrapper .Polaris-Card:nth-child(1) .Polaris-Card__Section>div>div {
    padding: 20px;
}

.condtions_wrapper .Polaris-Card:nth-child(1) .Polaris-Card__Section>div>div:not(:last-child) {
    border-bottom: 1px solid #e1e3e5;
}

.condtions_wrapper .Polaris-Card:nth-child(1) .Polaris-Card__Section>div>div>.searchPlanWrapper:not(:last-child) {
    border-bottom: 1px solid #e1e3e5;
}

.condtions_wrapper .Polaris-Card:nth-child(1) .Polaris-Card__Section>div>div>.searchPlanWrapper {
    padding: 20px 20px;
}

.condtions_wrapper .Polaris-Card:nth-child(1) .Polaris-Card__Section>div>div>.searchPlanWrapper:first-child {
    padding-top: 0;
}

.condtions_wrapper .Polaris-Card:nth-child(1) .Polaris-Card__Section>div>div>.searchPlanWrapper:last-child {
    padding-bottom: 0;
}

.condtions_wrapper .Polaris-Card:nth-child(1) .Polaris-Card__Section>div>div.selected_conditions_wrapper {
    padding-left: 0;
    padding-right: 0;
}

.chargezen-summary-wrapper .summary_width_trans>.Polaris-Layout {
    gap: 20px;
    margin: 0 !important;
}

.searchPlanWrapper.check_product_title_wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
}

.selected_plan_wrapper {
    flex-wrap: wrap;
    margin: 0 0 20px;
}

.condtions_wrapper .Polaris-Card:first-child .Polaris-Card__Section>div>div.selected_conditions_wrapper>div {
    padding-left: 20px;
    padding-right: 20px;
}

.condtions_wrapper .Polaris-Card:first-child .Polaris-Card__Section>div>div.selected_conditions_wrapper .searchPlanWrapper.check_product_title_wrapper {
    margin: 0 0 20px;
}

.Polaris-Popover__Pane.Polaris-Scrollable.Polaris-Scrollable--vertical {
    overflow: auto !important;
}

.searchPlanWrapper {
    position: relative;
}

.searchPlanWrapper .removeConditionButtonWrapper {
    position: absolute;
    right: 30px;
    top: 0px;
}


.selected_conditions_wrapper .searchPlanWrapper:not(:first-child) .removeConditionButtonWrapper {
    top: 20px;
}

.selected_conditions_wrapper .searchPlanWrapper>div:not(:last-child) {
    max-width: 760px;
}

.selected_conditions_wrapper button.remove_condition_button {
    background: transparent;
    border: 2px solid #ddd;
    padding: 4px 6px;
    color: #ddd !important;
}


.chargezen-summary-wrapper .summary_width_trans .table.customer-subscription-tbl.workflow_table tr.Polaris-DataTable__TableRow th.Polaris-DataTable__Cell--firstColumn {
    white-space: nowrap;
    min-width: 111px;
}

.chargezen-summary-wrapper .summary_width_trans .table.customer-subscription-tbl.workflow_table tr.Polaris-DataTable__TableRow th.Polaris-DataTable__Cell--firstColumn+td.Polaris-DataTable__Cell.Polaris-DataTable__Cell--verticalAlignTop {
    white-space: unset;
    min-width: 530px;
}

.searchPlanWrapper.selected_conditions_wrapper h3 {
    margin-bottom: 25px;
}

.update_billing_hour_button_wrapper {
    margin: 10px 0;
}

.chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack.Polaris-Stack--alignmentBaseline {
    flex-flow: row;
    margin: 0;
}

.chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item:last-child {
    margin: 0;
    width: 100%;
    max-width: calc(100% - 213px);
}

.chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item.Polaris-Stack__Item--fill:first-child {
    width: 100%;
    margin: 0;
    max-width: 213px;
    white-space: nowrap;
}

.chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item:last-child .Polaris-ButtonGroup {
    flex-flow: row;
    white-space: nowrap;
    overflow-x: auto;
    justify-content: flex-end;
}

.chargezen-summary-wrapper .summary_width_trans .Polaris-Card__Header .Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item:last-child .Polaris-ButtonGroup>.Polaris-ButtonGroup__Item {
    margin: 0;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup {
    justify-content: flex-end;
}

.Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill+.Polaris-Stack__Item .Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .Polaris-ButtonGroup span.Polaris-Button__Text {
    font-size: 15px;
    flex-wrap: wrap;
}

.summary_width_trans.summary_right_section .heading_title {
    font-size: 19px !important;
    max-width: fit-content;
}

.Polaris-Page__Content .Polaris-Layout__Section:first-child {
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
}

.add_action_button {
    display: flex;
    flex-flow: column;
    gap: 30px;
}

.add_action_button button.remove_condition_button {
    background: 0 0;
    border: 2px solid #ddd;
    padding: 4px 6px;
    color: #ddd !important;
    margin-top: -12px;
}

.create_workflow_wrapper .defauleLayoutSection.sdebar-right-cstm {
    max-width: 28% !important;
    width: 100% !important;
}

.table.customer-subscription-tbl.workflow_table th,
.table.customer-subscription-tbl.workflow_table td {
    padding: 16px !important;
    text-align: left !important;
}

.table.customer-subscription-tbl.workflow_table th:first-child,
.table.customer-subscription-tbl.workflow_table td:first-child {
    width: 40%;
}

.table.customer-subscription-tbl.workflow_table th:nth-child(3),
.table.customer-subscription-tbl.workflow_table td:nth-child(3) {
    width: 12%;
}

.table.customer-subscription-tbl.workflow_table td:nth-child(2),
.table.customer-subscription-tbl.workflow_table th:nth-child(2) {
    white-space: unset !important;
}

.workflow_summary_wrapper_container .Polaris-List__Item:last-child {
    word-break: break-all !important;
}

.workflow_status_wrapper .Polaris-Labelled__LabelWrapper label {
    font-weight: 600;
}

.Polaris-Card__Section .workflow_status_wrapper>div {
    margin-bottom: 20px;
}

.selected_conditions_wrapper .product-variant-picker-container {
    max-width: 100% !important;
}

.selected_conditions_wrapper .product-variant-picker-container {
    max-width: 100% !important;
}

.selected_conditions_wrapper .product_variant_preview_wrapper,
.product-variant-picker-container .product_variant_preview_wrapper {
    border: 1px solid #ddd;
    border-radius: 8px;
}

.selected_conditions_wrapper .product_variant_preview_wrapper .imgwrapper,
.product-variant-picker-container .product_variant_preview_wrapper .imgwrapper {
    margin: 10px 0 10px 10px !important;
}

.variants_tags_wrapper {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px;
}

.product_variant_preview_content {
    border: 1px solid #ddd;
    border-radius: 8px;
}

.product_variant_preview_content .product_variant_preview_wrapper {
    border: 0 !important;
}

.swap_product_swap_quantity_wrapper {
    display: flex;
    flex-flow: column;
    margin: 16px 0;
}

.swap_product_quantity_discount_container h4 {
    font-size: 1em;
    font-weight: 900;
    margin: 15px 0;
}