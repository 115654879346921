.card-box {
    padding: 0;
}

.card-box .rewards_settings.main-title {
    padding: 20px;
    margin: 0;
}

.card-box .zennportal_style_form_wrapper {
    padding: 20px;
}

.card-box .zennportal_style_form_wrapper .sub_category_field_container:not(:last-child) {
    margin: 0 0 20px;
}

.card-box .zennportal_style_form_wrapper .sub_category_field_container>h3 {
    margin: 0 0 19px;
    display: block;
    font-weight: bold;
    color: #000;
}

.card-box .zennportal_style_form_wrapper .sub_category_field_container>div:not(:last-child) {
    margin: 0 0 20px;
}

.card-box .zennportal_style_form_wrapper .sub_category_field_container>div {
    padding: 0 0 0 20px;
}

.card-box .zennportal_style_form_wrapper .sub_category_field_container>div label {
    display: block;
    margin: 0 0 5px;
    color: #000;
}

.card-box .zennportal_style_form_wrapper .category_field_wrapper:not(:last-child) {
    margin: 0 0 20px;
}

h1.customization_header {
    margin: 40px 0 20px;
    font-size: 20px;
    font-weight: bold;
}

.category_field_wrapper.color-field {
    display: flex;
    gap: 20px;
    align-items: flex-end;
}

.category_field_wrapper.color-field>div {
    min-width: 110px;
}

.manage_colors {
    display: flex;
    flex-flow: column;
    gap: 20px;
}


.category_field_wrapper.color-field>div:last-child .Polaris-Labelled__LabelWrapper label {
    display: none;
}

.manage_colors .sub_category_field_container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 20px;
}

.manage_colors .sub_category_field_container h3 {
    grid-column: 1/-1;
}

.manage_colors .sub_category_field_container>.category_field_wrapper {
    padding: 0 !important;
}

.manage_colors .sub_category_field_container>.category_field_wrapper {
    margin: 0 !important;
}

.manage_colors>.more_category_wrapper_main {
    width: 100%;
}

.manage_colors>div:not(.more_category_wrapper_main) {
    width: 100%;
    max-width: calc(100%/5 - 16px);
    flex: calc(100%/5 - 16px);
}


.manage_colors>.more_category_wrapper_main>.category_field_wrapper>.sub_category_field_container>.sub_category_field_container {
    padding: 0;
    display: block;
    width: 100%;
    max-width: 100%;
}

.manage_colors {
    flex-flow: unset;
    flex-wrap: wrap;
}

.Polaris-Card:nth-child(18)>.card-box>.zennportal_style_form_wrapper>.manage_colors>.more_category_wrapper_main>.category_field_wrapper>.sub_category_field_container {
    display: block;
}

.Polaris-Card:nth-child(18)>.card-box>.zennportal_style_form_wrapper>.manage_colors>.more_category_wrapper_main>.category_field_wrapper>.sub_category_field_container>.sub_category_field_container>.sub_category_field_container:last-child>.sub_category_field_container {
    order: 9;
    grid-column: 1/-1;
    padding: 0 !important;
}

.Polaris-Card:nth-child(18)>.card-box>.zennportal_style_form_wrapper>.manage_colors>.more_category_wrapper_main:first-child>.category_field_wrapper>.sub_category_field_container {
    display: grid;
}

.Polaris-Card:nth-child(18)>.card-box>.zennportal_style_form_wrapper>.manage_colors>.more_category_wrapper_main:nth-child(2)>.category_field_wrapper>.sub_category_field_container>.sub_category_field_container>.sub_category_field_container:nth-child(3)>.sub_category_field_container,
.Polaris-Card:nth-child(18)>.card-box>.zennportal_style_form_wrapper>.manage_colors>.more_category_wrapper_main:nth-child(2)>.category_field_wrapper>.sub_category_field_container>.sub_category_field_container>.sub_category_field_container:nth-child(4)>.sub_category_field_container {
    order: 9;
    grid-column: 1/-1;
    padding: 0;
}