.product_variant_picker .productWrapper .imgwrapper img {
    width: 100%;
    max-height: 40px;
    height: auto;
    object-fit: contain;
    border: 1px solid #ddd;
}

.product_variant_picker .productWrapper span.imgwrapper {
    height: 40px;
    width: 40px;
    display: block;
}

.product_variant_picker .productWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: 7px;
    justify-content: space-between;
    width: 100%;
}

.productWrapper span {
    font-family: 'Avenir';
    font-weight: 450;
    font-size: 13px;
}

.product-variant-picker-container .variantsContainer {
    display: flex;
    flex-flow: column;
}

.product-variant-picker-container .variantsContainer .variantRow {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 5px 10px 31px;
    width: 100%;
}

ul.segments_lists {
    overflow: auto;
    height: 100%;
}

.product-variant-picker-container .product-search .product_searchProduct {
    margin: 0 0 15px;
    display: flex;
    align-items: center;
}

.product-variant-picker-container {
    margin: 0 0 15px;
}

.product-variant-picker-container .product_searchProduct {
    display: flex;
    align-items: center;
}

.product-variant-picker-container .product_searchProduct .product_search_input {
    width: 100%;
    max-width: calc(100% - 67px);
    position: relative;
    padding-right: 15px;
}

.product-variant-picker-container .product_searchProduct .product_search_input span.Polaris-Icon {
    position: absolute;
    width: 15px;
    height: 15px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 11px;
}

.product-variant-picker-container .product_searchProduct .product_search_input input {
    width: 100%;
    background-color: rgba(253, 253, 253, 1);
    border: .1rem solid var(--p-border, #c4cdd5);
    border-top-color: #c4cdd5;
    border-radius: 0;
    height: 36px;
    padding-left: 40px;
    color: #91a0b3;
    font-weight: 300;
}

.product-variant-picker-container .product_searchProduct .product_search_input input::placeholder {
    color: #91a0b3;
    font-weight: 300;
}

.product-variant-picker-container .product_searchProduct button.browse_product_button {
    width: 100%;
    max-width: 67px;
    min-height: 36px;
    background: transparent;
    border: 0;
    border-radius: 5px;
    box-shadow: inset 0 -.0625rem 0 #b5b5b5, inset -.0625rem 0 0 #e3e3e3, inset .0625rem 0 0 #e3e3e3, inset 0 .0625rem 0 #e3e3e3;
    font-weight: 500;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container ul.segments_lists>li {
    padding: 0;
    border-bottom: 1px solid #ebebeb;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container ul.segments_lists>li>label.Polaris-Choice {
    padding: 8px 16px;
    border-bottom: 1px solid #ebebeb;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container ul.segments_lists>li>label.Polaris-Choice:hover {
    background: #f7f7f7;
}

.product-variant-picker-container .variantsContainer .variantRow {
    padding: 8px 16px 8px 48px;
}

.product-variant-picker-container .variantsContainer .variantRow:hover {
    background: #f7f7f7;
}

.segments_list_wrapper_container .variantsContainer .variantRow label.Polaris-Choice {
    width: 100%;
}

.segments_list_wrapper_container .variantsContainer .variantRow label.Polaris-Choice span.Polaris-Choice__Label {
    width: 100%;
}

.justify-between {
    justify-content: space-between !important;
}

.chargezen_search_model_footer .action_buttons {
    display: flex;
    gap: 8px;
    align-items: center;
}

.product_preview_row {
    display: flex;
    flex-flow: column;
    gap: 10px;
    width: 100%;
    margin-top: 18px;
}

.product_preview_row .product_variant_preview_wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
}

.product_preview_row .product_variant_preview_wrapper .imgwrapper {
    width: 60px;
    height: 60px;
}

.product_preview_row .product_variant_preview_wrapper .imgwrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 6px;
}

.product_preview_row .product_variant_preview_wrapper .pv_desc_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-size: 16px;
}

.product_preview_row .product_variant_preview_wrapper .pv_desc_container .edit_remove_wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}

.product_preview_row .product_variant_preview_wrapper .pv_desc_container .edit_remove_wrapper button.editbutton {
    color: #005bd3;
    border: 0;
    background: transparent;
    font-size: inherit;
}

.product_preview_row .product_variant_preview_wrapper .pv_desc_container .edit_remove_wrapperbutton.closeButton {
    background: transparent !important;
}

.product_preview_row .product_variant_preview_wrapper .pv_desc_container .edit_remove_wrapper button.close {
    background: transparent;
    border: 0;
    margin-top: 5px;
}

.product_preview_row .product_variant_preview_wrapper .pv_desc_container .edit_remove_wrapper button.close svg.Polaris-Icon__Svg {
    width: 14px;
}

.product_preview_row .product_variant_preview_wrapper .pv_desc_container .product_variant_desc {
    display: flex;
    flex-flow: column;
}