/* .loader {
    display: block !important;
}

.preloader {
    position: fixed !important;
    top: -37px !important;
    background-color: #000000;
    margin-top: 0px !important;
    left: 17.9% !important;
}

.loader:before {
    background: #43afec !important;
}

.loader:after {
    background-color: #000000;
} */

body {
    margin: 0;
    padding: 0;
    background: #001219;
}

.chargezen_loading_animation_container {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-flow: column;
    position: fixed;
    z-index: 9999;
    background: #001219;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
}

.ball {
    width: 10px;
    height: 10px;
    margin: 10px auto;
    border-radius: 50px;
}

.ball:nth-child(1) {
    background: #F2F2F2;
    -webkit-animation: right 1s infinite ease-in-out;
    -moz-animation: right 1s infinite ease-in-out;
    animation: right 1s infinite ease-in-out;
}

.ball:nth-child(2) {
    background: #F2F2F2;
    -webkit-animation: left 1.1s infinite ease-in-out;
    -moz-animation: left 1.1s infinite ease-in-out;
    animation: left 1.1s infinite ease-in-out;
}

.ball:nth-child(3) {
    background: #F2F2F2;
    -webkit-animation: right 1.05s infinite ease-in-out;
    -moz-animation: right 1.05s infinite ease-in-out;
    animation: right 1.05s infinite ease-in-out;
}

.ball:nth-child(4) {
    background: #F2F2F2;
    -webkit-animation: left 1.15s infinite ease-in-out;
    -moz-animation: left 1.15s infinite ease-in-out;
    animation: left 1.15s infinite ease-in-out;
}

.ball:nth-child(5) {
    background: #F2F2F2;
    -webkit-animation: right 1.1s infinite ease-in-out;
    -moz-animation: right 1.1s infinite ease-in-out;
    animation: right 1.1s infinite ease-in-out;
}

.ball:nth-child(6) {
    background: #F2F2F2;
    -webkit-animation: left 1.05s infinite ease-in-out;
    -moz-animation: left 1.05s infinite ease-in-out;
    animation: left 1.05s infinite ease-in-out;
}

.ball:nth-child(7) {
    background: #F2F2F2;
    -webkit-animation: right 1s infinite ease-in-out;
    -moz-animation: right 1s infinite ease-in-out;
    animation: right 1s infinite ease-in-out;
}

.chargezen_loading_animation_container .ball {
    display: block !important;
}

@-webkit-keyframes right {
    0% {
        -webkit-transform: translate(-15px);
    }

    50% {
        -webkit-transform: translate(15px);
    }

    100% {
        -webkit-transform: translate(-15px);
    }
}

@-webkit-keyframes left {
    0% {
        -webkit-transform: translate(15px);
    }

    50% {
        -webkit-transform: translate(-15px);
    }

    100% {
        -webkit-transform: translate(15px);
    }
}

@-moz-keyframes right {
    0% {
        -moz-transform: translate(-15px);
    }

    50% {
        -moz-transform: translate(15px);
    }

    100% {
        -moz-transform: translate(-15px);
    }
}

@-moz-keyframes left {
    0% {
        -moz-transform: translate(15px);
    }

    50% {
        -moz-transform: translate(-15px);
    }

    100% {
        -moz-transform: translate(15px);
    }
}

@keyframes right {
    0% {
        transform: translate(-15px);
    }

    50% {
        transform: translate(15px);
    }

    100% {
        transform: translate(-15px);
    }
}

@keyframes left {
    0% {
        transform: translate(15px);
    }

    50% {
        transform: translate(-15px);
    }

    100% {
        transform: translate(15px);
    }
}