.selected-plan-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  border: 1px solid #00000014;
  padding: 5px;
  border-radius: 8px;
}

.selected-plan-container>img {
  cursor: pointer;
}

.build-box-search {
  display: flex;
  align-items: center;
  border: 1px solid #00000014;
  padding: 5px;
  border-radius: 8px;
  max-width: 600px;
}

.build-box-search h3.Polaris-Subheading {
  white-space: nowrap;
}

.search {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.build-box-search>* {
  margin-inline: 5px;
}

.date-range-label {
  margin-bottom: 4px;
}

.build-a-box-create .Polaris-Card__Header {
  background: #fff;
  border-radius: 5px 5px 0px 0px;
  color: #1c1f37;
  padding-bottom: 10px;
}

.limit-section .product-stack>div {
  margin-bottom: 26px;
  margin-left: 22px;
  margin-top: 10px;
}

.limit-section .building-box-collection>div {
  margin-bottom: 26px;
  margin-left: 22px;
  margin-top: 10px;
}


.head-search+div {
  width: 450px;
}

.react-time-picker__clock.react-time-picker__clock--open {
  border-radius: 8px;
  z-index: 500;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid #808080b8;
  width: 100%;
  padding: 4px 12px;
  border-radius: 5px;
  margin: 5px 0px;
}

.create_rebuy_main .search_plan_group_main>div {
  width: 100%;
  margin-top: 15px;
}

.create_rebuy_main .save_button_main {
  margin-top: 20px;
}