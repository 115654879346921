.Polaris-Popover__Section {
  .Polaris-DatePicker {

    .Polaris-DatePicker__Header {
      position: static !important;
    }

    table {
      .Polaris-DatePicker__Weekday {
        display: revert !important;

      }

      .Polaris-DatePicker__Day {
        width: 100%;
      }

      .Polaris-DatePicker__Week,
      .Polaris-DatePicker__WeekHeadings {
        display: table-row;
      }
    }

  }

}

.Polaris-DatePicker__Day.Polaris-DatePicker__Day--selected.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hoverRight {
  border-radius: 3rem 0 0 3rem !important;
}

.Polaris-DatePicker__MonthContainer {
  margin-left: 1.6rem !important;
}

.Polaris-DatePicker__Day.Polaris-DatePicker__Day--selected.Polaris-DatePicker__Day--lastInRange.Polaris-DatePicker__Day--hoverRight.Polaris-DatePicker__Day--hasRange {
  border-radius: 0 3rem 3rem 0;
}

.Polaris-DatePicker__Day--selected {
  background-color: #306ecb !important;

  &:focus {
    box-shadow: none !important;
  }
}

.Polaris-DatePicker__MonthLayout {
  table {
    tr {
      border: 0;

      .Polaris-DatePicker__EmptyDayCell {
        border: 0;
      }

      .Polaris-DatePicker__DayCell {
        border: 0;

        button {
          &:hover {
            // background-color: rgba(31, 81, 153, 1);
          }
        }
      }

      .Polaris-DatePicker__Day {
        border: 0;
      }

      .Polaris-DatePicker__Day--inRange {
        background-color: rgba(242, 247, 254, 1);
      }

      td {
        .Polaris-DatePicker__Day.Polaris-DatePicker__Day--hasRange {
          border: 0;

        }
      }
    }
  }
}

.module-Analytics {

  .Polaris-Popover.Polaris-Popover--fullWidth {
    width: 700px !important;
  }

  .highcharts-credits {
    display: none;
  }


  .Polaris-Page__Content {
    .Polaris-Tabs__Wrapper {
      background: unset;
      border-radius: unset;

      .Polaris-Tabs__Title {
        color: unset;
      }

      .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected {
        .Polaris-Tabs__Title {
          border-bottom: var(--p-override-none, 0.3rem) solid #000000;
        }
      }
    }
  }

  //revenue trends

  .filter-parent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .export-filter-parent * {
    margin: 20px !important;
  }

  .rev-date-picker {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    >div {
      width: 100%;
    }

    .analytics-btn-group {
      display: flex;
      gap: 16px;

      >button {
        margin-left: 50px;
        padding: 10px 50px;
      }
    }
  }

  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;

    .form-inline-child {
      width: 40%;
      justify-content: space-between;
      display: flex;
      align-items: center;

      .select {
        width: 30%;
      }

      .textfield {
        width: 15%;
      }
    }
  }

  .sales-section {
    .Polaris-Layout__Section--secondary {
      margin-top: 0.4rem;

      .Polaris-Stack>.Polaris-Stack__Item {
        margin-top: 1.6rem;

      }
    }
  }

  .Polaris-TextStyle--variationPositive {
    display: flex;
  }

  .Polaris-TextStyle--variationNegative {
    display: flex;
  }

  .Polaris-Stack {
    margin-top: unset;
    flex-wrap: wrap;

    .Polaris-Stack--vertical {
      width: 100%;
    }
  }

  .Polaris-Card {
    border-radius: 9px;
  }

  .Polaris-Icon {
    margin: unset;
  }

  .Polaris-Layout__Section--secondary {
    margin-top: -0.1rem;
  }

  .frequency-graph-revenue {
    .Polaris-Card__Section {
      display: flex;
      align-items: center;
    }
  }

  .frequency-graph-parameters {
    display: grid;
    justify-content: space-between;
    padding: 0px 18px;
    flex-direction: column;

    >div {
      display: flex;
      margin-top: 26px;
      grid-template-columns: auto auto;

      div {
        width: 41px;
        height: 17px;
      }

      p {
        margin-left: 28px;
        white-space: nowrap;
      }
    }

    .active-color {
      background-color: #000000;
    }

    .dunning-color {
      background-color: #57AAFF;
    }

    .cancel-color {
      background-color: #979797;
    }
  }

  .container-left {
    width: 28%;
  }

  .container-right {
    width: 72%;
  }

  .customer-count {
    .count-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
      margin-top: 7px;
    }

    .Polaris-Layout__Section {
      .Polaris-Stack {
        >.Polaris-Stack__Item:first-child {
          margin-top: 0px;
        }
      }
    }


  }

  // customer insight
  .customer-insight {
    // thead {
    //   background: #258aff;
    //   .Polaris-DataTable__Cell {
    //     &.Polaris-DataTable__Cell--verticalAlignTop {
    //       &.Polaris-DataTable__Cell--header {
    //         color: rgba(255, 255, 255, 0.87);
    //       }
    //     }
    //   }
    // }

    .frequency-graph {
      .Polaris-Card__Section {
        display: grid;
        grid-template-columns: 20% 80%;
        align-items: center;
      }
    }

    .container-right {
      width: 72%;

      .card-chart {

        .Polaris-Card {
          justify-content: center;
          flex-direction: column;
          overflow: hidden;

          .card-graph-parameters {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 40px;
            flex-wrap: wrap;
            padding: 0px 18px;

            >div {
              display: flex;

              div {
                width: 41px;
                height: 17px;
              }

              p {
                margin-left: 28px;
              }
            }

            .active-color {
              background-color: #000000;
            }

            .dunning-color {
              background-color: #57AAFF;
            }

            .cancel-color {
              background-color: #979797;
            }
          }
        }
      }
    }


    .last {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .card-chart {
      height: 540px;

      .Polaris-Card {
        height: 100%;
        align-items: center;
        display: flex;
      }
    }

    // tbody {
    //   .Polaris-DataTable__Cell--verticalAlignTop {
    //     border: 1.0624px solid #cecece;
    //     box-sizing: border-box;
    //   }
    // }
    .subscription-chart {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    // .Polaris-Stack {
    //   // &.Polaris-Stack--distributionTrailing {
    //   //   .Polaris-DataTable {
    //   //     thead {
    //   //       display: none;
    //   //     }
    //   //   }
    //   //   tbody {
    //   //     th.Polaris-DataTable__Cell {
    //   //       border-top: none;
    //   //       border-left: none;
    //   //     }
    //   //     .Polaris-DataTable__TableRow td:last-child {
    //   //       border-right: none;
    //   //       border-top: none;
    //   //     }
    //   //   }
    //   //   tbody tr:first-child {
    //   //     td {
    //   //       border-top: none;
    //   //     }
    //   //   }
    //   //   tbody tr:last-child {
    //   //     th.Polaris-DataTable__Cell {
    //   //       border-bottom: none;
    //   //     }
    //   //     td {
    //   //       border-bottom: none;
    //   //     }
    //   //   }
    //   // }
    // }
  }

  // .product {
  //   // thead {
  //   //   background: #258aff;
  //   //   .Polaris-DataTable__Cell {
  //   //     &.Polaris-DataTable__Cell--verticalAlignTop {
  //   //       &.Polaris-DataTable__Cell--header {
  //   //         color: rgba(255, 255, 255, 0.87);
  //   //       }
  //   //     }
  //   //   }
  //   // }
  // }
}

.data-tabels {
  display: none;
}

.insight-chart-select {
  width: 20%;
  top: 38px !important;
  left: 30px !important;
  position: relative;
}

//smarty-sms sectoin //@at-root
.image-card-section {
  .Polaris-Layout__Section {
    margin-top: 0px;
  }

  .smarty-card-section {
    .Polaris-Stack {
      flex-wrap: nowrap;
    }

    .Polaris-Stack__Item {
      margin-top: 4px;
    }

    .img-section {
      background-color: #5C5F62;
      width: 100%;
      height: 143px;
      border-radius: 9px;

      img {
        overflow: hidden;
        height: 100%;
        object-fit: cover;
        width: 100%;
        border-radius: 9px;

      }

      + {
        p {
          font-weight: bold;
          margin-top: 9px;
          font-size: 24px;
          margin-bottom: 5px;
        }
      }
    }

    span {
      margin-bottom: 7px;
      font-size: 14px;
      display: inline-block;
      color: #4D506A;
    }

    small {
      color: #4D506A;
      font-size: 14px;
    }
  }
}

.smart-card {
  >.Polaris-Stack {
    flex-wrap: nowrap;

    >.Polaris-Stack__Item {
      margin-top: 0;
      flex: 0 0 auto;
      flex-basis: 25%;

      >.Polaris-Stack__Item:not(:first-child) {
        margin-left: 1.6rem;
      }

      >.Polaris-Stack__Item {
        flex-basis: 25%;
      }
    }
  }

  .Polaris-Card__Section {
    .Polaris-Stack {
      flex-wrap: nowrap;
      height: 38px;
    }
  }
}

.card-tabs {
  .container-right {
    .Polaris-Card {
      >div {
        >div {
          height: 264px !important;

          >svg {
            height: 264px;
          }
        }
      }
    }
  }
}