.chargezen_search_model {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000080;
    z-index: 999;
}

.chargezen_search_model>.modal-content-wrapper {
    max-width: 620px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: 0rem 1.25rem 1.25rem -.5rem rgba(26, 26, 26, .28);
    max-height: calc(100vh - 3.75rem);
    border-radius: 16px;
    overflow: hidden;
}

.chargezen_search_model>.modal-content-wrapper>.chargezen_search_model_header_container {
    padding: 16px;
    background: #f3f3f3;
}

.chargezen_search_model>.modal-content-wrapper>.chargezen_search_model_header_container>h2 {
    font-weight: 600;
    font-size: 14px;
    color: #000;
    line-height: 100%;
    position: relative;
    top: 3px;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_footer {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    box-shadow: 0rem 1rem 1rem 1rem rgba(26, 26, 26, .28);
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_footer button.Polaris-Button {
    display: flex;
    align-items: center;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body {
    padding: 0px 0;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body input {
    border-radius: 60px !important;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body input+.Polaris-TextField__Backdrop {
    border-radius: 10px;
    border: 1px solid #000;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container {}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container ul.segments_lists {
    padding: 0;
    margin: 0;
    list-style: none;
    /* max-height: 40px; */
    overflow-y: auto;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container ul.segments_lists>li {
    padding: 12px 16px;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container ul.segments_lists>li>label.Polaris-Choice {
    width: 100%;
    display: flex;
    align-items: center;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container ul.segments_lists>li>label.Polaris-Choice span.Polaris-Choice__Label {
    display: flex;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container ul.segments_lists>li>label.Polaris-Choice span.Polaris-Choice__Label .name_email_wrapper {
    display: flex;
    color: #000;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container ul.segments_lists>li>label.Polaris-Choice {
    padding: 0;
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body>div:first-child {
    padding: 20px 16px;
    border-bottom: 1px solid rgba(235, 235, 235, 1);
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0rem 1rem 1rem -1rem rgba(26, 26, 26, .28);
}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body.segments_list_wrapper_container {}

.chargezen_search_model>.modal-content-wrapper .chargezen_search_model_body .segments_list_wrapper_container {
    padding: 0;
    max-height: 240px;
    height: calc(100vh - 18px);
}

.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view div.Polaris-Labelled__HelpText {
    margin: 10px 0 0;
}


.sidebar_summary_main_container .chargezen-summary-wrapper.detail_view .Polaris-Layout__Section>.Polaris-Card:not(:last-child) {
    margin: 0 0 30px !important;
}


.Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section .Polaris-Card:nth-child(3) .Polaris-Card__Section .chargezen_form_row {
    display: flex;
    grid-column-gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}



.summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section>.Polaris-Card {
    margin: 0 0 30px !important;
}

.Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section .Polaris-Card:last-child .chargezen_form_row {
    display: flex;
    flex-wrap: wrap;
}

.Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section .Polaris-Card:last-child .chargezen_form_row.flow-cloumn {
    flex-flow: column;
}

.Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section .Polaris-Card:last-child .chargezen_form_row label.custom_label {
    display: flex;
    align-items: center;
}

.Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section .Polaris-Card:last-child .chargezen_form_row label.custom_label input[type="date"] {
    border: 1px solid #ddd;
    margin: 0 10px;
}

.Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section .Polaris-Card:last-child .chargezen_form_row label.custom_label input[type="time"] {
    margin: 0 10px;
    border: 1px solid #ddd;
}

/* efsef */

.heading_title {
    font-size: 14px;
    font-weight: 650;
    line-height: 20px;
    color: #303030;
}



.Polaris-Card {
    border-radius: 5px !important;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout {
    margin: 0;
    padding: 0 15px;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section {
    width: 100%;
    max-width: 64%;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section>.Polaris-Card {
    margin-left: 0;
    box-shadow: 0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, .13) inset, -0.0625rem 0rem 0rem 0rem rgba(0, 0, 0, .13) inset, 0rem -0.0625rem 0rem 0rem rgba(0, 0, 0, .17) inset, 0rem 0.0625rem 0rem 0rem rgba(204, 204, 204, .5) inset;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection {
    width: 100%;
    max-width: 36%;
    padding-left: 16px;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection>.Polaris-Layout__Section.Polaris-Layout__Section--secondary {
    margin-left: 0 !important;
    padding: 0 !important;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection>.Polaris-Card {}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection .Polaris-Card {
    margin-left: 0;
    padding: 20px;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection .Polaris-Card__Header {
    border: 0;
    padding: 0 !important;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection .Polaris-Card .Polaris-Card__Section {
    padding: 15px 0 0 !important;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection .Polaris-Card .Polaris-Card__Section h2.summary_discount_code {
    font-weight: bold;
    color: #616161;
    margin: 0 0 14px;
    font-size: 13px;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection .Polaris-Card__Header h2.Polaris-Heading {
    font-size: 14px;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection .Polaris-Card ul.Polaris-List li {
    flex-flow: column;
    gap: 10px;
    list-style: decimal;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection .Polaris-Card h3 {
    font-size: 13px;
    color: #303030;
    font-weight: bold;
    padding: 0 0 10px;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.defauleLayoutSection .Polaris-Card ul.Polaris-List li:not(:last-child) {
    margin: 0 0 10px;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card__Header {
    padding: 0 0 15px !important;
    border: 0 !important;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card {
    padding: 20px;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card>.Polaris-Card__Section {
    padding: 0 !important;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card__Header>.heading_title {
    justify-content: flex-start;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card__Header>.heading_title>span {
    display: none;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card .Polaris-Card__Section {}


.chargezen_affiliate_main_container .summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section>.Polaris-Card:not(:last-child) {
    margin: 0 0 16px !important;
}

.chargezen_affiliate_main_container .Polaris-Card .Polaris-TextField__Backdrop,
.chargezen_affiliate_main_container .Polaris-Select__Backdrop,
.Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff !important;
    border: 1px solid rgba(138, 138, 138, 1) !important;
    box-shadow: inset 0 1px 0 0 rgba(99, 115, 129, .05) !important;
    pointer-events: none !important;
    border-radius: 0.8rem !important;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card .Polaris-TextField div.Polaris-TextField__Suffix {
    font-size: 13px;
    color: #616161;
    position: relative;
    top: 1px;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card .Polaris-TextField .Polaris-TextField__Spinner {
    display: none;
}

.summary_main_div_wrapper.chargezen-summary-wrapper.detail_view .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section>.Polaris-Card:not(:last-child) {
    margin: 0 0 16px !important;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card .Polaris-TextField.Polaris-TextField--hasValue {}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card .Polaris-TextField {
    font-size: 16px;
    overflow: hidden;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card .Polaris-TextField .Polaris-TextField__Backdrop {
    display: block;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card .Polaris-TextField .Polaris-TextField__Spinner {
    display: none;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card .Polaris-TextField div.Polaris-TextField__Suffix {
    font-size: 13px;
    color: #616161;
    position: relative;
    top: 1px;
}

.chargezen_affiliate_main_container .Polaris-Select:not(.Polaris-Select--newDesignLanguage) .Polaris-Select__Backdrop:after {
    background: transparent;
}

.Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section .Polaris-Card:last-child .chargezen_form_row {
    margin: 3px 0;
}

.chargezen_affiliate_main_container .Polaris-Frame__Content>.Polaris-Page>.Polaris-Page__Content>.Polaris-Layout>.Polaris-Layout__Section .Polaris-Card .Polaris-TextField {
    margin: 0 0 7px;
}

.Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section .Polaris-Card:last-child .chargezen_form_row {
    display: flex;
    flex-wrap: wrap;
}

.flow-cloumn {
    flex-flow: column !important;
}

.change_method_wrapper {
    display: flex;
    gap: 0;
    margin-bottom: 14px;
}

.change_method_wrapper button.toggleMethod {
    border: 1px solid #b8b5b5;
    padding: 4px 8px;
    background: transparent;
}

.change_method_wrapper button.toggleMethod:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.change_method_wrapper button.toggleMethod:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.change_method_wrapper button.toggleMethod.true {
    background: #e4e3e3;
}

.method_header_title {
    margin-bottom: 5px;
}

.flow-cloumn {
    flex-flow: column !important;
}

.Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section .Polaris-Card .Polaris-Card__Section .chargezen_form_row.flex_wrapper>div:first-child {
    max-width: 58%;
    width: 100%;
}

.Polaris-Frame__Content .Polaris-Page__Content .Polaris-Layout .Polaris-Layout__Section .Polaris-Card .Polaris-Card__Section .chargezen_form_row.flex_wrapper>div:nth-child(2) {
    max-width: 40%;
    width: 100%;
}


.chargezen_form_row.flex_wrapper {
    display: flex;
    justify-content: space-between !important;
}

.heading_title.affiliate_discount {
    margin-top: 16px !important;
    margin-bottom: 12px !important;
}



/* sidebar */


.Polaris-Layout__Section.Polaris-Layout__Section--secondary {
    padding: 0 !important;
}

.defauleLayoutSection.sdebar-right-cstm {
    min-width: 331px;
}

.defauleLayoutSection.sdebar-right-cstm .Polaris-Card__Header {
    padding: 16px 16px 0;
    border: 0;
}

.defauleLayoutSection.sdebar-right-cstm .Polaris-Card__Section {
    padding: 20px 16px 16px;
}

.defauleLayoutSection.sdebar-right-cstm .Polaris-Card__Section strong.summary_discount_code {
    color: #616161;
    font-size: 12.8px;
    font-weight: 700;
    line-height: 20px;
    display: block;
    padding: 0 0 20px;
}

.defauleLayoutSection.sdebar-right-cstm .Polaris-Card__Section .sidbar-section:not(:last-child) {
    padding: 0 0 20px;
}

.defauleLayoutSection.sdebar-right-cstm .Polaris-Card__Section .sidbar-section.seec-2 {
    min-height: 60px;
}

.defauleLayoutSection.sdebar-right-cstm .Polaris-Card__Section .sidbar-section h3 {
    color: #303030;
    font-size: 12.9px;
    font-weight: 700;
    line-height: 100%;
    padding: 0 0 8px;
}

.defauleLayoutSection.sdebar-right-cstm .Polaris-Card__Section .sidbar-section ul.Polaris-List {
    list-style: none;
    padding: 0 0 0 20px;
}

.defauleLayoutSection.sdebar-right-cstm .Polaris-Card__Section .sidbar-section ul.Polaris-List>li {
    font-size: 12.9px;
    line-height: 20px;
    color: #303030;
    font-weight: 500;
}

.defauleLayoutSection.sdebar-right-cstm .Polaris-Card__Section .sidbar-section p {
    font-size: 12.8px;
    line-height: 100%;
    color: #616161;

}

.w-full {
    width: 100% !important;
}

button.select_commission_profile {
    margin-top: 16px;
    width: 100%;
    border: 0;
    color: #303030;
    border-radius: 5px;
    background: #FFF;
    padding: 6px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}