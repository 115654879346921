.report_options_list {
    border-radius: 8px;
    background: #F9FAFB;
    margin: 2px 15px 8px;
    box-shadow: 0px 0px 5px 0px rgba(23, 24, 24, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.report_options_list .option_list_wrapper {
    border-bottom: 1px solid #E1E3E5;
    padding: 2%;
    cursor: pointer;
}

.report_options_list .option_list_wrapper .option_list_content strong {
    color: #202223;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.report_options_list .option_list_wrapper .option_list_content p.option_desc {
    color: #6D7175;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.report_options_list .option_list_wrapper:last-child {
    border: 0;
}

.analytics_report_container {
    background: #fff;
}

.forecastInventory_container {
    overflow-x: auto;
}

.forecastInventory_container>table.forecastInventory_table {
    width: 100%;
    border-collapse: collapse;
    background: #F9FAFB;
}

.forecastInventory_container>table.forecastInventory_table>thead>tr>th {
    border-bottom: 1px solid #001219;
    padding: 8.5px 16px;
    color: #6D7175;
    font-family: 'Avenir';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
    text-align: left;
}

.forecastInventory_container>table.forecastInventory_table>thead>tr>th:first-child {
    min-width: 188px;
    width: 8%;
    padding-left: 36px;
}

.forecastInventory_container>table.forecastInventory_table>thead>tr>th:nth-child(2) {
    width: 11%;
    min-width: 177px;
}

.forecastInventory_container>table.forecastInventory_table>thead>tr>th:nth-child(3) {
    width: 32%;
    min-width: 473px;
}

.forecastInventory_container>table.forecastInventory_table>thead>tr>th:nth-child(4) {
    width: 19%;
    min-width: 283px;
}

.forecastInventory_container>table.forecastInventory_table>thead>tr>th:nth-child(5) {
    width: 0%;
    min-width: 148px;
}



.forecastInventory_container>table.forecastInventory_table>tbody>tr>td {
    padding: 8.5px 16px;
    color: #202223;
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.forecastInventory_container>table.forecastInventory_table>tbody>tr>td:first-child {
    padding-left: 36px;
}

.analytics_header_filter_buttons .billing_label {
    margin: 0 !important;
    background: transparent !important;
}

.Polaris-Card .Polaris-Card__Header>.Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain button.Polaris-Button.Polaris-Button--plain {
    background: transparent !important;
}

.without_header_card>.Polaris-Card>.Polaris-Card__Header>.Polaris-Stack>.Polaris-Stack__Item>.Polaris-ButtonGroup button.Polaris-Button {
    height: auto;
    background: transparent !important;
}

.without_header_card>.Polaris-Card>.Polaris-Card__Header>.Polaris-Stack>.Polaris-Stack__Item>.Polaris-ButtonGroup button.Polaris-Button span.Polaris-Button__Content span.Polaris-Button__Text .card_header_button_group .analytics_header_filter_buttons {
    width: 100%;
}

.without_header_card>.Polaris-Card>.Polaris-Card__Header>.Polaris-Stack>.Polaris-Stack__Item>.Polaris-ButtonGroup button.Polaris-Button span.Polaris-Button__Content span.Polaris-Button__Text .card_header_button_group .analytics_header_filter_buttons .billing_filter_button_group button.Polaris-Button {
    color: #000;
}

.analytics_header_filter_buttons {
    display: flex;
    align-items: center;
}

.analytics_header_filter_buttons .billing_label {
    color: #000;
}

.analytics_header_filter_buttons>.billing_label {
    color: #001219;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    width: 100%;
    max-width: 80px;
}

.analytics_header_filter_buttons>.billing_filter_button_group {
    width: 100%;
    max-width: calc(100% - 80px);
    padding-left: 28px;
    display: flex;
    align-items: center;
}

.analytics_header_filter_buttons>.billing_filter_button_group>button.Polaris-Button {
    border-radius: 3px !important;
    border: 1px solid #C4CDD5 !important;
    background: #FFF !important;
    box-shadow: 0px 1px 0px 0px rgba(22, 29, 37, 0.05);
}

.analytics_header_filter_buttons>.billing_filter_button_group>button.Polaris-Button span.Polaris-Button__Text {
    color: #212B36;
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 16px;
    /* 114.286% */
}

.without_header_card>.Polaris-Card>.Polaris-Card__Header>.Polaris-Stack>.Polaris-Stack__Item>.Polaris-ButtonGroup button.Polaris-Button span.Polaris-Button__Content span.Polaris-Button__Text .card_header_button_group .analytics_header_filter_buttons .billing_filter_button_group button.Polaris-Button--primary {
    background: #000 !important;
    color: #fff !important;
}

.without_header_card>.Polaris-Card>.Polaris-Card__Header>.Polaris-Stack>.Polaris-Stack__Item>.Polaris-ButtonGroup button.Polaris-Button span.Polaris-Button__Content span.Polaris-Button__Text .card_header_button_group .analytics_header_filter_buttons .billing_filter_button_group button.Polaris-Button--primary span {
    color: #fff;
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_header_container {
    padding: 16px 12px 17px 20px;
    background: transparent;
    border-bottom: 1px solid #E1E3E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .modal-content-wrapper {
    border-radius: 8px;
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_header_container>h2 {
    color: #202223;
    font-family: 'Avenir';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}


.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_header_container>.closeModal svg.Polaris-Icon__Svg {
    opacity: 0.7;
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_footer.justify-between {
    padding: 17px 16px 16px 16px;
    justify-content: flex-end !important;
    border-top: 1px solid #E1E3E5;
    box-shadow: none;
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_footer.justify-between button.Polaris-Button.Polaris-Button--primary {
    padding: 12px 14.741px 10px 16px !important;
    border-radius: 4px;
    background: #001219;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset;
    color: #FFF;
    text-align: center;
    /* 114.286% */
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_footer.justify-between button.Polaris-Button.Polaris-Button--primary span.Polaris-Button__Content {
    color: #FFF;
    text-align: center;
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 114.286% */
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_footer.justify-between button.Polaris-Button {
    padding: 12px 15.968px 10px 17px !important;
    border-radius: 4px !important;
    border: 1px solid #C9CCCF;
    background: #FFF;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_footer.justify-between button.Polaris-Button span.Polaris-Button__Content {
    color: #202223;
    text-align: center;
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 114.286% */
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_body {
    padding: 20px;
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_body .desc_wrapper {
    padding: 0;
    box-shadow: none;
    border: 0;
    color: #202223;
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    margin: 0 0 20.25px;
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_body label {
    color: #202223;
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    margin: 0 0 8px;
}

.chargezen_search_model.chargezen_export_modal.product_variant_picker .chargezen_search_model_body .Polaris-TextField__Backdrop {
    border-radius: 4px;
    border: 1px solid #898F94;
    background: #FFF;
}

.custom_date_range_filter {
    position: absolute;
    top: 100%;
    min-width: 290px;
    background: #fff;
    display: flex;
    z-index: 99;
    padding: 16px;
    left: 0;
}

.calendar-wrapper {
    position: relative;
}

.custom_date_range_filter table.Polaris-DatePicker__Month {
    width: 100%;
    max-width: 241px;
    min-width: unset;
    margin: 0 auto !important;
}

.custom_date_range_filter .Polaris-DatePicker__MonthLayout .Polaris-DatePicker__MonthContainer {
    width: 100%;
    max-width: 100%;
    min-width: unset;
    display: flex;
    flex-wrap: wrap;
    margin-top: -2.2rem !important;
}

.custom_date_range_filter table.Polaris-DatePicker__Month caption.Polaris-DatePicker__Title {
    font-weight: 700;
    font-size: 14px;
    color: #6f6f6f;
    margin: 2px 0 0 !important;
    padding: 0 0 4px 0 !important;
}

.custom_date_range_filter .Polaris-DatePicker__Header {
    top: 0;
    position: static;
}

.custom_date_range_filter .Polaris-DatePicker__Header button.Polaris-Button {
    padding: 7px 16px !important;
    width: auto !important;
    height: auto !important;
    min-width: unset !important;
    min-height: unset !important;
}


.custom_date_range_filter table.Polaris-DatePicker__Month thead tr {
    display: flex;
}


.custom_date_range_filter {
    justify-content: center;
    box-shadow: -1px 0 20px rgba(23, 24, 24, .05), 0 1px 5px rgba(0, 0, 0, .15) !important;
}

.custom_date_range_filter table.Polaris-DatePicker__Month tbody td button.Polaris-DatePicker__Day {
    width: 100%;
    padding: 12px;
}