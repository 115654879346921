/* subscription page */
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: normal;
  src: url(https://shopapp.chargezen.co/assets/AvenirLTStd-82672b073ab7863a76c0114a13b52f13deffd13dd8920bfd4e30d37949af3778.ttf) format("woff");
}

@font-face {
  font-family: "Avenir-Black";
  font-style: normal;
  font-weight: 800;
  src: url(https://shopapp.chargezen.co/packs/media/components/weeklyMeals/AvenirFont/Avenir-Black-59cfb107d18bc0b25f478dbae6559ad1.ttf) format("woff");
}


.product-option-button .restart_sub_wrapper .edit_date_wrapper {
  max-width: 200px;
}


.product-option-button {
  align-items: flex-start;
}

.product-option-button .restart_sub_wrapper .edit_date_wrapper .chooseDateLabel {
  font-size: 11px;
  text-align: center;
}


html body {
  font-family: "Avenir";
}

.container-chargen p {
  font-size: 16px;
  line-height: 150%;
}

.container-chargen h2 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
}

.container-chargen h3 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.container-chargen {
  /* padding: 3.2rem 3.2rem; */
  max-width: 1574px;
  margin: 0 auto;
  font-size: 14px;
  color: #212b36;
}

.back-nav {
  display: flex;
  margin-bottom: 2rem;
  color: #637381;
}

.back-nav .back-button-wrapper {
  display: flex;
  align-content: center;
  cursor: pointer;
}

.flex {
  display: flex;
}

.padding-20 {
  padding: 20px;
}

.bg-white {
  background-color: #fff;
}

.common-radius {
  border-radius: 8px;
}

.billing-detaisl img.card_logo {
  width: 100%;
  max-width: 70px;
  height: 40px;
  object-fit: cover;
}

.billing-info {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 2rem;
  /* align-items: flex-start; */
}

.billing-info .subscription-col {
  width: 100%;
  max-width: 69%;
}

.billing-info .billing-detaisl {
  width: 100%;
  max-width: 29%;
}

.billing-info .billing-detaisl .flex.header-bill {
  justify-content: space-between;
  margin: 0 0 1.6rem;
  align-items: center;
}

.billing-info .billing-detaisl .flex.header-bill span.edit {
  color: #006fbb;
}

.billing-info .billing-detaisl .visa-section .visa-text p {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
}

.billing-info .billing-detaisl .visa-section .visa-text p span b {
  margin-left: 5px;
  font-weight: 600;
}

.total-spend {
  margin-top: 1rem;
}

.total-spend p {
  font-size: 18px;
  line-height: 18px;
  margin-top: 1rem;
  font-family: Avenir LT Std;
}

.subscription-header.flex {
  justify-content: space-between;
  margin: 0 0 10px;
}

.subscription-header.flex div b {
  display: block;
  text-align: right;
}

.subscription-header.flex div:last-child span {
  color: #637381;
}

.meal-saver.flex {
  justify-content: space-between;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  background: #f2f0f9;
  padding: 1rem 2rem;
}

.meal-saver.flex div p:first-child {
  font-size: 2rem;
  line-height: 1.8;
}

.meal-saver.flex div .item_price {
  font-size: 20px;
  line-height: 20px;
  text-align: right;
}

.meal-saver.flex div button.edit-product-button {
  background: 0 0;
  border: none;
  color: #396dc7;
  font-size: 15px;
  padding-top: 3px;
}

.product-option-button {
  margin-top: 1.4rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 20px;
}

.container-chargen button.btn {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  border: 0;
  border-radius: 4px;
  width: auto;
  font-style: normal;
  font-weight: 750;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  padding: 8px 16px;
  border: 0;
  cursor: pointer;
  font-family: "Avenir";
}

.container-chargen button.btn.green-button {
  background: #27ae60;
}

.container-chargen button.btn.blue-button {
  background: #1b3a58;
}

.apply-discount {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.apply-discount .delivery_price_sub {
  text-align: right;
  font-size: 16px;
}

.apply-discount .discount_main {
  font-size: 16px;
}

.order-type button.edit-discount {
  background: 0 0;
  border: none;
  color: #396dc7;
  font-size: 13.33px;
  padding: 0 5px;
  font-family: "Avenir";
}

.order-info {
  margin: 2rem 0 0;
  box-shadow: var(--p-card-shadow,
      0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15));
  outline: 0.1rem solid transparent;
}

.order-info .flex {
  justify-content: space-between;
}

.order-info .flex .order-inner {
  display: flex;
  gap: 1.6rem;
  align-items: flex-start;
}

.order-info .flex .order-inner .order-icon {
  width: 100%;
  max-width: 35px;
}

.order-info .flex .order-inner .order-type span.date {
  display: block;
}

.container-chargen button.btn-transparent {
  padding-left: 0;
  padding-right: 0.8rem;
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #006fbb;
  font-family: "Avenir";
}

.col-left-tabbing .Polaris-Card {
  padding: 0;
}

.col-left-tabbing .Polaris-Card .Polaris-Tabs__Wrapper {
  padding: 0;
  border: 0;
}

.col-left-tabbing .Polaris-Card .Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li>button {
  padding: 0;
}

.col-left-tabbing .Polaris-Card .Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li>button>span.Polaris-Tabs__Title {
  padding: 0;
  min-width: unset;
}

.col-left-tabbing .Polaris-Card .Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li {
  margin: 1rem;
  cursor: pointer;
}

.col-left-tabbing .Polaris-Card .Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li>button::after {
  width: 0;
  height: 0;
}

.col-left-tabbing .Polaris-Card .Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li>button span.Polaris-Tabs__Title {
  font-size: 14px;
  font-family: "Avenir";
  color: #212b36;
}

.col-left-tabbing .Polaris-Card .Polaris-Tabs__Wrapper>ul.Polaris-Tabs>li>button.Polaris-Tabs__Tab--selected>span.Polaris-Tabs__Title {
  border-bottom: 2px groove #cdcdcd;
}

.tabbing-and-adresses {
  margin-top: 2rem;
}

.tabbing-and-adresses .col-right-address {
  width: 100%;
  max-width: 34.5%;
  padding-left: 20px;
}

.tabbing-and-adresses .col-left-tabbing {
  width: 100%;
  max-width: 65.5%;
}

.tabbing-and-adresses .col-right-address .card-charzen.delivery-shedule {
  margin-top: 2rem;
}

.card-charzen.delivery-address h3 {
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 1rem;
}

.card-charzen.delivery-address p {
  font-size: 14px;
  color: #637381;
}

.card-charzen.delivery-address .delivery-address {
  border-top: 0.1rem solid var(--p-border-subdued, #dfe3e8);
  padding-top: 20px;
}

.card-charzen.delivery-address .billing-address {
  padding-bottom: 20px;
}

.tabbing-and-adresses .col-right-address .card-charzen.delivery-shedule h2 {
  margin-bottom: 1rem;
}

.tabbing-and-adresses .col-right-address .card-charzen.delivery-shedule .delivery-shedule-info .text-par {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3rem;
  padding-top: 3rem;
  border-bottom: 0.1rem solid var(--p-divider, #dfe3e8);
  align-items: center;
}

.tabbing-and-adresses .col-right-address .card-charzen.delivery-shedule .delivery-shedule-info .text-par p.text {
  font-size: 18px;
  line-height: 28px;
}

.tabbing-and-adresses .col-right-address .card-charzen.delivery-shedule .delivery-shedule-info .text-par:last-child {
  border: 0;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section {
  padding-top: 1rem;
  padding-left: 10px;
  display: block;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div.tab-conten-wrap p {
  font-size: 14px;
  margin: 1rem 0;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div.tab-conten-wrap table {
  width: 100%;
  border-collapse: collapse;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div.tab-conten-wrap table>thead>tr {
  background: #001219;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div.tab-conten-wrap table>thead>tr>th {
  padding: 10px 18px;
  border-bottom: 1px solid #111;
  color: rgb(255, 255, 255);
  text-align: left;
  border-left: 0;
  border-radius: 0;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div.tab-conten-wrap table>tbody>tr>td,
.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div.tab-conten-wrap table>tbody>tr>th {
  padding: 8px 10px;
  font-size: 14px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Logs .Polaris-DataTable__ScrollContainer table thead tr>th:first-child {
  width: 101px;
  min-width: 101px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Logs .Polaris-DataTable__ScrollContainer table thead tr>th:last-child {
  width: 171px;
  min-width: 171px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Logs .Polaris-DataTable__ScrollContainer table thead tr>th:nth-child(2) {
  width: 608px;
  min-width: 608px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div:not(#Logs) .Polaris-DataTable__ScrollContainer table thead tr th,
.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div:not(#Logs) .scroll-container table tbody tr td {
  padding: 1.6rem;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Payment-History .Polaris-DataTable__ScrollContainer table thead tr th:first-child {
  width: 445px;
  min-width: 445px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Payment-History .Polaris-DataTable__ScrollContainer table thead tr th:last-child {
  width: 223px;
  text-align: right;
  min-width: 223px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Payment-History .Polaris-DataTable__ScrollContainer table thead tr th:nth-child(2) {
  width: 201px;
  min-width: 201px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Payment-History .Polaris-DataTable__ScrollContainer table tbody tr td:last-child {
  text-align: right;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div.tab-conten-wrap table>thead>tr>th {
  font-weight: 400;
  font-family: inherit;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Billing-Attempts table thead tr th:first-child {
  width: 239px;
  min-width: 239px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Billing-Attempts table thead tr th:nth-child(2) {
  width: 97px;
  min-width: 97px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Billing-Attempts table thead tr th:nth-child(3) {
  width: 245px;
  min-width: 245px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Billing-Attempts table thead tr th:nth-child(4) {
  width: 297px;
  min-width: 297px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Contract-History .Polaris-DataTable__ScrollContainer table thead>tr>th:first-child {
  width: 206px;
  min-width: 206px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Contract-History .Polaris-DataTable__ScrollContainer table thead>tr>th:last-child {
  width: 438px;
  min-width: 438px;
  text-align: right;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Contract-History .Polaris-DataTable__ScrollContainer table tbody>tr>td:last-child {
  text-align: right;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Contract-History .Polaris-DataTable__ScrollContainer table thead>tr>th:nth-child(2) {
  width: 240px;
  min-width: 240px;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper {
  height: 100%;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Logs table>thead>tr>th button span.Polaris-DataTable__Icon {
  opacity: 1;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Logs table>thead>tr>th button svg {
  fill: #fff !important;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div#Logs table>thead>tr>th button {
  padding: 0;
  width: 100%;
  justify-content: space-between;
  color: #fff;
  font-weight: 400;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination {
  justify-content: flex-end;
  border-top: 1px solid #111;
  padding-top: 0.25em;
  width: 100%;
  flex-wrap: wrap;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination button.Polaris-Pagination__Button {
  padding: 0;
  border: 0;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination button.Polaris-Pagination__Button svg.Polaris-Icon__Svg {
  display: none;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination button.Polaris-Pagination__Button span.Polaris-Icon {
  display: none;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination button.Polaris-Pagination__Button:first-child:after {
  content: "Previous";
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination button.Polaris-Pagination__Button {
  padding: 0.5em 1em;
  background: transparent !important;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination button.Polaris-Pagination__Button::after {
  font-size: 14px;
  color: #666;
  font-weight: 200;
  font-family: "Avenir";
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination button.Polaris-Pagination__Button:last-child:after {
  content: "Next";
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination .Polaris-Pagination__Label {
  padding: 0;
}

.card-charzen {
  width: 100%;
}

.container-chargen .text-par p {
  width: 100%;
  max-width: 300px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination .Polaris-Pagination__Label .pagenumbers span {
  padding: 0.6em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  min-width: 1.5em;
  display: inline-flex;
  color: #333;
  font-size: 14px;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section nav.Polaris-Pagination .Polaris-Pagination__Label .pagenumbers span.current_page {
  background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
  color: #333 !important;
  border: 1px solid #979797;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-DataTable__Navigation {
  display: none;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div.tab-conten-wrap table>tbody>tr>th span {
  padding: 0 0.8rem;
  border: 0.2rem solid var(--p-surface, #fff);
  font-size: 13px;
  border-radius: 2rem;
  line-height: 2rem;
  font-weight: var(--p-badge-font-weight, 400);
  display: inline-flex;
  align-items: center;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div.tab-conten-wrap table>tbody>tr>th span.success {
  background-color: var(--p-surface-success, #bbe5b3);
  color: #414f3e;
}

.payment-tabs-wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section div.tab-conten-wrap table>tbody>tr>th span.failed {
  background: #d9534f;
  color: #fff;
}

/* modal css */

.modal-popup-cstm {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 22;
}

.modal-popup-cstm .modal-dialouge {
  width: 100%;
  max-height: calc(100vh - 128px);
  background: var(--p-surface, #fff);
  box-shadow: var(--p-modal-shadow,
      0 31px 41px 0 rgba(32, 42, 53, 0.2),
      0 2px 16px 0 rgba(32, 42, 54, 0.08));
  max-width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 0px 40px 0;
  border-radius: 8px;
  overflow-y: auto;
}

.modal-popup-cstm .modal-cstm-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--p-backdrop, rgba(33, 43, 54, 0.4));
  animation: Polaris-Backdrop__fade--in 0.2s 1 forwards;
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: opacity;
}

.modal-popup-cstm .modal-dialouge .Modal-header {
  padding-top: 40px;
  padding-bottom: 30px;
}

.modal-popup-cstm .modal-dialouge .Modal-header h2 {
  font-size: 28px;
  line-height: 100%;
}

.modal-popup-cstm .modal-dialouge .Modal-header h2 {
  font-size: 28px;
  line-height: 100%;
  font-family: "Avenir";
  color: #212b36;
}

.modal-popup-cstm .modal-dialouge .modal-footer {
  padding: 25px 0px 40px;
}

.modal-popup-cstm .modal-dialouge .modal-footer .save-cancel-button {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-popup-cstm .modal-dialouge .modal-footer .save-cancel-button button.cancel-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.6rem;
  background: linear-gradient(180deg, #fff, #f9fafb);
  border: 0.1rem solid var(--p-border, #c4cdd5);
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  line-height: 1;
  color: #212b36;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  transition-property: background, border, box-shadow;
  transition-duration: var(--p-override-none, 0.2s);
  transition-timing-function: var(--p-override-none,
      cubic-bezier(0.64, 0, 0.35, 1));
  -webkit-tap-highlight-color: transparent;
  color: red;
}

.modal-popup-cstm .modal-dialouge .modal-footer .save-cancel-button button.save-button {
  background: linear-gradient(180deg, #6371c7, #5563c1);
  border-color: #3f4eae;
  box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
    0 0 0 0 transparent;
  color: #fff;
  padding: 9px 18px;
  border: 0;
  border-radius: 3px;
}

.edit-product-form label {
  width: 100%;
  display: block;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  font-family: Avenir !important;
  color: #212b36;
  font-weight: 400;
}

.edit-product-form .form-group {
  margin: 0 0 15px;
}

.edit-product-form input {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  padding: 9px 12px;
}

.edit-product-form input[type="text"] {
  width: 100%;
}

.edit-product-form input {
  font-size: 1.4rem;
  color: #000;
  font-family: "Avenir";
}

.edit-product-form input::placeholder,
.edit-product-form input {
  color: #000 !important;
}

.edit-product-form input::-webkit-outer-spin-button,
.edit-product-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.edit-product-form input[type="number"] {
  -moz-appearance: textfield;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge {
  padding: 0;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .Modal-header,
.EditSwapModel.modal-popup-cstm .modal-dialouge .Modal-header {
  padding: 0px 20px;
  width: 100%;
  min-height: 75px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  padding-left: 60px;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .Modal-header button.close-btn,
.EditSwapModel.modal-popup-cstm .modal-dialouge .Modal-header button.close-btn {
  padding: 0;
  color: #000;
  opacity: 1;
  background: transparent;
  border: 0;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .Modal-header button.close-btn svg path,
.EditSwapModel.modal-popup-cstm .modal-dialouge .Modal-header button.close-btn svg path {
  fill: black !important;
  color: black;
  opacity: 1 !important;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .modal-footer {
  padding: 0px 35px 20px;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .modal-footer .btn-wrapper {
  display: flex;
  flex-flow: column;
  padding: 0px 0px 0px;
  border-top: 1px solid #ddd;
  gap: 10px;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .modal-footer .btn-wrapper button {
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 10px;
  background: transparent;
  cursor: pointer;
  outline: 0 none;
  font-family: "Avenir";
}

.modal-popup-cstm .modal-dialouge::-webkit-scrollbar {
  width: 0;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .modal-content {
  padding: 0 35px 20px;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .modal-content .delivery-charge {
  padding: 20px 0px 0;
  border-top: 1px solid #ddd;
  display: flex;
  flex-flow: column;
  gap: 16px;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .modal-content .delivery-charge div {
  display: flex;
  justify-content: space-between;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .modal-content .delivery-charge div h3 {
  color: #c4c4c4;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Avenir";
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .modal-content .delivery-charge div h4 {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "Avenir";
}

.modal-popup-cstm .modal-dialouge .modal-content .img {
  width: 200px;
  height: 100px;
  margin: 30px auto;
}

.modal-popup-cstm .modal-dialouge .modal-content .title {
  padding-bottom: 20px;
}

.modal-popup-cstm .modal-dialouge .modal-content .title h2 {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 10px;
  color: #212b36;
  font-family: "Avenir";
}

.modal-popup-cstm .modal-dialouge .modal-content .title h3 {
  font-size: 16px;
  font-weight: 900;
  color: #4d506a;
  font-family: "Avenir";
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .Modal-header h2 {
  font-size: 22px;
  font-weight: bold;
  margin-left: 0;
  color: #212b36;
}

.edit_sub_model.modal-popup-cstm .modal-dialouge .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge {
  padding: 0;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge {
  max-width: 980px;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding: 30px;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .item {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  padding: 25px 40px;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .item .img {
  width: 100%;
  height: 115px;
  margin: 0;
  margin-bottom: 25px;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .item .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .item .title {
  padding: 0 0 25px;
  text-align: center;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .item .title h2 {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .item .title p.price_per_week {
  font-size: 14px;
  text-align: center;
  color: #acb5bd;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .item .button-wrapper button {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background: #000000;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  outline: 0 none;
  transition: 0.3s all ease-in-out;
  font-family: "Avenir";
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .item .button-wrapper {
  padding: 0 0 10px;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .Modal-header h2 {
  font-size: 22px;
  font-weight: bold;
  margin-left: 0;
}

.create_billing.modal-popup-cstm .modal-dialouge {
  padding: 0;
}

.create_billing.modal-popup-cstm .modal-dialouge .Modal-header {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 2rem;
  border-bottom: var(--p-thin-border-subdued,
      0.1rem solid var(--p-border-subdued, #dfe3e8));
}

.create_billing.modal-popup-cstm .modal-dialouge .Modal-header h2 {
  font-size: 2rem;
  line-height: 2.8rem;
}

.create_billing.modal-popup-cstm .modal-dialouge .Modal-header button.close-btn {
  background: transparent;
  border: 0;
}

.create_billing.modal-popup-cstm .modal-dialouge .Modal-header button.close-btn svg.Polaris-Icon__Svg {
  width: 20px;
  height: 20px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-footer {
  display: flex;
  align-self: flex-end;
  align-items: center;
  width: 100%;
  min-height: 6.4rem;
  padding: 1.6rem;
  border-top: var(--p-thin-border-subdued,
      0.1rem solid var(--p-border-subdued, #dfe3e8));
  justify-content: flex-end;
  gap: 10px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-footer button {
  box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
    0 0 0 0 transparent;
  color: #fff;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
  min-width: 3.6rem;
  margin: 0;
  padding: 0.7rem 1.8rem;
  background: linear-gradient(180deg, #fff, #f9fafb);
  border: 0.1rem solid var(--p-border, #c4cdd5);
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  line-height: 1;
  color: #212b36;
  text-align: center;
  cursor: pointer;
}

.tabbing-and-adresses .col-right-address .card-charzen.delivery-shedule {
  min-height: 340px;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper {
  max-height: 585px;
  overflow: auto;
}

.tabbing-and-adresses .col-right-address .delivery-address.card-charzen .delivery-address {
  min-height: 92px;
  max-height: 92px;
  overflow: auto;
}

.card-charzen.delivery-address .billing-address {
  max-height: 92px;
  height: auto;
  min-height: 92px;
}

.billing-info .billing-detaisl .flex.header-bill {
  padding: 20px 20px 20px;
  margin: 0;
}

.billing-info .billing-detaisl .flex.header-bill h1 {
  color: #202223;
  font-family: 'Avenir';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.billing-info .billing-detaisl .contract_overview_content {
  padding: 0 28px 16.75px 20px;
  border-bottom: 1px solid #E1E3E5;
}

.billing-info .billing-detaisl {
  padding: 0;
}

.billing-info .billing-detaisl .overview_container_footer {
  padding: 20px;
}

.overview_row {
  display: flex;
  justify-content: space-between;
}

.billing-info .billing-detaisl .contract_overview_content .overview_row p.overview_label {
  line-height: normal;
}

.overview_row p.overview_label strong {
  color: #202223;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: block;
}

.overview_row .overview_val {
  color: #202223;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.overview_row a.overview_val {
  color: #2C6ECB;
}

.billing-info .billing-detaisl .overview_container_footer .overview_row:last-child>.overview_val {
  color: #6D7175;
}

.billing-detaisl.bg-white.padding-20.common-radius .flex.header-bill {
  justify-content: space-between;
  margin: 0 0 18px;
}

.billing-detaisl.bg-white.padding-20.common-radius .flex.header-bill h1 {
  color: #212B36;
  font-family: Avenir;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 14.7px;
}

.billing-detaisl.bg-white.padding-20.common-radius .visa-section {
  display: flex;
  justify-content: space-between;
}

.billing-detaisl.bg-white.padding-20.common-radius .visa-section .visa-text {
  text-align: right;
}

.billing-detaisl.bg-white.padding-20.common-radius .visa-section .visa-text span.no.Polaris-TextStyle--variationStrong {
  justify-content: flex-end;
}

.billing-detaisl.bg-white.padding-20.common-radius .visa-section .visa-text p {
  color: #212B36;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 21px;
}

.tabbing-and-adresses .col-right-address .billing-detaisl.common-radius {
  margin: 0 0 27px;
}

.tabbing-and-adresses .col-right-address .card-charzen h3 {
  color: #212B36;
  font-family: Avenir;
  font-size: 13.234px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  margin: 0 0 10px;
}

.tabbing-and-adresses .col-right-address .card-charzen p {
  color: #637381;
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 21px;
}

.tabbing-and-adresses .col-right-address .card-charzen.common-radius.bg-white {
  min-height: 174px;
}

.tabbing-and-adresses .col-right-address .card-charzen.common-radius.bg-white h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabbing-and-adresses .col-right-address .card-charzen.common-radius.bg-white h2 span {
  color: #202223;
  font-family: 'Avenir';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  align-items: center;
}

.tabbing-and-adresses .col-right-address .card-charzen.common-radius.bg-white h2 span.edit_button {
  color: #2C6ECB;
  text-align: center;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.tabbing-and-adresses .col-right-address .card-charzen.common-radius.bg-white .delivery-shedule-info {
  color: #6D7175;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.delivery_schedulre_button_groups {
  display: flex;
  gap: 8px;
  margin-top: 6px;
}

.change_update_button_group span.edit_button {
  color: #006fbb;
}

.Polaris-Card__Section>.Polaris-Card__Section {
  padding: 0 !important;
  margin: 0 !important;
}

.common-radius.bg-white.cancelation-col {
  padding: 13px 20px;
}

.common-radius.bg-white.cancelation-col>.delivery-shedule-info {
  color: #202223;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.common-radius.bg-white.cancelation-col p.remark-text {
  display: block;
}

.common-radius.bg-white.cancelation-col p.remark-text strong {
  color: #202223;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.common-radius.bg-white.cancelation-col p.remark-sub,
.common-radius.bg-white.cancelation-col p.remark-sub * {
  color: #202223;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
}

.product-option-button {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.product-option-button button,
.product-option-button a {
  width: 100% !important;
  max-width: calc(100%/2 - 5px) !important;
}

.product-option-button a button {
  width: 100% !important;
  max-width: 100% !important;
}

@media (max-width: 1279px) {
  .product-option-button {
    grid-column-gap: 10px;
  }

  .container-chargen button.btn {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.modal-popup-cstm .modal-content {
  border: 0;
  max-width: 100%;
  width: 100%;
}


.edit_date_wrapper input {
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 5px;
}

.edit_date_wrapper {
  display: flex;
  flex-flow: column;
  padding-top: 5px;
  gap: 5px;
}

.edit_date_wrapper .save_button {
  background: #27ae60;
  border: 0;
  color: #fff;
  font-family: 'Avenir';
  padding: 5px;
  border-radius: 3px;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header {
  padding: 0 0 10px;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack {
  margin: 0;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item.Polaris-Stack__Item--fill:first-child {
  margin: 0;
  max-width: 170px !important;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child {
  max-width: calc(100% - 170px);
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item {
  margin: 0;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup {
  margin: 0;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain {
  padding: 0 !important;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item.Polaris-Stack__Item--fill:first-child .heading_title {
  font-size: 18px;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item.Polaris-Stack__Item--fill:first-child .heading_title span {
  margin: 0;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content {
  margin: 0;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text {
  margin: 0;
}


.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
  font-size: 16px;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup {
  margin: 0 !important;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup {
  position: relative;
  top: -4px;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
  padding: 5px !important;
  font-size: 16px !important;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
  padding: 5px 10px !important;
  font-size: 16px !important;
}

.tabbing-and-adresses .col-right-address .delivery-address {
  width: 100%;
}

.delivery_schedule_num_date_wrapper {
  display: flex;
  gap: 14px;
}

.delivery_schedule_bage_wrapper {
  display: flex;
  justify-content: end;
}

@media(max-width:1600px) {

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
    padding: 5px 5px !important;

  }

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
    font-size: 14px;
  }

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup {
    position: relative;
    top: -2px;
  }


}

@media(max-width:1535px) {
  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
    font-size: 14px;
  }

}

@media(max-width:1439px) {
  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item.Polaris-Stack__Item--fill:first-child .heading_title {
    font-size: 16px;
  }

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item.Polaris-Stack__Item--fill:first-child {
    margin: 0;
    max-width: 150px !important;
  }

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child {
    max-width: calc(100% - 150px);

  }

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup {
    display: flex;
    flex-flow: row wrap;
  }

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
    padding: 5px !important;
    height: auto !important;
    min-height: unset;
  }

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
    font-size: 13px;
  }

  .order-info h2 {
    font-size: 1.4rem;
  }

  .order-info .flex .order-inner {
    gap: 1rem;

  }

  .order-info .order-type,
  .order-type .edit-discount,
  .order-info span {
    font-size: 13px !important;
  }

  .total-spend p {
    font-size: 14px;
    line-height: 16px;
  }


}

@media(max-width:1365px) {
  .billing-info .subscription-col {
    max-width: 68%;
  }

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button {
    padding: 5px 5px !important;
    height: auto !important;
    min-height: unset;
  }

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup .Polaris-ButtonGroup__Item .Polaris-Button .Polaris-Button__Text {
    font-size: 12px;
  }

  .product-option-button .restart_sub_wrapper {
    max-width: calc(50% - 5px) !important;
    width: 100%;
    flex: calc(50% - 5px) !important;
  }

  .product-option-button .restart_sub_wrapper .btn.green-button {
    max-width: 100% !important;
    width: 100%;
  }

  .product-option-button .restart_sub_wrapper .edit_date_wrapper {
    max-width: 200px;
    margin: 0 auto;
  }

  .product-option-button .restart_sub_wrapper .edit_date_wrapper .save_next_billing_button.save_button {
    margin: 0 auto;
    width: 100% !important;
    max-width: 100% !important;
  }

}

@media(max-width:1279px) {

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child {
    padding-left: 20px;
  }

  .tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card__Header .Polaris-Stack .Polaris-Stack__Item:last-child .Polaris-ButtonGroup__Item .Polaris-Button.Polaris-Button--plain .Polaris-Button__Content .Polaris-Button__Text .tabButtons .Polaris-ButtonGroup {
    justify-content: flex-start;
    flex-flow: row;
  }


}


.input_flex_wrapper {
  display: flex;
  gap: 15px;
}

.input_flex_wrapper #PolarisTextField4 {
  border: 1px solid #9b9a9a;
  max-height: 45px;
}

.input_flex_wrapper select {
  border: 1px solid #9b9a9a;
  /*! min-height: 45px !important; */
  background: #fff;
}

.input_flex_wrapper .Polaris-Select {
  min-height: 45px !important;
  display: flex;
  background: #fff;
  min-width: 140px;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .variant_card {
  display: flex;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .variant_card .image_wrapper {
  width: 100%;
  max-width: 100px;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .variant_card .varaint_content {
  width: 100%;
  max-width: calc(100% - 100px);
  padding-left: 10px;
  position: relative;
  padding-bottom: 25px;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .variant_card .varaint_content .title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 120%;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .variant_card .varaint_content .price {
  font-size: 13px;
  margin: 0 0 9px;
  color: #909090;
  font-weight: bold;
  line-height: 100%;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .variant_card button {
  background: #000;
  color: #fff;
  border: 0;
  padding: 6px 11px 4px;
  font-size: 13px;
  line-height: 100%;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid .variant_card .varaint_content .button-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 1;
}


.swap_modal .search_plan_wrapper {
  margin-bottom: 20px;
}

.EditSwapModel.modal-popup-cstm .modal-dialouge .swap-grid {
  grid-template-columns: repeat(3, 1fr);
}

.chargezen_button_spinner {
  transition-timing-function: ease;
  transition-duration: .3s;
  pointer-events: auto;
  transition-property: opacity, transform;
  transition-delay: .3s;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
  border-right: transparent;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.show_more_line_items {
  text-align: center !important;
  max-width: 100%;
  background: #000;
  background-color: #000;
  opacity: 0.7;
  color: #fff;
  font-weight: 900;
  padding: 4px;
  margin-top: -11px !important;
  cursor: pointer;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper {
  position: relative;
  padding-bottom: 70px;
  overflow: unset;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper nav.Polaris-Pagination {
  position: absolute;
  bottom: 18px;
  left: 0;
  right: 0;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-DataTable--condensed {
  max-height: 350px;
  overflow: auto;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card {
  max-height: 100%;
}

.tabbing-and-adresses .col-left-tabbing .payment-tabs-wrapper .Polaris-Card div#Billing-Attempts {
  max-height: 350px;
  overflow: auto;
}

.change_update_button_group {
  display: flex;
  gap: 18px;
}

.edit_discount_wrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.edit_discount_wrapper button.edit-discount {
  padding: 0 !important;
}

.edit_discount_wrapper span.no_discount_text {
  color: #396dc7;
}

.create_billing.modal-popup-cstm .modal-dialouge .Modal-header {
  padding: 17px 20px 17px 20px;
  min-height: 69px;
  align-items: center;
}

.create_billing.modal-popup-cstm .modal-dialouge .Modal-header h2 {
  color: #202223;
  font-family: 'Avenir';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 120% */
}

.create_billing.modal-popup-cstm .modal-dialouge .Modal-header>span.Polaris-Icon {
  margin: 0;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content {
  padding: 19.75px 20px 40.25px 20px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-content p {
  color: #202223;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-footer {
  padding: 17px 16px 16px 16px;
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-footer button.btn-green {
  border-radius: 4px;
  background: #001219;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset;
  color: #FFF;
  text-align: center;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 114.286% */
}

.create_billing.modal-popup-cstm .modal-dialouge .modal-footer button.btn-red {
  border-radius: 4px;
  border: 1px solid #C9CCCF;
  background: #FFF;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
  color: #202223;
  text-align: center;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 114.286% */
}

.tabbing-and-adresses .col-right-address {
  display: flex;
  flex-wrap: wrap;
}

.tabbing-and-adresses .col-right-address>* {
  width: 100%;
}

.tabbing-and-adresses .col-left-tabbing {
  display: flex;
  flex-wrap: wrap;
}

.tabbing-and-adresses .col-left-tabbing>.payment-tabs-wrapper {
  width: 100%;
  height: 100%;
  max-height: unset;
}

.tabbing-and-adresses .col-left-tabbing>.payment-tabs-wrapper .Polaris-Card {
  height: 100%;
  max-height: unset;
}

.tabbing-and-adresses .col-left-tabbing>.payment-tabs-wrapper .Polaris-Card .Polaris-Card__Section {
  height: 100%;
}

.tabbing-and-adresses .col-left-tabbing>.payment-tabs-wrapper .Polaris-Card .Polaris-Card__Section div#Billing-Attempts {
  max-height: calc(100% - 80px);
}

.modal-form-control.flex_control {
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
}


.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container {
  padding: 20px;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container+.modal-footer {
  padding: 25px 20px 20px;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control {
  margin: 19.75px 0 0;
  display: flex;
  flex-flow: column;
  gap: 8px;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control>label {
  padding: 0;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control>.auto_resume_options {
  display: flex;
  flex-flow: column;
  gap: 8px;
  padding: 0 0 9px 12px;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control>.auto_resume_options>label.Polaris-Choice {
  padding: 0;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control label span.Polaris-Choice__Label,
.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container+.modal-footer label.Polaris-Choice span.Polaris-Choice__Label {
  color: #202223;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control label span.Polaris-RadioButton__Backdrop {
  border-radius: 9px;
  border: 2px solid #8C9196;
  background: #FFF;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control label span.Polaris-RadioButton__Icon {
  background: #2C6ECB;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container+.modal-footer label.Polaris-Choice {
  padding: 0;
  margin: 0 0 20.75px;
}


.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container+.modal-footer label.Polaris-Choice span.Polaris-Checkbox__Backdrop {
  border-radius: 4px;
  border: 2px solid #8C9196;
  background: #FFF;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container+.modal-footer p {
  color: #6D7175;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container+.modal-footer p:not(:last-child) {
  margin: 0 0 20px;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control>div[class] {
  margin-top: 11.75px;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control>label.customlabel {
  color: #202223;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  margin: 0 0 -6px;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control>div[class] label {
  color: #202223;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  margin: 0 0 4.25px;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control>div[class] textarea {
  color: #6D7175;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* 142.857% */
  height: 82px !important;
  min-height: unset !important;
  line-height: unset;
  border-radius: 4px;
  border: 1px solid #898F94;
  background: #FFF;

  padding: 6px 13px;
}

.create_billing.modal-popup-cstm .modal-content.cancellation_reason_container .modal-form-control>div[class] textarea::placeholder {
  color: #6D7175;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  opacity: 1;
}