.text_flex_wrapper label.custom_label {
    color: #000;
    margin-top: 16px;
}

.text_flex_wrapper textarea {
    border: 1px solid #9d9d9d;
    border-radius: 5px;
}

.create_quick_actions_wrapper button.copy_code_block {
    width: fit-content;
    padding: 6px 14px;
    background: #fff;
    border: 1px solid #897f7f;
    border-radius: 3px;
}

.create_quick_actions_wrapper .text_flex_wrapper {
    margin-bottom: 26px;
}