.products_viewer {
    width: 100%;
    padding: 20px 0 5px;
}

.products_viewer img {
    max-width: 60px;
}

.upsell_container {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    position: relative;
}

.upsell_container .flex_wrapper {
    display: flex;
    width: 100%;
    gap: 10%;
}


.labels_wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 900;
    min-width: 100px;
}

.values_wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.products_viewer .img {
    width: fit-content;
    margin: 0 auto;
}

.products_viewer p {
    text-align: center;
}

.create_new_upsell {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 20px;
}


.upsell_container button {
    position: absolute;
    background: transparent;
    border: 0;
    right: 0;
    color: #017efa;
}

h3.manage_buttons.Heading {
    font-size: 24px;
    margin: 60px 0 20px;
    font-weight: 900;
}

h3.manage_buttons_title {
    font-size: 18px;
}

h3.manage_visibility_title {
    margin-top: 50px !important;
}

.upsell_container button.delete_button {
    top: 25px;
    color: red;
}

.manage_colors .sub_manage_color_row {
    padding: 10px 0;
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.manage_colors .sub_manage_color_row div {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 370px;
    gap: 10px;
}

.zennportal_style.manage_style_wrapper.manage_order_wrapper .manage_colors {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.zennportal_style.manage_style_wrapper.color-picker .zennportal_style_form_wrapper>div:first-child {
    display: flex;
    align-items: center;
    gap: 10px;
}

.zennportal_style.manage_style_wrapper.color-picker .zennportal_style_form_wrapper>div:first-child .Polaris-Labelled__LabelWrapper {
    margin: 0;
}

.zennportal_style.manage_style_wrapper.color-picker .zennportal_style_form_wrapper>div:first-child .Polaris-Connected {
    width: 100%;
    max-width: calc(100% - 85px);
}

.zennportal_style.manage_style_wrapper.color-picker .zennportal_style_form_wrapper>div:first-child .Polaris-Labelled__LabelWrapper {
    width: 100%;
    max-width: 85px;
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 120px;
}


.zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:last-child {
    max-width: 250px;
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:first-child {
    width: 100%;
    max-width: calc(100% - 250px);
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:first-child .Polaris-Labelled__LabelWrapper {
    margin: 0;
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:first-child>.Polaris-Connected {
    max-width: 100px;
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:first-child>.Polaris-Connected input {
    padding: 0px 3px;
}

.create_upsell_container .Polaris-Stack {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 0 0 15px;
}

.create_upsell_container .Polaris-Stack>.Polaris-Stack__Item {
    margin: 0;
    width: 100%;
}

.create_upsell_container .Polaris-Stack {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 0 0 15px;
}

.create_upsell_container .Polaris-Stack>.Polaris-Stack__Item {
    margin: 0;
    width: 100%;
}


.manage_button_settings_container .Polaris-FormLayout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    gap: 15px;
}

.manage_button_settings_container .Polaris-FormLayout>.Polaris-FormLayout__Item {
    display: flex;
    align-items: flex-start;
    margin: 0;
}

.manage_button_settings_container .Polaris-FormLayout>.Polaris-FormLayout__Item h3.manage_buttons_title {
    margin: 0 !important;
}

.manage_button_settings_container .Polaris-FormLayout>.Polaris-FormLayout__Item h3.manage_buttons_title {
    margin: 0 0 15px !important;
}

.manage_button_settings_container .Polaris-FormLayout>.Polaris-FormLayout__Item:nth-child(2) h3.manage_buttons_title {
    margin: 0 0 34px !important;
}

.submit_button_wrapper {
    margin-top: 20px;
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors>.sub_manage_color_row {
    max-width: 100%;
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors>.sub_manage_color_row>div:first-child {
    max-width: 363px;
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:first-child>.Polaris-Connected {
    max-width: 60px;
}

.zennportal_style.manage_style_wrapper.manage_order_wrapper .Polaris-Card__Section .zennportal_style_form_wrapper>.manage_colors>.sub_manage_color_row {
    max-width: 100%;
}

.shopify_chargezen_card .heading_title {
    padding: 16px;
}

.shopify_chargezen_card .rewards_template_wrapper.zenn_portal_wrapper .Polaris-Page,
.shopify_base_card .Polaris-Frame__Content .Polaris-Page {
    margin: 0 !important;
    padding: 1px !important;
}

.shopify_chargezen_card .Polaris-Page .back-button {
    margin-top: -32px;
}


.zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div.cstm-font {
    max-width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div.cstm-font>div {
    max-width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div.cstm-font>div>.Polaris-Labelled__LabelWrapper {
    max-width: unset !important;
    width: auto !important;
}

.zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div.cstm-font>div>.Polaris-Labelled__LabelWrapper+.Polaris-Connected {
    max-width: 330px;
}

.affiliate_setting_wrapper {
    align-items: flex-start;
}

.affiliate_setting_wrapper>.affiliate_setting_side_container {
    position: sticky !important;
    top: 70px;
    min-height: calc(100vh - 88px);
}

body .module-navbar .app_content .Polaris-Frame[data-polaris-layer="true"] {
    overflow: visible !important;
}



/*New desgin style*/


.card-box.upsell_form_wrapper .Polaris-TextField {
    border-radius: 8px;
    /* border: 1px solid #898F94; */
    background: #FDFDFD;
    min-height: 32px;
    padding: 0;
    margin-bottom: 21px;
}

.card-box.upsell_form_wrapper .Polaris-TextField input {
    border-radius: 0;
    border: 0;
    min-height: 32px;
    width: 100%;
}

.card-box.upsell_form_wrapper label {
    color: #303030;
    font-family: Avenir;
    font-size: 12.898px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}



.upsell_form_row.multirow_form_control {
    display: flex;
    width: 100%;
    max-width: 100%;
    gap: 18px;
}

.upsell_form_row .seperate_multirow_form:first-child {
    display: flex;
    align-items: center;
    width: 50%;
    gap: 18px;
}

.upsell_form_row .seperate_multirow_form {
    width: 25%;
}

.upsell_form_row .seperate_multirow_form:first-child>div {
    width: 100%;
}

.start_end_date_wrapper {
    display: flex;
    align-items: center;
    width: 50%;
    gap: 18px;
}

.start_end_date_wrapper div {
    width: 100%;
}

.custom_sub_header_wrapper .chargezen_button_group button.chargezen_primary {
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0.00) 63.53%, rgba(255, 255, 255, 0.15) 100%), #001219;
    box-shadow: 0px -1px 0px 1px rgba(0, 0, 0, 0.80) inset, 0px 0px 0px 1px #303030 inset, 0px 0.5px 0px 1.5px rgba(255, 255, 255, 0.25) inset;
    color: #FFF;
    text-align: center;
    font-family: Avenir;
    font-size: 11.813px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    width: 104px;
    height: 28px;
}

.custom_sub_header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-box.upsell_form_wrapper ul.upsell_listings li {
    border-radius: 5px;
    border: 1px solid #EBEBEB;
    background: #FFF;
    margin-bottom: 9px;
    padding: 8px 16px;
}

ul.upsell_listings {
    list-style: none;
    padding-left: 0;
}

ul.upsell_listings .upsell_listing_content {
    display: flex;
    justify-content: space-between;
}

ul.upsell_listings .upsell_listing_content .left_section {
    display: flex;
    gap: 10px;
    width: 80%;
    align-items: center;
}

ul.upsell_listings .upsell_listing_content .right_section {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

ul.upsell_listings .upsell_listing_content .left_section h5 {
    color: #303030;
    font-family: Avenir;
    font-size: 12.898px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

ul.upsell_listings .upsell_listing_content .left_section .start_end_wrapper {
    color: #616161;
    font-family: Avenir;
    font-size: 12.898px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

ul.upsell_listings .upsell_listing_content .right_section .upsell_sec {
    display: flex;
    align-items: center;
    gap: 8px;
}

ul.upsell_listings .upsell_listing_content .right_section .upsell_sec span {
    color: #616161;
    font-family: Avenir;
    font-size: 12.695px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

ul.upsell_listings .upsell_listing_content .right_section .drg_svg_wrapper.pin_icon span.Polaris-Icon {
    transform: rotate(315deg);
}



ul.upsell_listings .upsell_listing_content .left_section .drg_svg_wrapper svg {
    fill: #8A8A8A;
}

/* .Polaris-Card__Section:last-child {
    padding: 0 26px 24px 26px;
} */
.Polaris-Card__Section {
    padding: 1px 19.41px 26px 14px;
}

.custom_sub_header_wrapper .back_button_wrapper {
    align-items: center;
}

.rewards_toggle_status_wrapper {
    margin: 28px 0;
}

/*end*/
@media(max-width:1900px) {
    .zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:last-child {
        max-width: 150px;
    }

    .zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:first-child {
        max-width: calc(100% - 150px);
    }

    .zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div.cstm-font>div>.Polaris-Labelled__LabelWrapper+.Polaris-Connected {
        max-width: 230px;
    }

}

@media(max-width:1650px) {
    .zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:first-child>.Polaris-Connected {
        max-width: 70px;
    }

    .zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div.cstm-font>div>.Polaris-Labelled__LabelWrapper+.Polaris-Connected {
        max-width: 240px;
    }
}


@media(max-width:1600px) {
    .zennportal_style.manage_style_wrapper.manage_order_wrapper .manage_colors {

        grid-template-columns: repeat(5, 1fr);
    }

    .zennportal_style.manage_style_wrapper.color-picker .manage_colors {
        gap: 20px 66px;
    }

}

@media(max-width:1535px) {
    .zennportal_style.manage_style_wrapper.manage_order_wrapper .manage_colors {

        grid-template-columns: repeat(3, 1fr);
    }

    .zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:last-child {
        max-width: 50px;
    }

    .zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:first-child {
        max-width: calc(100% - 100px);
    }

    .zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div:last-child {
        max-width: 100px !important;
    }

    .zennportal_style.manage_style_wrapper.color-picker .manage_colors .sub_manage_color_row>div.cstm-font>div>.Polaris-Labelled__LabelWrapper+.Polaris-Connected {
        max-width: 190px;
    }
}