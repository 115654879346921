.rewards_choose_template.show_content_3 .Polaris-Card__Section .Polaris-Card {
    max-width: calc(100%/3 - 15px);
}

.rewards_template_wrapper .Polaris-Page .rewards_choose_template.show_content_3 div.Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card .top_image img {
    max-height: 290px;
    min-height: 290px;
    height: auto !important;

}

.bundle_with_page_wrapper .Polaris-Page {
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
}

.theme_extension_wrapper .Polaris-Card .Polaris-Tabs__Panel .Polaris-Card__Section .Polaris-Card {
    width: 100%;
    max-width: calc(33% - 10px);
    flex: calc(33% - 10px);
}