.tizen-page>.Polaris-Layout {
    display: block;
    margin-top: 0;
    margin-left: 0;
}

.back-button.pointer>span {
    margin: 0;
    margin-bottom: 1rem;
}

.smarty_messages_container.chargezen_main_content_wrapper {
    padding: 51px 28px 0;
}

.shpoify_style_button_wrapper {
    display: flex;
    gap: 8px;
}

.shpoify_style_button_wrapper button.Polaris-Button:nth-child(odd) {
    display: flex;
    height: 28px;
    padding: 6px 5.81px 6px 6px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px !important;
    background: #E3E3E3;
    font-size: 11px;
    font-weight: 900 !important;
}

.shpoify_style_button_wrapper button.Polaris-Button:nth-child(even) {
    display: flex;
    height: 28px;
    padding: 6px 11.8px 6px 12px !important;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px !important;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0.00) 63.53%, rgba(255, 255, 255, 0.15) 100%), #001219;
    box-shadow: 0px -1px 0px 1px rgba(0, 0, 0, 0.80) inset, 0px 0px 0px 1px #303030 inset, 0px 0.5px 0px 1.5px rgba(255, 255, 255, 0.25) inset;
}

.shpoify_style_button_wrapper button.Polaris-Button span.Polaris-Button__Text {
    font-size: 13px;
    font-weight: 900;
}

.shpoify_style_button_wrapper button.Polaris-Button:nth-child(even) span.Polaris-Button__Text {
    color: #fff;
}

.shpoify_style_button_wrapper .button_primary button.Polaris-Button {
    display: flex;
    height: 28px;
    padding: 6px 12px 6px 12px !important;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px !important;
    color: #fff;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0.00) 63.53%, rgba(255, 255, 255, 0.15) 100%), #001219;
    box-shadow: 0px -1px 0px 1px rgba(0, 0, 0, 0.80) inset, 0px 0px 0px 1px #303030 inset, 0px 0.5px 0px 1.5px rgba(255, 255, 255, 0.25) inset;
}

.shpoify_style_button_wrapper button.Polaris-Button.Polaris-Button--destructive {
    background: #e02d24 !important;
    box-shadow: 0px -1px 0px 1px #e02d24 inset;
}

.shpoify_style_button_wrapper button.Polaris-Button {
    border-radius: 8px !important;
    min-width: 65px;
}