body,
button,
html {
  font-family: Avenir !important;
}

.Polaris-DataTable__Cell--header {
  font-weight: 600 !important;
}

.setting-grid .tabs-parents .tab-info h4 {
  font-weight: 600 !important;
}

.settings-container .back-button {
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  gap: 8px;
}

.settings-container .EmailNotification button.Polaris-Button.Polaris-Button--plain.Polaris-Button--textAlignLeft {
  background: transparent !important;
}

.email_editor iframe {
  min-width: auto !important;
}

.flex-wrapper.justify-between.email_notfication_custom_field_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.flex-wrapper.justify-between.email_notfication_custom_field_wrapper .custom_field_row {
  max-width: 50%;
  width: 100%;
}

.flex-wrapper.justify-between.email_notfication_custom_field_wrapper .custom_field_row .custom_label {
  margin-bottom: 8px;
  font-size: 15px;
}

.chargezen_auth_token_wrapper {
  border: 1px solid #ddd;
  width: fit-content;
  padding: 8px 18px;
  background: #e4e0e00d;
  border-radius: 5px;
}