@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: normal;
    src: url('../../assets/fonts/AvenirLTStd.ttf') format('truetype');
}

body,
button,
html {
    font-family: Avenir !important;
}

.Polaris-DataTable__Cell--header {
    font-weight: 600 !important;
}

.setting-grid .tabs-parents .tab-info h4 {
    font-weight: 600 !important;
}

/* 
.dashboard_left {
    padding: 20px 0 0 30px;
} */
.dashboard_main_div {
    width: 100%;
    padding: 20px;
    background: #F8F9FF;
}

.dashboard_left .dashboard_header .main_head {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    color: #1C1F37;
    padding-bottom: 5px;
}

.dashboard_left .sub_head {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #6F6F6F;
}

.dashboard_header {
    padding-bottom: 25px;
}

.Polaris-Card .Polaris-Card__Header {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: #1C1F37;
    border-radius: 8px 8px 0 0;
}

.Polaris-Card .Polaris-Card__Header span {
    margin-right: 15px;
}

.customer_activity_div img.avatar {
    max-width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 0;
    padding: 0;
}

.top_section {
    width: 100%;
    max-width: 100%;
}

.top_section .revenue_overview_div {
    max-width: 100%;
}

.home_main_div h2.Polaris-Heading {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    color: #1C1F37;
}

.customer_activity_right .heading h2 {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #1C1F37;
    padding-right: 25px;
}

.customer_activity_div {
    display: flex;
}

.customer_activity_div .heading {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    align-items: center;

}

.customer_activity_div .body h6 {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #1C1F37;
}

.customer_activity_div {
    display: flex;
    padding: 10px 0;
    background: #FAFAFA;
    border-radius: 6px;
    margin-bottom: 15px;
}

/* .dashboard_right .graphs_main .content_summary_main svg.highcharts-root,
.dashboard_right .graphs_main .content_summary_main .highcharts-container {
    width: 100% !important;
} */



.dashboard_right {
    display: flex;
}



.right_section_sub {
    background: #F5F7FB;
    padding: 20px;
    border-radius: 6px;
    max-width: 198px;
    margin: 0 auto;
}

.mrr_first {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 55px;
    color: #1C1F37;
}

.mrr_second {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #1C1F37;
    padding-bottom: 10px;
}


.right_section_sub .mrr_div:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
}

.report {
    padding-top: 15px;
}

.report h2 {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.bottom_section {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    max-width: 100%;
    gap: 15px;
    justify-content: space-between;
    margin-top: 35px;
}

.report h6 {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    opacity: 0.5;
}

.bottom_section .right_section {
    flex: 0 0 28%;
    max-width: 28%;
}

.bottom_section .left_section {
    flex: 0 0 95%;
    max-width: 95%;
}

.bottom_section .graph_main .left_section .highcharts-container,
.bottom_section .graph_main .left_section .highcharts-container svg.highcharts-root {
    width: 100% !important;
}


.graph_main {
    display: flex;
}

.app-content .Polaris-Page {
    max-width: 100%;
    padding: 0;
    margin: 3.5% !important;
}

.Polaris-Layout {
    margin-left: 0 !important;
}

.mrr_second .subs_icon {
    position: relative;
    padding-left: 20px;
}

.mrr_second {
    position: relative;
}

.mrr_second .subs_icon:before {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    background: #017EFA;
    border-radius: 4px;
    left: 0;
    top: 0;
}


.mrr_second .subs_icon.pink:before {
    background: #FD1F9B;
}


.bottom_section .left_section .body {
    display: flex;
    justify-content: space-around;
    column-gap: 32px;
    align-items: baseline;
    margin-left: 5%;
}

.bottom_section .left_section .body .Polaris-Card {
    flex: 0 0 20%;
    max-width: 20%;
    height: 120px;
}

.revenue_heading {
    display: flex;
    justify-content: space-between;
}



.left_heading {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
}

.right_heading {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #06C270;
}

.total_sales {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 750;
    font-size: 28px;
    line-height: 125%;
}

.main_head {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 150%;
    color: #202223;
}


.main_head {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 150%;
    color: #202223;
}

.report {
    display: flex;
    column-gap: 15px;
}

.report .Polaris-Icon__Svg {
    fill: #017EFA !important;
}

.heading_title svg {
    background: #017EFA;
    border-radius: 50%;
    padding: 5px;
    width: 34px;
    height: 34px;
    left: 343px;
    top: 225px;
}

.highcharts-figure {
    max-width: calc(100% - 250px);
    width: 100%;
    flex: calc(100% - 250px);
    margin: 0;
    padding: 40px 20px 30px;
    border-right: 1px solid #dadada80;
}

.top_section .right_section {
    max-width: 250px;
    padding: 15px 24px 10px;
}

.revenue_overview_div .Polaris-Card>.Polaris-Card__Section {
    padding: 0;
}

.Polaris-Card__Header {
    border-bottom: 1px solid #dadada80;
}

.Polaris-Page__Content .Polaris-Card {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: none;
}

.dashboard_main_div {
    width: 100%;
    padding: 20px;
}

.revenue_overview_div .Polaris-Stack>.Polaris-Stack__Item,
.revenue_overview_div .Polaris-Stack--alignmentBaseline {
    margin: 0;
}

.heading_title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.Polaris-Card .Polaris-Card__Header {
    padding: 20px 20px 10px;
}

.dashboard_right .highcharts-figure svg {
    max-width: 100%;
}

.chargezen .revenue_overview_div .Polaris-Card__Header .Polaris-Button:not(.Polaris-Button--plain) {
    background: #F5F7FB;
    color: #A9ABB0;
    border: none;
    margin: 0;
}

.chargezen .revenue_overview_div .Polaris-Card__Header .Polaris-Button:not(.Polaris-Button--plain) span {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
}

.revenue_overview_div .Polaris-Stack>.Polaris-Stack__Item .Polaris-ButtonGroup,
.chargezen .revenue_overview_div .Polaris-ButtonGroup__Item,
.chargezen .Polaris-Card__Header .Polaris-Button,
.chargezen .revenue_overview_div .Polaris-Card .Polaris-Card__Header span {
    margin: 0;
}

.chargezen .revenue_overview_div span.Polaris-Button__Text .Polaris-ButtonGroup {
    gap: 10px;
    margin: 0;
}

.revenue_overview_div .Polaris-Stack.Polaris-Stack--alignmentBaseline {
    align-items: center;
}

.customer_activity_right svg {
    width: 14px;
    fill: #66B2FF !important;
}

.customer_activity_div {
    align-items: center;
    padding: 10px;
}

.customer_activity_div .heading h3 {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: #1C1F37;
}

.customer_activity_div .customer_activity_right {
    max-width: calc(100% - 42px);
    flex: 100%;
    padding-left: 15px;
}

.customer_activity_div .body {
    display: flex;
    justify-content: flex-start;
}

.customer_activity_div .customer_activity_right .body span.Polaris-Icon {
    margin: 0;
}

.top_section .Polaris-Stack.Polaris-Stack--alignmentBaseline>.Polaris-Stack__Item>.Polaris-ButtonGroup>.Polaris-ButtonGroup__Item.Polaris-ButtonGroup__Item--plain>button.Polaris-Button.Polaris-Button--plain {
    padding: 0 !important;
}

.right_section .report span.Polaris-Icon {
    display: inline-flex;
    background: #E6F3FF;
    padding: 6px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.right_section .report {
    gap: 8px;
}

.module-navbar .app_content {
    background: #F8F9FF;
}


/* 23-12-2022 */
.module-navbar.dashboardArea.collapsedNav .app_content {
    left: 7%;
    max-width: 93%;
}

.Polaris-Navigation__Item .Polaris-Navigation__Text {
    transition: 0.3s ease all;
}



.logo img {
    transition: 0.3s ease all;
}

.module-navbar.dashboardArea.collapsedNav .logo img {
    transform: translateX(85px) scale(1.8);
}

.module-navbar.dashboardArea.collapsedNav .navBar {
    max-width: 100px;
}


.module-navbar.dashboardArea.collapsedNav .collapsible_button .Polaris-Button {
    transform: scaleX(-1);
}

.module-navbar .Polaris-Button svg {
    transform: translate(-1px, 2px);
}

.collapsible_button .Polaris-Button {
    position: absolute;
    left: calc(100% - 23px);
    top: 10px;
    border: 1px solid #DADADA;
    background: #fff;
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    padding: 0 !important;
}

.module-navbar.dashboardArea.collapsedNav .logo img {
    transform: translate(130px, 30px) scale(4);
}

.navBar .logo img {
    max-width: 180px;
}

.module-navbar.dashboardArea.collapsedNav .Polaris-Navigation__Section {
    margin-top: 30px;
}

.navBar .sidBartabs nav.Polaris-Navigation {
    min-width: 100%;
    max-width: 100px;
}

.module-navbar.dashboardArea.collapsedNav .Polaris-Navigation__Item .Polaris-Navigation__Text {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    position: absolute;
    overflow: hidden;
}

.navBar {
    z-index: 9;
}

.logo_main {
    overflow: hidden;
    height: 70px;
}



/* left sidebar */

.module-navbar.dashboardArea.collapsedNav .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded {
    width: 30px;
    margin: 0;
}

.module-navbar.dashboardArea.collapsedNav .Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded .Polaris-Collapsible {
    max-width: 40px;
    margin: 0 auto;
}

.module-navbar.dashboardArea.collapsedNav .Polaris-Navigation__Icon {
    margin: auto;
}

.module-navbar.dashboardArea.collapsedNav .navBar .sidBartabs ul li span {
    margin: 0;
}

.module-navbar.dashboardArea.collapsedNav .navBar .sidBartabs ul li a {
    text-align: center;
    display: inline-block;
    margin: 0 auto;
}

.module-navbar.dashboardArea.collapsedNav .navBar {
    line-height: normal !important;
}

.module-navbar.dashboardArea.collapsedNav .navBar .sidBartabs ul li ul li {
    margin: 10px auto;
}


.module-navbar.dashboardArea.collapsedNav .navBar .sidBartabs ul li a.Polaris-Navigation__Item .Polaris-Navigation__Icon {

    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.module-navbar.dashboardArea.collapsedNav .navBar .sidBartabs ul li::before {
    display: none;
}

.navBar .sidBartabs ul li span.Polaris-Navigation__Text {
    color: #fff !important;
    font-weight: 600 !important;
}

.Polaris-Navigation__Item:hover {
    background: #1B2B65 !important;
    border-radius: 8px 0 0 8px !important;
}

.Polaris-Navigation__ItemWrapper,
.Polaris-Navigation__ItemWrapper * {
    opacity: 1 !important;
}

.navBar .sidBartabs ul li .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Text {
    color: #1BC1E0 !important;
}

@media (max-width: 1700px) {
    figure.highcharts-figure>div {
        overflow-x: auto !important;
    }

    .top_section .revenue_overview_div {
        flex-basis: 100%;
        max-width: 100%;
    }
}

.back-button.pointer {
    border: 1px solid #babfc3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 36px;
    height: 36px;
    border-radius: 5px;
}

.back-button svg.Polaris-Icon__Svg {
    fill: #5c5f62;
}

.back-button.pointer>span {
    margin: 0 !important;
}

.app_content .Polaris-Layout {
    margin-left: -8px !important;
}

/* .app_content .Polaris-Layout__AnnotatedSection,
.app_content .Polaris-Layout__Section {
    padding: 0 !important;
    margin-left: 0px !important;
} */

.defauleLayoutSection .Polaris-Layout__AnnotatedSection,
.defauleLayoutSection .Polaris-Layout__Section {
    padding: 0 15px !important;
    margin-left: 1px !important;
}

span.Polaris-TextStyle--variationStrong {
    display: flex;
    align-items: end;
    gap: 20px;
}

.button_algin_to_left {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: end;
    margin-bottom: 18px;
}

.dashboard_sub_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3%;
    gap: 20px;
}

.dashboard_sub_header .Polaris-Card {
    width: 100%;
    padding-bottom: 0;
    margin-top: 0px !important;
}

.dashboard_sub_header .footer_main {
    color: #777676;
    font-weight: 900;
    position: relative;
    bottom: 0;
}

.dashboard_sub_header .subheader_heading .left_heading {
    color: #2d7cf6;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 900;
}

.dashboard_sub_header .Polaris-Card__Header {
    border: none;
}

.dashboard_sub_header .result_main {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #001219;
}

.dashboard_sub_header .Polaris-Card__Section {
    margin-bottom: 0px;
}

.custom_filter_main .btn-sort {
    min-width: 267px;
    width: 100%;
    max-width: 267px;
}

.custom_filter_main .btn-sort button {
    width: 100%;
}

.custom_filter_main .Polaris-Filters {
    width: 100%;
}

.rewards_template_wrapper .rewards_choose_template .Polaris-Tabs__Wrapper {
    display: none;
}

.rewards_template_wrapper .rewards_choose_template .sort-by {
    display: none;
}

/* setup guide */

.setup_guide_content_wrapper {
    padding: 0;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper {
    padding: 10px 6px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper.activate {
    background: #dddddd66;
    border-radius: 4px;
    margin-bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper button.checkbox_button {
    padding: 0;
    border: 0;
    background: transparent;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper .left_section_container {
    display: flex;
    min-width: 15px;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper .right_section_container {
    width: 100%;
    max-width: calc(100% - 20px);
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper .right_section_container h2 {
    color: #303030;
    padding: 0 0 4px;
    font-size: 14px !important;
    text-align: left !important;
    margin-bottom: 0 !important;
    line-height: 20px !important;
    font-weight: 600 !important;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper .right_section_container .description_wrapper {
    margin: 0 0 10px;
    font-size: 13px;
    color: #303030;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper .right_section_container .buttons_group_wrapper {
    display: flex;
    gap: 10px;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper .right_section_container .buttons_group_wrapper button {
    padding: 6px 12px;
    font-size: 12px;
    line-height: 100%;
    border: 0;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper .right_section_container .buttons_group_wrapper button.bg_transparent_button {
    background: #f2f2f2;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper .right_section_container .buttons_group_wrapper button.bg_colored_button {
    background: #303030;
    color: #fff;
    border-radius: 5px;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper button.checkbox_button svg {
    width: 15px;
    height: 15px;
}


.setup_guide_content_wrapper .chargezen_flex_row_wrapper svg circle {
    fill: transparent !important;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper svg {
    border: 1px dashed #8a8a8a;
    border-radius: 100px;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper button.checkbox_button {
    cursor: pointer;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper button.checkbox_button.checkbox_button.active svg {
    border: 0;
}

.setup_guide_content_wrapper .chargezen_flex_row_wrapper button.checkbox_button.checkbox_button.active svg circle {
    fill: #000 !important;
}

.setup_guide_content_wrapper .show_hide_content_wrapper.no-height {
    height: 0;
    display: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

@media(max-width:1365px) {
    .revenue_overview_div .Polaris-Stack.Polaris-Stack--alignmentBaseline {
        justify-content: space-between !important;
    }

}

.chargezen_chat_with_us_container {
    width: 100%;
}

.chargezen_chat_with_us_container h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: 'Avenir';
}

.chargezen_chat_with_us_container .chargezen_chat_with_us_wrapper {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(23, 24, 24, 0.05);
    padding: 38px 27px;
    margin-top: 10px;
}

.chargezen_chat_with_us_container .chargezen_chat_with_us_wrapper .right_section_wrapper p {
    color: #202223;
    font-family: 'Avenir';
    font-weight: 400;
    line-height: 20px;
}

button.chat_with_us_button {
    cursor: pointer;
    margin-top: 18px;
    display: flex;
    width: 116.32px;
    height: 36px;
    padding: 10px 15.148px 10px 17px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #C9CCCF;
    background: #FFF;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
}

.chargezen_chat_with_us_wrapper .left_section_wrapper {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 170px;
}

.chargezen_chat_with_us_wrapper {
    display: flex;
    gap: 15px;
}

.chargezen_chat_with_us_wrapper .image_wrapper {
    border-radius: 100%;
    width: 74px;
    height: 74px;
}

.chargezen_chat_with_us_wrapper .image_wrapper img,
.image_wrapper svg {
    border-radius: 100%;
    width: 74px;
    height: 74px;
    border: 1px solid #fff;
    position: absolute;
}

.chargezen_chat_with_us_wrapper .image_wrapper:first-child img,
.image_wrapper:first-child svg {
    left: 0px;
}

.chargezen_chat_with_us_wrapper .image_wrapper:nth-child(2) img,
.image_wrapper:nth-child(2) svg {
    left: 30px;
}

.chargezen_chat_with_us_wrapper .image_wrapper:nth-child(3) img,
.image_wrapper:nth-child(3) svg {
    left: 60px;
}

.chargezen_chat_with_us_wrapper .image_wrapper:nth-child(4) img,
.image_wrapper:nth-child(4) svg {
    left: 90px;
}

.progress_bar_in_header .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .progressbar_wrapper {
    bottom: 0;
}

.progress_bar_in_header .Polaris-Card .Polaris-Card__Header {
    padding-bottom: 65px;
}

.progress_bar_in_header .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .progressbar_wrapper .progressbar_wrapper {
    position: absolute;
    bottom: 21px;
    font-size: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 280px;
    gap: 15px;
    line-height: normal;
}

.progress_bar_in_header .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .progressbar_wrapper .card_title_desc {
    bottom: 41px;
}

.setup_guide_content_wrapper .contents_wrapper_container.no-height {
    height: 0;
    opacity: 0;
    padding: 0;
    transition: all 0.3s ease-in-out;
    display: none;
}

.setup_guide_content_wrapper .contents_tab_wrapper {
    padding: 0 20px;
    background: #f1f1f182;
    border-bottom: 1px solid #ddddddb3;
    border-left: 0;
    border-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
}

.setup_guide_content_wrapper .contents_wrapper_container {
    padding: 5px 14px;
}

.setup_guide_content_wrapper .contents_tab_wrapper button.icon_wrapper {
    background: transparent;
    width: fit-content;
    cursor: pointer;
    border: 0;
}

.setup_guide_content_wrapper .contents_tab_wrapper span {
    font-weight: 900;
    font-family: 'Avenir';
}

.setup_guide_content_wrapper .contents_tab_wrapper span {
    display: inline-block;
}

.setup_guide_content_wrapper .contents_tab_wrapper button.icon_wrapper {
    padding: 0;
    margin-right: 0;
    width: auto !important;
    height: auto !important;
}

.polaris-no-padding-section .Polaris-Card .Polaris-Card__Section {
    padding: 0 !important;
}

@media(max-width:1279px) {
    .progress_bar_in_header .Polaris-Card .Polaris-Card__Header .Polaris-Stack>.Polaris-Stack__Item.Polaris-Stack__Item--fill .progressbar_wrapper .progressbar_wrapper {
        left: 18px;
    }

}